import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { CircularProgress, Container } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { Link as RouterLink, useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import FillDetails from './job-stepper-components/FillDetails';
import SelectAssessment from './job-stepper-components/SelectAssessment';
import SelectJobBoards from './job-stepper-components/SelectJobBoards';
import Publish from './job-stepper-components/Publish';
import AvailableJobsModel from '../../../components/Mains/AvilableJobsModel';
import JobPreViewModel from '../../../components/Mains/JobPreViewModel';
import { showToast } from '../../../utils/toast';
import { jobAction } from '../../../redux/job/JobReducer';
import { setJobList } from '../../../redux/job/JobListReducer';
import { useAddJobMutation, useUpdateJobMutation, useGetJobQuery, useGetJobeDetailsQuery, useGetDraftJobsDetailsQuery } from '../../../redux/services/jobs/JobServices';
import Back from '../../../assets/images/back.svg';

// function getSteps() {
//   return ['Fill Details', 'Select Assessment', 'Select Job Boards', 'Publish'];
// }

// function getStepContent(step) {  
//   switch (step) {
//     case 0:
//       return <FillDetails />;
//     case 1:
//       return <SelectAssessment />;
//     case 2:
//       return <SelectJobBoards />;
//     // case 3:
//     //   return <Publish />;
//     case 3:
//       return <Publish />;
//     default:
//       return 'Unknown step';
//   }
// }

function getSteps(isEditMode) {
  if (isEditMode) { 
    return ['Fill Details'];
  }
    return ['Fill Details', 'Select Assessment', 'Select Job Boards', 'Publish'];
}
function getStepContent(step, isEditMode) {
  if (isEditMode){
    return <FillDetails />;
  }
    switch (step) {
      case 0:
      return <FillDetails />;
    case 1:
      return <SelectAssessment />;
    case 2:
      return <SelectJobBoards />;
    case 3:
      return <Publish />;
    default:
        return 'Unknown step';
    }
}

const CreateJob = () => {
  const { editJobId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {editJobType, draftJobId} = location.state || {};
  const isEditMode = Boolean(editJobId);
  // const searchParams = new URLSearchParams(location.search);
  // const editJobType = searchParams.get('editJob');
  const job = useSelector((state) => state.job.job);
  const { data: allJobs, refetch } = useGetJobQuery();
  const { data: jobData, refetch: getRefetch} = useGetJobeDetailsQuery(editJobId);
  const { data: DraftJobData, refetch: getDraftJobRefetch} = useGetDraftJobsDetailsQuery(draftJobId);
  const [addJobData, addJobDataInfo] = useAddJobMutation();
  const [updateJobData, updateJobDataInfo] = useUpdateJobMutation();
  const [modelOpen, setModelOpen] = useState(false);
  const [jobModelPublish, setJobModelPublish] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  
  const steps = getSteps();

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();
  // console.log(activeStep, 'activeStep');
  const handleNext = () => {
    if (activeStep === 0) {
      const status = isValidateUpdateJob()
      if (status) {
        const newActiveStep =
          isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
        setActiveStep(newActiveStep);
      }
    }
    else if (activeStep === 1) {
      // console.log(job.assesment, job.webform, 'job.assesment, job.webform')
      // const assesmentAndWebForm= 
      if (job.webform === null) {
        showToast('error', 'Please select a webform');
        return; // Exit the function early
      }

      if (job.webform !== null) {
        const newActiveStep =
          isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
        setActiveStep(newActiveStep);
      }
    }
    else {
      const newActiveStep =
        isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
      setActiveStep(newActiveStep);
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  // const handleComplete = () => {
  //   const newCompleted = completed;
  //   newCompleted[activeStep] = true;
  //   setCompleted(newCompleted);
  //   handleNext();
  // };

  const handleComplete = async () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
    if (isValidateUpdateJob()) {
      // if (editJobId) {
      //   const { id, ...jobWithoutId } = job;
      //   const updatedJob = { ...jobWithoutId, education: 1 };
      //   console.log("update job data",updatedJob)
      //   await updateJobData({ updatedJob, id: editJobId });
      // }

      if (editJobId) {
        const { id, ...jobWithoutId } = job;
        const updatedJob = { ...jobWithoutId };
        await updateJobData({ id, updatedJob });
      }
      else {
        const { id, ...jobWithoutId } = job;
        const updatedJob = { ...jobWithoutId, published: true };
        await addJobData(updatedJob);
      }
    }
  };

  useEffect(() => {
    if (draftJobId && editJobType === 'draftJob') {
      getDraftJobRefetch();
    } else if (editJobId) {
      getRefetch();
    }
  }, [editJobId, editJobType, getRefetch, getDraftJobRefetch]);

  
  useEffect(() => {
    const dataToUse = editJobType === 'draftJob' ? DraftJobData : jobData;
     
    if (dataToUse) {
      const textValue1 = {
        id: editJobId || draftJobId,
        title: dataToUse?.title,
        vacancies: dataToUse?.vacancies,
        department: dataToUse?.department,
        owner: dataToUse?.owner,
        assesment: dataToUse?.assesment,
        webform: dataToUse?.webform,
        webform_name: dataToUse?.webform?.name,
        member_ids: dataToUse?.members?.map(member => member.account_id) || [],
        member_names: dataToUse?.members,
        type: dataToUse?.type,
        nature: dataToUse?.nature,
        education: dataToUse?.educations,
        speciality: dataToUse?.speciality,
        exp_min: dataToUse?.exp_min,
        exp_max: dataToUse?.exp_max,
        salary_min: dataToUse?.salary_min,
        salary_max: dataToUse?.salary_max,
        currency: dataToUse?.currency,
        salary_type: dataToUse?.salary_type,
        state: dataToUse?.state?.id,
        state_name: dataToUse?.state?.name,
        city: dataToUse?.city,
        description: dataToUse?.description,
        job_boards: ['Linedin-id'],
        // job_boards: dataToUse?.job_boards,
        pipeline: dataToUse?.pipeline,
        active: dataToUse?.active,
        assesment_name: dataToUse?.assesment?.name,
        // education_names: dataToUse?.educations,
        pipeline_name: dataToUse?.pipeline?.name,
        owner_name: dataToUse?.owner,
        city_name: dataToUse?.city?.city_name,
        department_name: dataToUse?.department_name,
        address: dataToUse?.location,
      };
      dispatch(jobAction(textValue1));      
    }
  }, [dispatch, jobData, DraftJobData, editJobId, editJobType]);

  useEffect(() => {
    if (addJobDataInfo.isSuccess) {
      showToast('success', 'Job created succesfully');
      const textValue1 = {
        title: '',
        vacancies: null,
        department: null,
        owner: '',
        assesment: null,
        member_ids: [],
        member_names: [],
        type: '',
        nature: '',
        education: '',
        speciality: '',
        exp_min: null,
        exp_max: null,
        salary_min: '',
        salary_max: '',
        currency: '',
        salary_type: '',
        state: null,
        city: '',
        description: '',
        job_boards: ['Linedin-id'],
        pipeline: '',
        active: 1,
        assesment_name: '',
        webform: null,
        webform_name: '',
        // education_names: [],
        pipeline_name: '',
        state_name: '',
        owner_name: '',
        department_name: '',
        city_name: '',

      };
      dispatch(jobAction(textValue1));
      addJobDataInfo.reset();
      refetch();
      dispatch(setJobList(allJobs));
      navigate('/dashboard/jobs');
    }
    if (updateJobDataInfo.isSuccess) {
      showToast('success', 'update Job succesfully');
      const textValue1 = {
        title: '',
        vacancies: null,
        department: null,
        owner: '',
        assesment: null,
        member_ids: [],
        member_names: [],
        type: '',
        nature: '',
        education: '',
        speciality: '',
        exp_min: null,
        exp_max: null,
        salary_min: '',
        salary_max: '',
        currency: '',
        salary_type: '',
        state: null,
        city: '',
        description: '',
        job_boards: ['Linedin-id'],
        pipeline: '',
        active: 1,
        assesment_name: '',
        webform: null,
        webform_name: '',
        // education_names: [],
        pipeline_name: '',
        state_name: '',
        owner_name: '',
        department_name: '',
        city_name: '',

      };
      dispatch(jobAction(textValue1));
      updateJobDataInfo.reset();
      refetch();
      dispatch(setJobList(allJobs));
      navigate('/dashboard/jobs');
    }
    if (addJobDataInfo.isError) {
      showToast('error', addJobDataInfo.error.data.msg);
      // console.log(addJobDataInfo.error)
      addJobDataInfo.reset();
      refetch();
    }
    if (updateJobDataInfo.isError) {

      console.log(updateJobDataInfo.error.data.detail)
      showToast('error', updateJobDataInfo.error.data.detail);
      updateJobDataInfo.reset();
      refetch();
    }
    return () => {
      const textValue2 = {
        title: '',
        vacancies: null,
        department: null,
        owner: '',
        assesment: null,
        member_ids: [],
        member_names: [],
        type: '',
        nature: '',
        education: [],
        speciality: '',
        exp_min: null,
        exp_max: null,
        salary_min: '',
        salary_max: '',
        currency: '',
        salary_type: '',
        state: null,
        city: '',
        description: '',
        address: '',
        job_boards: ['Linedin-id'],
        pipeline: '',
        active: 1,
        assesment_name: '',
        // education_names: [],
        pipeline_name: '',
        state_name: '',
        owner_name: '',
        department_name: '',
        city_name: '',
      };
      dispatch(jobAction(textValue2));
    };
  }, [addJobDataInfo.isSuccess, addJobDataInfo.isError, updateJobDataInfo.isSuccess, updateJobDataInfo.isError, dispatch, addJobDataInfo, updateJobDataInfo, refetch, allJobs, navigate]);

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  const isValidateUpdateJob = () => {
    let status = true;
    if (job.title === null || job.title === '' || job.title === undefined) {
      status = false;
      showToast('error', 'fill the title');
    }
    if (job.vacancies === null || job.vacancies === '' || job.vacancies === undefined) {
      status = false;
      showToast('error', 'fill the number of vacancies');
    }
    if (job.department === null || job.department === '' || job.department === undefined) {
      status = false;
      showToast('error', 'fill the department');
    }
    if (job.owner === null || job.owner === '' || job.owner === undefined) {
      status = false;
      showToast('error', 'fill the owner');
    }
    if (job.education.length === 0 || job.education === '' || job.education === undefined) {
      status = false;
      showToast('error', 'fill the education');
    }
    if (job.member_names.length === 0 || job.member_names === '' || job.member_names === undefined) {
      status = false;
      showToast('error', 'fill the team members');
    }
    if (job.type === null || job.type === '' || job.type === undefined) {
      status = false;
      showToast('error', 'fill the type');
    }
    if (job.nature === null || job.nature === '' || job.nature === undefined) {
      status = false;
      showToast('error', 'fill the job nature');
    }

    if (job.speciality === null || job.speciality === '' || job.speciality === undefined) {
      status = false;
      showToast('error', 'fill the speciality');
    }
    if (job.exp_min === null || job.exp_min === '' || job.exp_min === undefined) {
      status = false;
      showToast('error', 'fill the number of exp min');
    }
    if (job.exp_max === null || job.exp_max === '' || job.exp_max === undefined) {
      status = false;
      showToast('error', 'fill the exp max');
    }
    if (job.exp_min > job.exp_max) {
      status = false;
      showToast('error', 'Minimum experience cannot be greater than maximum experience.');
    }
    if (job.exp_max < job.exp_min) {
      status = false;
      showToast('error', 'Maximum experience cannot be less than minimum experience.');
    }
    if (job.salary_min === null || job.salary_min === '' || job.salary_min === undefined) {
      status = false;
      showToast('error', 'fill the number of salary min');
    }
    if (job.salary_max === null || job.salary_max === '' || job.salary_max === undefined) {
      status = false;
      showToast('error', 'fill the salary max');
    }
    const salaryMin = Number(job.salary_min);
    const salaryMax = Number(job.salary_max);

    if (Number.isNaN(salaryMin) || Number.isNaN(salaryMax)) {
      status = false;
      showToast('error', 'Salary values must be valid numbers.');
    } else if (salaryMin > salaryMax) {
      status = false;
      showToast('error', 'Minimum salary cannot be greater than maximum salary.');
    }
    if (job.currency === null || job.currency === '' || job.currency === undefined) {
      status = false;
      showToast('error', 'fill the team currency');
    }
    if (job.salary_type === null || job.salary_type === '' || job.salary_type === undefined) {
      status = false;
      showToast('error', 'fill the salary type');
    }
    if (job.address === null || job.address === '' || job.address === undefined) {
      status = false;
      showToast('error', 'fill the Address state');
    }
    if (job.pipeline === null || job.pipeline === '' || job.pipeline === undefined) {
      status = false;
      showToast('error', 'fill the pipeline');
    }
    if (job.description === null || job.description === '' || job.description === undefined || job.description === '<p><br></p>') {
      status = false;
      showToast('error', 'fill the job description');
    }
    if (job.description.length >= 5001){
      status = false;
      showToast('error', 'Job description must not exceed 5000 characters');
    }
    return status;
  };
  // for New Page Ui

  const avilableJobs = () => {
    setJobModelPublish(true);
  };

  const handleClose = () => {
    setModelOpen(false);
  };
  const handleJobClose = () => {
    setJobModelPublish(false)
  }
  const onJobPreviewModel = () => {
    setModelOpen(true);
  };

  const getButtonAction = () => {
    if (isEditMode) {
      return {
        onClick: handleComplete,
        text: 'Update'
      };
    }
    
    if (activeStep === steps.length - 1) {
      return {
        onClick: handleComplete,
        text: 'Publish'
      };
    }
    
    return {
      onClick: handleNext,
      text: 'Next'
    };
  };


  // if (isJobDataLoading) {
  //   return (
  //     <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
  //       <CircularProgress />
  //     </Container>
  //   );
  // }

  return (
    <>
      <Grid container spacing={2} padding="20px">
      <Grid item xs={12} sm={6} display="flex" alignItems="center" spacing={2}>
          <Grid item>
            <Link to="/dashboard/jobs">
              <img src={Back} alt="Go Back" />
            </Link>
            {/* <IconButton edge="start" color="inherit" aria-label="close" component={RouterLink} to="/dashboard/jobs">
              <CloseIcon />
            </IconButton> */}
          </Grid>
          <Grid item sx={{ ml: 2 }}>
            <Typography 
              variant="h4" 
              color="textPrimary" 
              sx={{ 
                width: '300px',
                textDecorationLine: 'underline',
                backgroundColor: 'transparent !important'
              }}
            >
              {editJobId ? 'Update' : 'Create'} job
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="right">
          {/* <Grid style={{ marginRight: 5 }}>
            <Button variant="contained" onClick={handleComplete} component={RouterLink} to="#">
              Save
            </Button>
          </Grid> */}
          {/* <Grid style={{ marginRight: 5 }}>
            <Button variant="contained" component={RouterLink} to="#" onClick={onJobPreviewModel}>
              Preview
            </Button>
          </Grid> */}
          {/* <Grid style={{ marginRight: 5 }}>
            <Button variant="contained" onClick={handleComplete}>
              Publish
            </Button>
          </Grid> */}
        </Grid>
      </Grid>
      {/* <Card style={{ padding: 20 }}>
        <div>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton onClick={handleStep(index)} completed={completed[index]}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>

          <div>
            {allStepsCompleted() ? (
              <div>
                <Typography>All steps completed - you&apos;re finished</Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                <Typography style={{ display: 'flex', justifyContent: 'center' }}>
                  {getStepContent(activeStep)}
                </Typography>

                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button disabled={activeStep === 0} onClick={handleBack} style={{ marginRight: '5px' }}>
                    Back
                  </Button>
                  {activeStep === steps.length - 1 ? (
                    <Button variant="contained" color="primary" onClick={handleComplete} style={{ marginRight: '5px' }}>
                      Publish
                    </Button>
                  ) : (
                    <Button variant="contained" color="primary" onClick={handleNext} style={{ marginRight: '5px' }}>
                      Next
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Card> */}

      <Card style={{ padding: 20 }}>
        <div>
          <Stepper activeStep={activeStep}>
            {getSteps(isEditMode).map((label, index) => (
              <Step key={label}>
                <StepButton onClick={handleStep(index)} completed={completed[index]}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>

          <div>
            {allStepsCompleted() ? (
              <div>
                <Typography>All steps completed - you&apos;re finished</Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                <Typography style={{ display: 'flex', justifyContent: 'center' }}>
                  {getStepContent(activeStep, isEditMode)}
                </Typography>

                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  {!isEditMode && (
                    <Button 
                      disabled={activeStep === 0} 
                      onClick={handleBack} 
                      style={{ marginRight: '5px' }}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={getButtonAction().onClick}
                    style={{ marginRight: '5px' }}
                  >
                    {getButtonAction().text}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>
      <AvailableJobsModel open={jobModelPublish} handleClose={handleJobClose} />
      <JobPreViewModel open={modelOpen} handleClose={handleClose} />
    </>
  );
};

export default CreateJob;
