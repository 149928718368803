import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import mammoth from 'mammoth';
import DOMPurify from 'dompurify';
// import { convertToHtml } from 'mammoth/mammoth.browser';

const PDFViewer = ({ open, onClose, fileUrl, fileType }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [docxContent, setDocxContent] = useState('');
    // const containerRef  = useRef(null);

    // useEffect(() => {
    //     let PSPDFKit;
    //     let instance;

    //     if (fileType === 'docx') {
    //         (async () => {
    //             try {
    //                 PSPDFKit = await import('pspdfkit');
    //                 const container = containerRef.current;

    //                 if (container) {
    //                     instance = await PSPDFKit.load({
    //                         container,
    //                         document: fileUrl,
    //                         baseUrl: "http://localhost:3000/",
    //                     });
    //                 }
    //             } catch (error) {
    //                 console.error('Error loading PSPDFKit:', error);
    //             }
    //         })();
    //     }

    //     return () => {
    //         if (PSPDFKit && PSPDFKit.unload) {
    //             PSPDFKit.unload(containerRef.current);
    //         }
    //     };
    // }, [fileType, fileUrl]);

  
    useEffect(() => {
        if (fileUrl && fileType === 'docx') {
            fetch(fileUrl)
                .then(response => response.arrayBuffer())
                .then(buffer => mammoth.convertToHtml({ arrayBuffer: buffer }))
                .then(result => {
                  // Sanitize the HTML content
                  const cleanHtml = DOMPurify.sanitize(result.value);
                  setDocxContent(cleanHtml);
                })
                .catch(error => console.error('Error fetching DOCX:', error));
        }
    }, [fileUrl, fileType]);

  return (
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
          <DialogTitle>
              Document Viewer
              <IconButton onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
                  <CloseIcon />
              </IconButton>
          </DialogTitle>
          <DialogContent>
              <div style={{ height: '500px' }}>
                  {fileType === 'pdf' && (
                      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
                          <Viewer fileUrl={fileUrl} plugins={[defaultLayoutPluginInstance]} />
                      </Worker>
                  )}
                   {fileType === 'docx' && (
                        <div
                        style={{
                            fontFamily: 'Arial, sans-serif',
                            fontSize: '14px',
                            lineHeight: '1.6',
                            color: '#333',
                            padding: '10px',
                            whiteSpace: 'pre-wrap',
                        }}
                        dangerouslySetInnerHTML={{ __html: docxContent }}
                        />
                    )}
                    {['jpg', 'jpeg', 'png', 'gif'].includes(fileType) && (
                        <img src={fileUrl} alt="Document" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    )}
              </div>
          </DialogContent>
      </Dialog>
  );
};
export default PDFViewer;