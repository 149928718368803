import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, FormControl, FormHelperText } from '@mui/material';
import PhoneInput from 'react-phone-input-2';

const AddNewContact = (props) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const { open, handleClose, textboxlabel, loadingbtn, formstagedata, onsubmit, type, companyName, data } = props;
  const [formData, setFormData] = useState({
    id: data?.id || null,
    name: data?.name || '',
    email: data?.email || '',
    mobile: data?.mobile || '',
  });

  const isFormValid = () => 
    !emailError && !errorMessage && formData.name && formData.email && formData.mobile;

  const handleChange = (name, value) => {
    if (name === 'mobile') {
      const digitsOnly = value.replace(/[^\d+]/g, '');
      if (digitsOnly.length < 8) {
        setErrorMessage('Phone number is too short');
        setFormData((prev) => ({ ...prev, [name]: value }));
      } else if (digitsOnly.length > 15) {
        setErrorMessage('Phone number is too long');
        setFormData((prev) => ({ ...prev, [name]: value }));
      } else {
        setErrorMessage('');
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    } else if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        setEmailError('');
        setFormData((prev) => ({ ...prev, [name]: value }));
      } else {
        setEmailError('Please enter a valid email address.');
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  useEffect(() => {
    if (data) {
      setFormData({
        id: data?.id,
        name: data?.name,
        email: data?.email,
        mobile: data?.mobile,
      });
    } else {
      setFormData({
        id: null,
        name: '',
        email: '',
        mobile: '',
      });
    }
  }, [data]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="alertmodalCloseHandler-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{ style: { background: 'rgba(0, 0, 0, 0.5)' } }}
      >
        <div>
          <DialogTitle>{textboxlabel || 'Update Contact'}</DialogTitle>
          <DialogContent>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                  <TextField
                    id="Add Contact"
                    label={data ? 'Edit Contact Name' : 'Add Contact Name'}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => handleChange('name', e.target.value)}
                    value={formData?.name}
                  />
                  <TextField
                    sx={{
                      marginTop: '20px',
                    }}
                    id="Add Contact"
                    label={data ? 'Edit Contact Email' : 'Add Contact Email'}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => handleChange('email', e.target.value)}
                    value={formData?.email}
                    error={emailError !== ''}
                    helperText={emailError}
                  />
                  {/* <TextField
                    sx={{
                      marginTop: '20px',
                    }}
                    id="phone"
                    label={data ? 'Edit Contact Phone number' : 'Add Contact Phone number'}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, '');
                      handleChange('mobile', value);
                    }}
                    value={formData?.mobile}
                    error={errorMessage !== ''}
                    helperText={errorMessage}
                  /> */}
                    <FormControl fullWidth sx={{marginTop:"20px"}}>
                      <PhoneInput
                        specialLabel={data ? 'Edit Contact Phone number' : 'Add Contact Phone number'}
                        country={'in'}
                        value={formData.mobile}
                        onChange={(value) => {
                          if (value.match(/^\+?[\d\s-]+$/)) {
                            handleChange('mobile', value);
                          }
                        }}
                        inputStyle={{
                          width: '100%',
                          height: '56px',
                          fontSize: '16px',
                          borderRadius: '4px',
                          border: '1px solid #ccc',
                          padding: '14px 14px',
                          paddingLeft: '50px',
                        }}
                        containerStyle={{
                          width: '100%',
                          position: 'relative'
                        }}
                        buttonStyle={{
                          border: '1px solid #ccc',
                          borderRight: 'none',
                          backgroundColor: '#fff'
                        }}
                        inputProps={{
                          required: true,
                          onBlur: () => setErrorMessage('') // Clear error message on blur
                        }}
                      />
                      {errorMessage && (
                        <FormHelperText error>
                          {errorMessage}
                        </FormHelperText>
                      )}
                    </FormControl>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ position: 'relative' }}>
            <Box>
              <Button onClick={handleClose} autoFocus variant="outlined" style={{ marginRight: 5 }}>
                Cancel
              </Button>

              <LoadingButton
                onClick={() => onsubmit(formData)}
                variant="contained"
                loading={loadingbtn}
                disabled={!isFormValid()}
              >
                {formData.id ? 'Update' : 'Add'}
              </LoadingButton>
            </Box>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default AddNewContact;
