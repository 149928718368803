import React, { useEffect, useState } from 'react';
import { StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { onAuthStateChanged } from 'firebase/auth';
import { useLocation } from 'react-router-dom';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { auth, getUserFromDatabase } from '../../../../fireabse';
import BEDocTemp2 from './BEDocTemp2';
import BEDocTemp1 from './BEDocTemp1';
import BEDocTemp3 from './BEDocTemp3';
import BEDocTemp4 from './BEDocTemp4';


const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
  },
  leftColumn: {
    width: '70%',
    padding: 10,
  },
  rightColumn: {
    width: '30%',
    padding: 10,
  },
  header: {
    fontSize: 20,
    marginBottom: 10,
  },
  name: {
    fontSize: 16,
  },
  designation: {
    fontSize: 14,
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: 16,
    marginBottom: 10,
  },
  paragraph: {
    fontSize: 12,
    marginBottom: 10,
  },
  image: {
    width: 80,
    height: 80,
  },
  pDFViewerStyle: {
    backgroundColor: "#ffffff",
    height: '100%',
    width: '100%'
  },

});



export default function PdfDisplayBE({ resumeData, resumeUser, pdfDisplayOpen, onClose }) {


  const [user, setUser] = useState(resumeData);
  const location = useLocation();

  const [courses, setCourses] = useState([]);
  const [activities, setActivities] = useState([]);
  const [internships, setInternships] = useState([]);
  const [hobbies, setHobbies] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [references, setReferences] = useState([]);
  const [customSections, setCustomSections] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [downloadPdf, setDownloadPdf] = useState(false);
  const [customDetails, setCustomDetails] = useState({
    courses: [],
    activities: [],
    internships: [],
    hobbies: [],
    languages: [],
    references: [],
    customSections: [],
  });
  const [selectedTemplateId, setSelectedTemplateId] = useState(1);
  const [personalData, setPersonalData] = useState({
    jobTitle: "",
    firstName: "",
    middleName: "",
    lastName: "",
    inputEmail: "",
    phone: "",
    dateOfBirth: "",
    city: "",
    address: "",
    postalCode: "",
    drivingLicense: "",
    nationality: "",
    placeOfBirth: "",
    country: "",
    professionalSummary: "",
    uploadedPhotoURL: "",
    employmentHistory: [
      {
        jobTitle: "",
        employer: "",
        startDate: "",
        endDate: "",
        city: "",
        description: "",
      },
    ],
    educationHistory: [
      {
        school: "",
        degree: "",
        startDate: "",
        endDate: "",
        city: "",
        description: "",
      },
    ],
    websitesAndLinks: [
      {
        name: "",
        url: "",
      },
    ],
  });

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [imgFile, setImgFile] = useState(null);
  const [skills, setSkills] = useState([]);
  const [currId, setCurrId] = useState(null);
  const [count, setCount] = useState(0);
  const [openModal, setOpenModal] = useState(false);


  useEffect(() => {
    const temp = {
      jobTitle: "",
      firstName: "",
      middleName: "",
      lastName: "",
      inputEmail: "",
      phone: "",
      dateOfBirth: "",
      city: "",
      address: "",
      postalCode: "",
      drivingLicense: "",
      nationality: "",
      placeOfBirth: "",
      country: "",
      professionalSummary: "",
      uploadedPhotoURL: "",
      employmentHistory: [
        {
          jobTitle: "",
          employer: "",
          startDate: "",
          endDate: "",
          city: "",
          description: "",
        },
      ],
      educationHistory: [
        {
          school: "",
          degree: "",
          startDate: "",
          endDate: "",
          city: "",
          description: "",
        },
      ],
      websitesAndLinks: [
        {
          name: "",
          url: "",
        },
      ],
    };
    if (location.state !== null) {
      if (location.state.popon) setIsPopupOpen(location.state.popon);

      if (location.state.download) setDownloadPdf(location.state.download);
    }
    if (user) {
      Object.keys(user).forEach(key => {
        temp[key] = user[key];
      });
    }
    setCustomDetails(user?.customDetails);
    setActivities(user?.customDetails?.activities);
    setCourses(user?.customDetails?.courses);
    setInternships(user?.customDetails?.internships);
    setReferences(user?.customDetails?.references);
    setCustomSections(user?.customDetails?.customSections);
    setLanguages(user?.customDetails?.languages);
    setHobbies(user?.customDetails?.hobbies);
    setCurrId(user?.id);
    setSelectedOptions(user?.skills);
    setSelectedTemplateId(user?.resumeId);
    setPersonalData(temp);
    setCount(1);
    setSkills(user?.skills);

  }, [user]);


  return (
    <>
      <Dialog open={pdfDisplayOpen} onClose={onClose}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          style: { height: '70%', width: '70%', marginTop: '5%' }
        }}
      >
        <DialogTitle>
          Document Viewer
          <IconButton onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <PDFViewer style={styles.pDFViewerStyle} >
          {(() => {
            if (personalData) {
              if (user && user.resumeId === 1) {
                return <BEDocTemp1
                  imgFile={imgFile}
                  personalData={personalData}
                  courses={courses}
                  activities={activities}
                  internships={internships}
                  hobbies={hobbies}
                  languages={languages}
                  references={references}
                  customSections={customSections}
                  skills={skills}
                />;
              }
              if (user && user.resumeId === 2) {
                return <BEDocTemp2
                  imgFile={imgFile}
                  personalData={personalData}
                  courses={courses}
                  activities={activities}
                  internships={internships}
                  hobbies={hobbies}
                  languages={languages}
                  references={references}
                  customSections={customSections}
                  skills={skills}
                />;
              }
              if (user && user.resumeId === 3) {
                return <BEDocTemp3
                  imgFile={imgFile}
                  personalData={personalData}
                  courses={courses}
                  activities={activities}
                  internships={internships}
                  hobbies={hobbies}
                  languages={languages}
                  references={references}
                  customSections={customSections}
                  skills={skills}
                />;
              }
              return <BEDocTemp4
                imgFile={imgFile}
                personalData={personalData}
                courses={courses}
                activities={activities}
                internships={internships}
                hobbies={hobbies}
                languages={languages}
                references={references}
                customSections={customSections}
                skills={skills}
              />;
            }
          })()}
        </PDFViewer>
      </Dialog>
    </>
  );
}

