import React, { useState, useEffect, useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import { sentenceCase } from 'change-case';
import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
// material
import {
  Card, Stack, Button, Container,
  Typography, ListItemIcon, DialogContent
} from '@mui/material';
// components
import { showToast } from '../../../utils/toast';
import { sortedDataFn } from '../../../utils/getSortedData';
import {
  useGetWebformQuery, useDeleteWebformMutation
} from '../../../redux/services/settings/WebformService';
// import { useGetWebformFieldsQuery } from '../../../redux/services/settings/FieldServices';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import WebFormsModal from '../../../components/webform/WebFormModal';

const Webforms = () => {
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState(false);
  const [editmodalOpen, setEditModalOpen] = useState(false);
  const { data = [], isLoading, refetch } = useGetWebformQuery();
  useEffect(() => {
    refetch()
  }, [data])
  const [DeleteWebform, DeleteWebformInfo] = useDeleteWebformMutation();

  const [btnLoader, setBtnLoader] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const [modalName, setModalName] = useState('add');
  // const { data: webFormFieldsData } = useGetWebformFieldsQuery();

  const [editValue, setEditValue] = useState()
 
  const navigateCancel = () => {
    navigate("/dashboard/InstituteSettings/settings")
}

const theme = useTheme();
const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
const [active, setActive] = React.useState(false);
const [open, setOpen] = React.useState(false);
const handleeClosee = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setActive(false);
};
const handleClosee = () => {
  setOpen(false);
};
const handleClickOpen = () => {
  setOpen(true);
};

  const modalHandleClose = (value) => {
    setModalOpen(value);
    setEditModalOpen(value);
    refetch();
  };
  const modaEditlHandleClose = (value) => {
    setModalOpen(value);
    setEditModalOpen(value);
    refetch();
  };
  const sortData = useMemo(() => {
    const sortresult = sortedDataFn(data.data);
    return sortresult;
  });
  const addNewWebformHandler = () => {
    setModalOpen(true);
  };

  const onEditModalHandler = (dataIndex) => {
    setEditModalOpen(true);
    setEditValue(data.data[dataIndex])
  };
  // delete Handler

  const onDeleteHandler = async (dataIndex) => {
    setOpen(false);
    setActive(true);
    setCurrentIndex(dataIndex);
    const dataArr = sortData;
    const currentDataObj = dataArr[dataIndex];
    await DeleteWebform(currentDataObj.id);
  };
  useEffect(() => {
    if (DeleteWebformInfo.isSuccess) {
      showToast('success', DeleteWebformInfo.data.msg);
      DeleteWebformInfo.reset();
      refetch();
    }
    if (DeleteWebformInfo.isError) {
      showToast('error', DeleteWebformInfo.error.data.msg);
      DeleteWebformInfo.reset();
      refetch();
    }
  }, [DeleteWebformInfo, refetch]);

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'action',
      label: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => (
          <>
 <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleeClosee}
          sx={{ height: '10vh' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
            position: 'absolute',
          }}
        >
          <Alert onClose={handleeClosee} severity="success" sx={{ width: '100%' }}>
            Interview Deleted!
          </Alert>
        </Snackbar>
            <div>
              <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClosee}
                aria-labelledby="responsive-dialog-title"
                sx={{ '& .MuiDialog-paper': { minHeight: '200px' } }}
              >
                <DialogTitle variant="h4">{'Delete'}</DialogTitle>
                <DialogContent>
                  <DialogContentText>Are you sure you wan&#39;t to delete this webform?</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleClosee}>
                    Disagree
                  </Button>
                  <Button
                    onClick={() =>  onDeleteHandler(dataIndex)}
                    loading={dataIndex === currentIndex ? useDeleteWebformMutation.isLoading : false}
                    autoFocus
                  >
                    Agree
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <Button style={{ minWidth: 0 }}  onClick={() => navigate(`/dashboard/candidate-settings/webforms/update/${sortData[dataIndex]?.id}`)}>
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="ep:edit" width={24} height={24} color={'blue'}/>
              </ListItemIcon>
            </Button>
            <LoadingButton
              style={{ minWidth: 0, margin: '0px 5px' }}
              // variant="contained"
              color="error"
              onClick={handleClickOpen}
              // onClick={() => onDeleteHandler(dataIndex)}
              loading={dataIndex === currentIndex ? useDeleteWebformMutation.isLoading : false}
            >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="eva:trash-2-outline" width={24} height={24} color={'red'}/>
              </ListItemIcon>
            </LoadingButton>
          </>
        ),
      },
    },
  ];

  const labelStatus = (
    <Label variant="ghost" color={'success'}>
      {sentenceCase('active')}
    </Label>
  );
  const editAndDeleteButton = (
    <>
      <Button onClick={onEditModalHandler}>
        <ListItemIcon style={{ justifyContent: 'center' }}>
          <Iconify icon="eva:edit-fill" width={24} height={24} />
        </ListItemIcon>
      </Button>
      <Button>
        <ListItemIcon style={{ justifyContent: 'center' }}>
          <Iconify icon="eva:trash-2-outline" width={24} height={24} />
        </ListItemIcon>
      </Button>
    </>
  );
  const options = {
    filterType: 'dropdown',
  };

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Container
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}
            >
                <div className="backbutton tt-back" style={{display: "flex" }} >
                    <ArrowBackIosIcon onClick={navigateCancel} sx={{
                        cursor: "pointer"
                    }} />
                </div>
                {/* <h1>
                    Webform
                </h1> */}
               <Typography 
                  variant="h3" 
                  color="textPrimary" 
                  sx={{ 
                    width: '300px',
                    textDecorationLine: 'underline',
                    backgroundColor: 'transparent !important'
                  }}
                >
                  Webform
                </Typography>
            </Container>
          <Button
           style={{width:"14%",height:"46px" }}
            variant="contained"
            component={RouterLink}
            to="/dashboard/candidate-settings/webforms/new"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Webform
          </Button>
        </Stack>
        <Card>
          <MUIDataTable title={'Webform List'} data={sortData} columns={columns} options={options} />
        </Card>
      </Container>
      {/* {modalOpen && (
        <WebFormsModal
          open={modalOpen}
          handleClose={modalHandleClose}
          label="Add Webform"
          type="text"
          textboxlabel="Create an Web form "
          id="webformName"
          name="webform"
          buttonlabel="Add Webform"
          webFormFieldsData={webFormFieldsData?.data}
        />
      )}
      {editmodalOpen && (
        <WebFormsModal 
          open={editmodalOpen}
          handleClose={modaEditlHandleClose}
          label="Edit Webform"
          type="text"
          textBoxLabel="Update an Web form "
          id="editWebformName"
          name="webform"
          webFormRowEdit={editValue}
          buttonLabel="Update Webform"
          webFormFieldsData={webFormFieldsData?.data}
        />
      )} */}
    </Page>
  );
};

export default Webforms;