/* eslint-disable eqeqeq */

import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {
    CardContent,
    Card,
    Grid,
    Divider,
    TextField,
    Box,
    Menu,
    OutlinedInput,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Radio,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useTheme } from '@mui/material/styles';
import ReactQuill from 'react-quill';
import { useCommonCandidateMailMutation, useGetEmailTamplateQuery, useGetEmailTemplateByIdQuery } from '../../redux/services/settings/EmailTamplateService';
import { showToast } from '../../utils/toast';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 550,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const SendEmailModel = (props) => {
    const [formData,setFormData]=useState({
        emails:"",
        body:"",
        subject:""
    })
    const { data: emailTemplateData, refetch: emailTemplateDataRefetch } = useGetEmailTamplateQuery()
    const [emailTemplateId, setEmailTemplateId] = useState(skipToken)
    const {data: emailTemplateDetails, refetch: emailTemplateDetailsRefetch} = useGetEmailTemplateByIdQuery(emailTemplateId)
    const [sendMail, sendMailInfo]=useCommonCandidateMailMutation(skipToken)
    const theme = useTheme();

    const [subject, setSubject] = useState("");

    const modules = {
        toolbar: [
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'align': [] }],
          [{ 'color': [] }, { 'background': [] }],
          ['clean']
        ]
      };
    
    
      const formats = [
        'font',
        'size',
        'bold', 'italic', 'underline',
        'list', 'bullet',
        'align',
        'color', 'background'
      ];
    
      const state = {
        comments: ''
      }

    const { open, handleClose, candidateData } = props;

    useEffect(() => {
        emailTemplateDetailsRefetch();
        if (emailTemplateDetails) {
            setSubject(emailTemplateDetails.subject || ""); // Update subject state
            setFormData(prev => ({
                ...prev,
                body: emailTemplateDetails.message || "" // Set initial body content
            }));
        }
    }, [emailTemplateId, emailTemplateDetails]);

    const handleChange = (name, value) => {
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubjectChange = (e) => {
        setSubject(e.target.value);
    };

    const stripHtmlTags = (str) => {
        if (!str) return '';
        return str.replace(/<\/?[^>]+(>|$)/g, "");
    };

    const handleSubmit = async () => {
        const emailTemplate = stripHtmlTags(emailTemplateDetails?.message) || formData.body;;
        // const subject = emailTemplateDetails?.subject || formData.subject;
        const placeholders = emailTemplate.match(/\${(.*?)}/g)?.map(ph => ph.replace(/\${|}/g, '').trim()) || [];
        
        const payload = {
            emails: candidateData?.data?.email,
            body: emailTemplate,
            subject,
            candidate_id: candidateData?.data?.id, 
            placeholders,
        };
        
        try {
            const response = await sendMail(payload);
            if (response.error) {
                throw response.error;
            }
            if (response.data.code === 200) {
                showToast("success", "send email successfully!");
            }
            handleClose();
        } catch (error) {
            if (error && error.status === 500) {
                showToast("error", "Internal Server Error. Please try again later.");
            } else if (error && error.status === 403 && error.data.message === "EmailSmtp for the user does not exist.") {
                showToast("error", "Email SMTP configuration for the user does not exist.");
            }
        }
    };

    // useEffect(() => {
    //     emailTemplateDetailsRefetch()
    //   },[emailTemplateId])

    return (
        <>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} sx={{ mt: 10 }}>
                <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: '#fff' }}>
                    <Toolbar>
                        <IconButton edge="start" color="secondary" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Grid container spacing={3}>
                            <Grid item md={8} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div" style={{ color: '#000' }}>
                                    Send Email
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Grid container sx={{ mt: 5, mb: 5 }} style={{ display: "flex", justifyContent: "center" }}>
                    <Grid item md={8}>
                        <Card style={{ backgroundColor: "#f9f9f9", height: 'auto' }}>
                            <CardContent>
                                <Grid container sx={{ mt: 1 }} style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item md={3}>
                                        <Typography variant='subtitle1' sx={{ backgroundColor: 'transparent !important' }}>
                                            Select Email Template :
                                        </Typography>
                                    </Grid>
                                    <Grid item md={9}>
                                        <FormControl sx={{ m: 1,width:550}}>
                                            <InputLabel id="demo-multiple-name-label">Select Email Template</InputLabel>
                                            <Select
                                                select
                                                id="standard-required"
                                                label="Select Email Template"
                                                variant="standard"
                                                name="email_temp_id"
                                                SelectProps={{
                                                  native: true
                                                }}
                                                fullWidth
                                                onChange={(e)=> setEmailTemplateId(e?.target?.value)}
                                                input={<OutlinedInput label="Select Email Template" />}
                                                MenuProps={MenuProps}
                                            >
                                                {emailTemplateData?.data?.map((e) =>
                                                    <MenuItem key={e.id} value={e.id}>
                                                    {e.name}
                                                    </MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {/* <Grid container sx={{ mt: 3 }} style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item md={3}>
                                        <Typography variant='subtitle1'>
                                            From :
                                        </Typography>
                                    </Grid>
                                    <Grid item md={9}>
                                        <TextField id="outlined-basic" label="From" variant="outlined" fullWidth placeholder='@gmail.com' />
                                    </Grid>
                                </Grid> */}
                                <Grid container sx={{ mt: 3 }} style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item md={3}>
                                        <Typography variant='subtitle1' sx={{ backgroundColor: 'transparent !important' }}>
                                            To :
                                        </Typography>
                                    </Grid>
                                    <Grid item md={9}>
                                        <TextField id="outlined-basic" name="emails" label="To" variant="outlined" fullWidth placeholder='@gmail.com' onChange={(e)=>handleChange(e?.target?.name,e?.target?.value)} defaultValue={props.candidateData?.data.email} disabled />
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ mt: 3 }} style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item md={3}>
                                        <Typography variant='subtitle1' sx={{ backgroundColor: 'transparent !important' }}>
                                            Subject :
                                        </Typography>
                                    </Grid>
                                    <Grid item md={9}>
                                        <TextField 
                                            id="outlined-basic" 
                                            name="subject" 
                                            label="Subject" 
                                            variant="outlined" 
                                            fullWidth 
                                            placeholder='Type your subject here...' 
                                            onChange={handleSubjectChange} 
                                            value={subject} // Added value attribute
                                            InputLabelProps={{ shrink: true }} 
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ mt: 3 }} style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item md={3}>
                                        <Typography variant='subtitle1' sx={{ backgroundColor: 'transparent !important' }}>
                                            Email Body :
                                        </Typography>
                                    </Grid>
                                    <Grid item md={9}>
                                        <Box sx={{ minHeight: '300px', height: 'auto' }}>
                                            <ReactQuill
                                                theme="snow"
                                                modules={modules}
                                                formats={formats} 
                                                value={formData.body}
                                                name="body"
                                                onChange={e => {
                                                    handleChange("body",e)
                                                //   handleChangeFormData("email_msg", e)
                                                }}
                                                style={{ width: '100%', minHeight: '300px' }}
                                            />
                                        </Box>
                                        {/* <RichTextEditer /> */}
                                    </Grid>
                                </Grid>
                                {/* <Grid container sx={{ mt: 3 }} style={{ display: "flex", alignItems: "center" }}>
                                    <Grid item md={3}>
                                        <Typography variant='subtitle1'>
                                            Attechment :
                                        </Typography>
                                    </Grid>
                                    <Grid item md={9}>
                                        <TextField id="outlined-basic" type="file" label="Attechment" variant="outlined" fullWidth defaultValue="" />
                                    </Grid>
                                </Grid> */}
                                <Box style={{ display: "flex", justifyContent: "center" }} sx={{ mt: 8 }}>
                                    <Button variant="contained" sx={{ width: "200px" }} onClick={(e)=>handleSubmit()}>Send</Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </Dialog>
        </>
    );
};

export default SendEmailModel;
