import { apiBasePath } from '../BaseUrl';
import { apiUrl } from '../../../utils/api';

const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    jobStats: build.query({
      query: (id) => ({
        url: `${apiUrl.jobs}job-stats/?job=${id}`,
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      }),
    }),
    getJob: build.query({
      query: (id) => ({
        url: `${apiUrl.jobs}all-jobs/`,
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      }),
    }),
    getJobeDetails: build.query({
      query: (id) => ({
        url: `${apiUrl.jobs}job-details/${id}`,
        // headers: {
        //   'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        // }
      }),
    }),

    getDraftJobsDetails: build.query({
      query: (id) => ({
        url: `${apiUrl.jobs}draft-job-details/${id}`,
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      }),
    }),

    addJob: build.mutation({
      query: (data) => ({
        url: `${apiUrl.jobs}job/`,
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      }),
    }),

    cloneJob: build.mutation({
      query: (id) => ({
        url: `${apiUrl.jobs}job/clone/${id}`,
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      }),
    }),

    addJobDocument: build.mutation({
      query: (data) => ({
        url: `${apiUrl.jobs}job/upload-document/`,
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      }),
    }),

    deleteJobDocument: build.mutation({
      query: (data) => ({
        url: `${apiUrl.jobs}job/upload-document/`,
        method: 'DELETE',
        body: data,
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      }),
    }),
    addJobAssesment: build.mutation({
      query: (data) => ({
        url: `${apiUrl.jobs}job/upload_assessment/`,
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      }),
    }),

    deleteJobAssesment: build.mutation({
      query: (data) => ({
        url: `${apiUrl.jobs}job/upload_assessment/`,
        method: 'DELETE',
        body: data,
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      }),
    }),

    getJobDocument: build.query({
      query: (id) => ({
        url: `${apiUrl.jobs}job/upload-document/${id}`,
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      }),
    }),

    deleteJob: build.mutation({
      query: (id) => ({
        url: `${apiUrl.jobs}job/${id}`,
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      })
    }),
    updateJob: build.mutation({
      query: ({ id, updatedJob }) => {
        // Log the updatedJob data before making the PUT request
        console.log('Updated Job id:', id);
    
        return {
          url: `${apiUrl.jobs}job/${id}`,
          method: 'PUT',
          body: updatedJob,
          headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
          }
        };
      }
    }),
    updateJobStatus: build.mutation({
      query: (data) => ({
        url: `${apiUrl.jobs}job-stats/`,
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      })
    }),

    applyJobFilter: build.mutation({
      query: (data) => ({
        url: `${apiUrl.jobs}job_query/`,
        method: 'POST',
        body: data,
        headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
        }
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useJobStatsQuery,
  useGetJobQuery,
  useGetJobeDetailsQuery,
  useGetDraftJobsDetailsQuery,
  useAddJobMutation,
  useCloneJobMutation,
  useAddJobDocumentMutation,
  useDeleteJobDocumentMutation,
  useAddJobAssesmentMutation,
  useDeleteJobAssesmentMutation,
  useGetJobDocumentQuery,
  useDeleteJobMutation,
  useUpdateJobMutation,
  useUpdateJobStatusMutation,
  useApplyJobFilterMutation
} = extendedApi;
