import React, { useEffect, useRef, useState } from 'react';
import { redirect, useNavigate, useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DoneIcon from '@mui/icons-material/Done';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Slide from '@mui/material/Slide';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { MultiSelect } from "react-multi-select-component";
import { makeStyles } from '@mui/styles';
import {
  CardContent,
  Typography,
  Card,
  Grid,
  Divider,
  ListItemIcon,
  Box,
  Menu,
  MenuItem,
  Tooltip,
  Accordion,
  AccordionDetails,
  Container,
  AccordionSummary,
  Avatar,
  Input,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Alert,
  Snackbar,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  useMediaQuery, 
  useTheme,
  CircularProgress,
  Modal
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonAdd from '@mui/icons-material/PersonAdd';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import WorkIcon from '@mui/icons-material/Work';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import AnalyticsIcon from '@mui/icons-material/Analytics';
// eslint-disable-next-line import/no-unresolved
import { showToast } from 'src/utils/toast';
import SendEmailModel from '../../../components/Mains/SendEmailModel';
import Notes from '../../../components/Notes/Notes';
import Iconify from '../../../components/Iconify';
import AssignJobModel from '../../../components/Mains/AssignJobModel';
import { useGetCandidateNotesListQuery, useGetNotesTypesQuery } from '../../../redux/services/notes/NotesServices';
import { useGetCandidateDetailsQuery, useAssignJobMutation, useGetApplicantsQuery, useUpdateCandidateStatusMutation, useCandidateStatsQuery, useHiringStageQuery, useAddMultipleCandidateMutation, useDeleteCandidateMutation, useAddCandidateDocumentMutation, useDeleteCandidateDocumentMutation  } from '../../../redux/services/candidate/CandidateServices';
import { useGetJobListQuery } from '../../../redux/services/jobs/JobListService';
import ToDoApp from '../../../components/homePage/ToDoApp';
import AssesmentModal from './AssestmentModal';
import CandidatesModel from '../../../components/Mains/CandidatesModel';
import  PDFViewer  from './PDFViewer';
import EmailCommunicationModal from '../../../components/Mains/EmailCommunicationModal'; // Import the new component
import { useGetAllEmailQuery, useGetEmailReceivedQuery, useGetEmailSendQuery } from '../../../redux/services/settings/EmailTamplateService';
import { useGetInterviewListCandidateQuery } from '../../../redux/services/interview/InterviewServices';
import PdfDisplayBE from './pdfView/pdfDisplayBE';

const extractFileName = (url) => {
  if (url) {
    const parts = url?.split('/');
    return parts[parts.length - 1];
  }
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const PerticularCandidate = (props) => {
  const { id: candidateId } = useParams();
  const navigate = useNavigate()
  const [assignJob, assignJobInfo] = useAssignJobMutation()
  const [addApplyCandidate, addApplyCandidateInfo] = useAddMultipleCandidateMutation()
  const { data: candidateData, refetch, isLoading: iscandidateDataLoading } = useGetCandidateDetailsQuery(+candidateId)
  const { data: interviewData, refetch : interviewRefetch } = useGetInterviewListCandidateQuery(candidateId)
  // const { data: cardData, refetch: cardRefetch } = useCandidateStatsQuery(id);
  const pipelineId = candidateData?.data?.job?.[0]?.pipeline?.id;
  const { data: settings, refetch: settingsRefresh } = useHiringStageQuery(pipelineId)
  // Stage is named wrongly as Status
  const [updateCandidateStatus, updateCandidateStatusInfo] = useUpdateCandidateStatusMutation()
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteCandidate, { isLoading, isSuccess }] = useDeleteCandidateMutation();
  const { data: allEmailsData, refetch: refetchAllEmails, isLoading: isAllLoading, isError: isAllerror} = useGetAllEmailQuery(candidateId, {skip: !candidateId,refetchOnMountOrArgChange: true,});
  const [emails, setEmails] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [showTopButton, setShowTopButton] = useState(false);
  const [addCandidateDocument] = useAddCandidateDocumentMutation();
  const [deleteCandidateDocument] = useDeleteCandidateDocumentMutation();
  const fileInputRef = useRef(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [activeSection, setActiveSection] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [documentTypeToDelete, setDocumentTypeToDelete] = useState(null);  
  const [pdfDisplayOpen, setPdfDisplayOpen] = useState(false);

  const [documents, setDocuments] = useState([
    { id: 1, type: 'Certificate', url: candidateData?.data.certificate, name: extractFileName(candidateData?.data.certificate) },
    { id: 2, type: 'Resume', url: candidateData?.data.resume, name: extractFileName(candidateData?.data.resume) },
    { id: 3, type: 'Cover Letter', url: candidateData?.data.cover_letter, name: extractFileName(candidateData?.data.cover_letter) },
    ...(candidateData?.data?.resume_user && candidateData.data.resume_user !== "null" ? [{ id: 4, type: 'Resume_Shaper', url: "hello", name: "View Resume" }] : [])
  ].filter(row => row.url));

  useEffect(() => {
    setDocuments([
      { id: 1, type: 'Certificate', url: candidateData?.data.certificate, name: extractFileName(candidateData?.data.certificate) },
      { id: 2, type: 'Resume', url: candidateData?.data.resume, name: extractFileName(candidateData?.data.resume) },
      { id: 3, type: 'Cover Letter', url: candidateData?.data.cover_letter, name: extractFileName(candidateData?.data.cover_letter) },
      ...(candidateData?.data?.resume_user && candidateData.data.resume_user !== "null" ? [{ id: 4, type: 'View Resume', url: "hello", name: "View Resume" }] : [])
    ].filter(row => row.url));
  }, [candidateData]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // const [hiringStageId,setHiringStageId] =useState(settings?.data?.[0]?.id)
  /* eslint-disable no-unused-vars */
  /* eslint-disable */
  let hiringStageId = settings?.data?.[0]?.id;

  const handleChangeCandidateStatus = async (status, stageName) => {

    // hiringStageUpdate();
    await updateCandidateStatus({
      id: candidateId,
      pipeline_stage_status: status,
      pipeline_stage: stageName
    });
    handleCloseUserMenu();
  };

  const handleChangeCandidateStatus2 = async (status) => {

    await updateCandidateStatus({
      id: candidateId,
      pipeline_stage_status: status,
      pipeline_stage: hiringStatus?.[0]?.name
    });
  };

  
  const { data: applicantData, refetch: applicantdataRefetch } = useGetApplicantsQuery(candidateId)
 
  const { data: jobListData } = useGetJobListQuery();
  const [selectedJob, setSelectedJob] = useState(candidateData?.job?.id);
  const handleChangeSelectedJob = (e) => setSelectedJob(+e.target.value)
  const handleUpdateSelectedJob = async () => {
    await addApplyCandidate({
      data: [selectedJob], 
      id: +candidateId,
    })
    // refetch()
  }
  useEffect(() => {
    applicantdataRefetch(),
    refetch(),
    interviewRefetch()
  }, [])

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorElUser2, setAnchorElUser2] = useState(null);
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const [selectedStage, setSelectedStage] = useState(null);

  const [workExperienceExpanded, setWorkExperienceExpanded] = useState(null);
  const [educationExpanded, setEducationExpanded] = useState(null);
  const [modelOpen, setModelOpen] = useState(false);
  const [emailModelOpen, setEmailModelOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [openAssesmentModal, setOpenAssestmentModal] = useState(false)
  // const [hiringStatus,setHiringStatus]=useState()
  const { open, handleClose } = props;
  // let hiringStatus = [];
  const [hiringStatus, setHiringStatus] = useState([])
  const openMenu = Boolean(anchorEl);
  const [fileType, setFileType] = useState(''); 
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const [qualificationOptions, setQualificationOptions] = useState([
    'Secondary_School',
    'High_School',
    'Diploma',
    'Post_Graduate_Diploma',
    'Graduate',
    'Post_Graduate',
    'Doctorate',
  ]);
  const [professionalDegreeOptions, setProfessionalDegreeOptions] = useState([
    'B.Ed.',
    'D.Ed.',
    'Diploma',
    'B.E',
    'B.Arch',
    'MBBS',
    'Other',
  ]);
  const [educationBoardOptions, setEducationBoardOptions] = useState([
    'ICSE',
    'CBSE',
    'STATE_BOARD',
    'IGCSE',
    'IB',
    'OTHER',
    'NONE',
  ]);
  const [jobRoleOptions, setJobRoleOptions] = useState([
    'Teaching',
    'Administer',
    'Manager',
    'head_of_department',
    'Coordinator',
    'OTHER',
  ]);
  const [noticePeriodOptions, setNoticePeriodOptions] = useState([
    'Immediate joining',
    '15_days',
    '1_month',
    '2_months',
    '3_months',
    '4_months',
    '5_months',
    '6_months',
  ]);

  const [countryOptions, setCountryOptions] = useState([
    'USA',
    'Canada',
    'India',
  ]);
  const [stateOptions, setStateOptions] = useState([
    'California',
    'Texas',
    'New York',
    // Add other states as needed
  ]);
  const [cityOptions, setCityOptions] = useState([
    'Los Angeles',
    'San Francisco',
    // Add other cities as needed
  ]);

  const dynamicQualification = candidateData?.data.highest_qualification;
  if (!qualificationOptions.includes(dynamicQualification)) {
    setQualificationOptions((prevOptions) => [...prevOptions, dynamicQualification]);
  }

  const dynamicProfessionalDegree = candidateData?.data.professional_degree;
  if (!professionalDegreeOptions.includes(dynamicProfessionalDegree)) {
    setProfessionalDegreeOptions((prevOptions) => [...prevOptions, dynamicProfessionalDegree]);
  }
  const dynamicEducationBoard = candidateData?.data.curriculum_board;
  if (!educationBoardOptions.includes(dynamicEducationBoard)) {
    setEducationBoardOptions((prevOptions) => [...prevOptions, dynamicEducationBoard]);
  }
  const dynamicJobRole = candidateData?.data.fun_area;
  if (!jobRoleOptions.includes(dynamicJobRole)) {
    setJobRoleOptions((prevOptions) => [...prevOptions, dynamicJobRole]);
  }
  const dynamicNoticePeriod = candidateData?.data.notice_period;
  if (!noticePeriodOptions.includes(dynamicNoticePeriod)) {
    setNoticePeriodOptions((prevOptions) => [...prevOptions, dynamicNoticePeriod]);
  }

  const skills = candidateData?.data || []; // Ensure it's an array even if undefined

  const skillsString = skills?.skills?.map(skill => skill.name).join(', ');

  const subjects = candidateData?.data?.subjects || []; // Ensure it's an array even if undefined

  const subjectsString = subjects?.map(subject => subject.name).join(', ');

  if (candidateData?.data.country) {
    const newCountry = candidateData.data.country;

    // Check if the country is not already in the options
    if (!countryOptions?.includes(newCountry)) {
      setCountryOptions((prevOptions) => [...prevOptions, newCountry]);
    }
  }

  if (candidateData?.data.state) {
    const newState = candidateData.data.state;

    // Check if the state is not already in the options
    if (!stateOptions?.includes(newState)) {
      setStateOptions((prevOptions) => [...prevOptions, newState]);
    }
  }

  if (candidateData?.data.city) {
    const newCity = candidateData.data.city;

    // Check if the city is not already in the options
    if (!cityOptions?.includes(newCity)) {
      setCityOptions((prevOptions) => [...prevOptions, newCity]);
    }
  }



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    settingsRefresh();
  }, [selectedJob]);

  const hiringStageUpdate = () => {
    setHiringStatus(settings?.data?.filter((setting) => hiringStageId === setting?.id))
    // hiringStatus= settings?.data?.filter((setting)=> hiringStageId === setting?.id)
  }
  // const settings = ['Associated Screening', 'Applied', 'Shortlisted', 'Interviewed', "Offered", "Hired", "Onboarded"];

  //   const [open, setOpen] = React.useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenUserMenu2 = (event) => {
    setAnchorElUser2(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    setAnchorElStatus(null);
    setSelectedStage(null);
  };

  const handleOpenStatusMenu = (event, stage) => {
    setAnchorElStatus(event.currentTarget);
    setSelectedStage(stage);
  };

  const handleCloseUserMenu2 = () => {
    setAnchorElUser2(null);
  };
  // const [candidate, AddCandidate] = useCandidateApplyMutation()
  // const candidate_id = localStorage.getItem("candidate_id")
  const handleUpdate = async () => {
    //   await candidate({
    //     form: webform,
    //     job: job_id,
    //     candidate: parseInt(candidate_id),
    //     assingment: assesment
    // })
  }

  const handleWorkExperienceChange = (panel) => (event, isExpanded) => {
    setWorkExperienceExpanded(isExpanded ? panel : null);
  };

  const handleEducationChange = (panel) => (event, isExpanded) => {
    setEducationExpanded(isExpanded ? panel : null);
  };

  const assignJobModel = () => {
    setModelOpen(true);
  }
  const assignJobModelClosed = () => {
    setModelOpen(false);
    setEmailModelOpen(false);
  };

  const sendEmailhandler = () => {
    setEmailModelOpen(true);
  }

  useEffect(() => {
    if (assignJobInfo.isError) {
      showToast("error", "Error while assigning the job")
      assignJobModelClosed()
    }
    if (assignJobInfo.isSuccess) {
      showToast("success", "Assigned the job to the candidate")
      assignJobModelClosed()
    }
  }, [assignJobInfo])

  // useEffect(() => {
  //   refetch()
  //   cardRefetch()
  // }, [id])

  useEffect(() => {
    // 
  }, [hiringStatus])

  useEffect(() => {
    if (updateCandidateStatusInfo.isError) {
      // console.error("Error updating candidate status:", updateCandidateStatusInfo.error);
      showToast("error", "Unable to update candidate status!")
    }
    if (updateCandidateStatusInfo.isSuccess) {
      showToast("success", "Successfully updated candidate status!")
      refetch()
      // cardRefetch()
      handleCloseUserMenu()
    }
  }, [updateCandidateStatusInfo])

  useEffect(()=>{
    if(addApplyCandidateInfo.isError){
      showToast("error", "Unable to update candidate!")
    }
    if(addApplyCandidateInfo.isSuccess){
      showToast("success", "Successfully updated candidate")
      const timeoutId = setTimeout(() => {
        navigate('/dashboard/candidates');
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  },[addApplyCandidateInfo,navigate])

  useEffect(() => {
    hiringStageUpdate()
  }, [])

  function formatDateRange(startDate, endDate) {
    const startDateFormat = new Date(startDate);
    const endDateFormat = new Date(endDate);
    const startMonthYear = startDateFormat.toLocaleString('default', { month: 'long', year: 'numeric' });
    const endMonthYear = endDateFormat.toLocaleString('default', { month: 'long', year: 'numeric' });
    return `${startMonthYear} - ${endMonthYear}`;
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  
  const handleDelete = async () => {
    await deleteCandidate(candidateId);
    // setOpenDeleteModel(false);
    if (isSuccess) {
      showToast("success", "Candidate Deleted Successfully!");
      navigate('/dashboard/candidates');
    }
  };
  
  const handleDeleteClickOpen = () => {
    setOpenDeleteModel(true);
  };

  const handleDeleteClose = () => {
    setOpenDeleteModel(false);
  };

  useEffect(() => {
    if (isSuccess) {
      const timeoutId = setTimeout(() => {
        navigate('/dashboard/candidates');
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [isSuccess, navigate]);

  const handleOpenPdfViewer = (url, fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    setPdfUrl(url);
    setFileType(fileExtension);
    setPdfViewerOpen(true);
  };
  
  const handleClosePdfViewer = () => {
    setPdfViewerOpen(false);
  };

  const handleCreateInterview = () => {
    navigate('/dashboard/NewInterview', { state: { job: candidateData?.data?.job, candidate: candidateData?.data } });
  };

  const generateWhatsAppLink = (mobile) => {
    return `https://wa.me/${mobile}`;
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };

  // const handleUploadDocument = async () => {
  //   if (selectedFile) {
  //     const formData = new FormData();
  //     formData.append('candidate_id', candidateId);
  //     formData.append('document_type', selectedDocumentType);
  //     formData.append('file', selectedFile);

  //     try {
  //       await addCandidateDocument(formData);
  //       showToast("success", "Document uploaded successfully!");
  //       setSelectedFile(null);
  //       setSelectedDocumentType('');
  //       fileInputRef.current.value = '';
  //       refetch();
  //     } catch (error) {
  //       showToast("error", "Failed to upload document.");
  //     }
  //   }
  // };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (!selectedDocumentType) {
      showToast("error", "Please select a document type before uploading.");
      return;
    }

    if (file) {

      const formData = new FormData();
      formData.append('candidate_id', candidateId);
      formData.append('document_type', selectedDocumentType);
      formData.append('file', file);

      try {
        await addCandidateDocument(formData);
        showToast("success", "Document uploaded successfully!");
        setSelectedFile(null);
        setSelectedDocumentType('');
        fileInputRef.current.value = '';
        refetch();
      } catch (error) {
        showToast("error", "Failed to upload document.");
      }
    }
  };

  const handleDeleteDialogOpen = (documentType) => {
    setDocumentTypeToDelete(documentType);
    setDeleteDialogOpen(true);
  };
  
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setDocumentTypeToDelete(null);
  };
  
  const handleDeleteConfirm = () => {
    if (documentTypeToDelete !== null) {
      deleteCandidateDocument({ candidate_id: candidateId, document_type: documentTypeToDelete })
        .then(() => {
          showToast("success", "Document deleted successfully!");
          setDocuments((prevDocuments) => prevDocuments.filter(doc => doc.type.toLowerCase().replace(' ', '_') !== documentTypeToDelete));
          refetch();
        })
        .catch(() => {
          showToast("error", "Failed to delete document.");
        });
      handleDeleteDialogClose();
    }
  };

  const handleQuickAccessClick = (sectionId) => {
    setActiveSection(sectionId);
    const element = document.getElementById(sectionId);
    const yOffset = -90; // Adjust this value to set the top margin
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (iscandidateDataLoading) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Container>
    )
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredSettings = settings?.data?.filter(stage => 
    stage.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    stage.status.some(status => status.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const columns = [
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'from', headerName: 'From', width: 300 },
    { field: 'to', headerName: 'To', width: 300 },
    {
      field: 'subject',
      headerName: 'Subject',
      width: 200,
      renderCell: (params) => (
        <Button
          onClick={() => handleSubjectClick(params.row)}
        >
          {params.value}
        </Button>
      ),
    },
    { field: 'type', headerName: 'Type', width: 100 },
    // { field: 'body', headerName: 'Body', width: 400 },
  ];

  const handleSubjectClick = (params) => {
    setSelectedEmail(params);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedEmail(null);
  };

  const formatEmailBody = (body) => {
    return body.replace(/\n/g, '<br>');
  };

  const interviewColumns = [
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        renderCell: (params) => (
          <IconButton onClick={() => navigate(`/dashboard/interviews/perticularInterview/${interviewData.list[params.id].id}`)}>
            <Iconify icon="mdi:eye" width={24} height={24} />
          </IconButton>
        ),
    },
    { field: 'title', headerName: 'Title', width: 400, renderCell: (params) => (
        <Button onClick={() => navigate(`/dashboard/interviews/perticularInterview/${interviewData.list[params.id].id}`)}>
          {params.value}
        </Button>
    )},
    { field: 'job', headerName: 'Job Name', width: 400, renderCell: (params) => (
      <Button onClick={() => navigate(`/dashboard/candidate/jobDetails/${interviewData.list[params.id].job.id}`)}>
        {params.value}
      </Button>
    )},
    { field: 'time_start', headerName: 'Time Start', width: 250 },
    { field: 'time_end', headerName: 'Time End', width: 250 },
    { field: 'type', headerName: 'Type', width: 250 },
    { field: 'date', headerName: 'Date', width: 250 },
  ];
  
  const interviewRows = interviewData?.list?.map((interview, index) => ({
    id: index,
    title: interview.title,
    job: interview.job.title,
    time_start: interview.time_start,
    time_end: interview.time_end,
    type: interview.type,
    date: interview.date,
  })) || [];

  // Function to handle opening the PDF display
  const handleOpenPdfDisplay = () => {
    setPdfDisplayOpen(true);
  };

  // Function to handle closing the PDF display
  const handleClosePdfDisplay = () => {
    setPdfDisplayOpen(false);
  };

  return (
    <div>
      <AppBar sx={{ position: 'relative' }} style={{ backgroundColor: '#f9fafb' }}>
        <Toolbar>
          <IconButton edge="start" color="secondary" aria-label="close">
            <ArrowBackIosIcon onClick={() => (navigate(-1))} />
          </IconButton>
          <Grid container spacing={3} >
              <Grid item xs={12} md={10} style={{ display: 'flex', justifyContent: isSmallScreen ? 'center' : 'space-between' }}>
                <Typography variant="h4" style={{
                  color: "black",
                  backgroundColor: "transparent",
                  width: isSmallScreen ? "100%" : "400px",
                  marginRight: isSmallScreen ? "0" : "50px",
                  marginLeft: isSmallScreen ? "0" : "10px",
                  textDecorationLine: "underline"
                }}>
                  {`${candidateData?.data.first_name} ${candidateData?.data.last_name}`}
                </Typography>
              {/* <Grid item xs={12} md={8} style={{ display: 'flex', justifyContent: isSmallScreen ? 'center' : 'space-between' }}> */}
              <Typography variant="h5" style={{
                color: "black",
                marginLeft: isSmallScreen ? "0" : "10%",
                backgroundColor: "transparent",
                fontWeight: "normal",
                textDecorationLine: "underline"
              }}>
                {candidateData?.data.email}
              </Typography>
              <Typography variant="h5" style={{
                color: "black",
                backgroundColor: "transparent",
                fontWeight: "normal",
                textDecorationLine: "underline"
              }}>
                {candidateData?.data.mobile}
              </Typography>
              </Grid>
            <Grid item md={2} style={{ textAlign: 'end', }}>
              <Box sx={{ display: 'flex', justifyContent: "flex-end" }}>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Iconify icon="entypo:dots-three-vertical" width={24} height={24} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openMenu}
                onClose={handleCloseMenu}
                onClick={handleCloseMenu}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {/* <MenuItem>
                  <ListItemIcon>
                    <PersonAdd fontSize="small" />
                  </ListItemIcon>
                  <Typography> Edit Candidate</Typography>
                </MenuItem> */}
                  <MenuItem onClick={sendEmailhandler}>
                    <ListItemIcon>
                      <AttachEmailIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography>Send Email</Typography>
                  </MenuItem>
                  {/* <MenuItem onClick={handleOpenEmailModal}>
                    <ListItemIcon>
                      <AttachEmailIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography>Email SMTP</Typography>
                  </MenuItem> */}
                  {/* <MenuItem>
                    <ListItemIcon>
                      <VisibilityIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography> View Past Application</Typography>
                  </MenuItem> */}
                  <MenuItem onClick={handleDeleteClickOpen}>
                  <ListItemIcon>
                    <DeleteForeverIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography>Delete Candidate</Typography>
                </MenuItem>
                
                {/* <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={3000}
                  onClose={handleSnackbarClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Alert onClose={handleSnackbarClose} severity={isSuccess ? "success" : "error"} sx={{ width: '100%' }}>
                    {isSuccess ? "Candidate Deleted!" : `Error: ${error?.data?.msg}`}
                  </Alert>
                </Snackbar> */}
                <MenuItem onClick={() => navigate(`/dashboard/candidates/edit-candidate/${candidateData?.data.id}`)}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography>Edit Candidate</Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {/* delete modal start*/}

      <Dialog
        open={openDeleteModel}
        onClose={handleDeleteClose}
        aria-labelledby="responsive-dialog-title"
        sx={{ '& .MuiDialog-paper': { height: '200px' } }}
      >
        <DialogTitle>{'Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this candidate?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Disagree</Button>
          <Button onClick={handleDelete} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      {/* delete modal end*/}

      <Grid container sx={{ mt: 2, pl: 3, mb: 4 }} spacing={3}>
        <Card sx={{ width: '100%', padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2}>
                {/* First Row: ID and Department */}
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" style={{ backgroundColor: 'transparent' }}>ID</Typography>
                  <Typography variant="body1" style={{ backgroundColor: 'transparent' }}>
                    {`Can${String(candidateData?.data?.id).padStart(4, '0')}` || '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" style={{ backgroundColor: 'transparent' }}>Department</Typography>
                  <Typography variant="body1" style={{ backgroundColor: 'transparent' }}>
                    {/* {candidateData?.data?.job_department} */}
                    {candidateData?.data?.job?.length > 0 ? candidateData.data.job.map((job) => job.department ? job.department.name : '-').join(', ') : '-'}
                  </Typography>
                </Grid>
                {/* Second Row: Status and Job Applied */}
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" style={{ backgroundColor: 'transparent' }}>Status</Typography>
                  <Typography variant="body1" style={{ backgroundColor: 'transparent' }}>
                    {candidateData?.data?.pipeline_stage_status}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" style={{ backgroundColor: 'transparent' }}>Job Applied</Typography>
                  {/* <Typography variant="body1" style={{ backgroundColor: 'transparent' }}>
                    {candidateData?.data.job.title}
                  </Typography> */}
                  <Typography variant="body1" style={{ backgroundColor: 'transparent' }}>
                    {candidateData?.data?.job?.map((job) => job.title).join(', ')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={7} style={{ display: 'flex', justifyContent: 'flex-end', gap: "5%", alignItems: 'center', }}>
              <Tooltip title="Assesment" arrow>
                <AssignmentIcon onClick={() => setOpenAssestmentModal(true)} sx={{ cursor: "pointer", fontSize: "50px" }} />
              </Tooltip>
              <Tooltip title="Schedule Interview" arrow>
                <PersonalVideoIcon onClick={handleCreateInterview} sx={{ cursor: "pointer", fontSize: "50px", }} />
              </Tooltip>
              <Tooltip title="Assign Job" arrow>
                <WorkIcon onClick={assignJobModel} sx={{ cursor: "pointer", fontSize: "50px" }} />
              </Tooltip>
              <Tooltip title="Hiring Status" arrow>
                <StackedBarChartIcon
                  sx={{ cursor: "pointer", fontSize: "50px" }}
                  onClick={handleOpenUserMenu}
                />
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                PaperProps={{
                  style: {
                    maxHeight: '310px', 
                    overflowY: 'auto',
                  },
                }}
              >
                <Box sx={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'white', 
                    zIndex: 1, 
                    padding: '0px 8px 8px 8px',
                    borderBottom: '1px solid #e0e0e0'
                  }}>
                  <TextField
                    placeholder="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{
                      backgroundColor: 'white', // Ensure the background color matches the menu
                    }}
                    // sx={{ mb: 1 }}
                  />
                </Box>
                <Box sx={{ maxHeight: '244px', overflowY: 'auto' }}>
                  {filteredSettings?.length > 0 ? (
                    filteredSettings.map((stage) => (
                      <div key={stage?.id}>
                        <MenuItem disabled>
                         <h4>{stage?.name}</h4>
                          {/* <Typography textAlign="center" sx={{ fontWeight: 700, color: '#000000' }}>
                            {stage?.name}
                          </Typography> */}
                        </MenuItem>
                        {stage?.status?.map((status) => (
                          <MenuItem key={status} onClick={() => handleChangeCandidateStatus(status, stage.name)}>
                            <Typography textAlign="center">{status}</Typography>
                            <Typography sx={{ paddingLeft: '10px' }}>
                              {candidateData?.data.pipeline_stage_status === status && (
                                <span style={{ color: 'green', marginLeft: '5px' }}><DoneIcon /></span>
                              )}
                            </Typography>
                          </MenuItem>
                        ))}
                      </div>
                    ))
                  ) : (
                    <MenuItem disabled>
                      <Typography textAlign="center" color="textSecondary">
                        No records available
                      </Typography>
                    </MenuItem>
                  )}
                </Box>
              </Menu>
              {/* <Tooltip title="Hiring Status" arrow>
                <AnalyticsIcon
                  sx={{ cursor: "pointer", fontSize: "50px" }}
                  onClick={handleOpenUserMenu2}
                />
              </Tooltip> */}
              {/* <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser2}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser2)}
                onClose={handleCloseUserMenu2}
              >
                {hiringStatus?.[0]?.status?.map((setting) => (
                  <MenuItem key={setting} value={setting} onClick={() => handleChangeCandidateStatus2(setting)}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu> */}
                {/* {hiringStatus?.status?.map((name)=>{
                    return (
                      <>
                      {console.log(name)}
                    <MenuItem key={name} value={name} onClick={() => 
                    //  handleChangeCandidateStatus(name)
                    console.log(name)
                    }>
                    <Typography textAlign="center">{name}</Typography>
                  </MenuItem>
                    </>
                    )
                  })} */}
                {/* {settings?.data?.filter((setting) => {

                    if(hiringStageId === setting?.id){
                      return(
                        setting.status.map((status) => {
                          return(

                      <MenuItem key={status} value={status} onClick={() => handleChangeCandidateStatus(status)}>
                      <Typography textAlign="center">{status}</Typography>
                    </MenuItem>
                        )
                          }
                        )) 
                  }

                  })} */}
              <Button
                variant="contained"
                color="primary"
                startIcon={<WhatsAppIcon />}
                href={generateWhatsAppLink(candidateData?.data.mobile)}
                target="_blank"
              >
                Chat on WhatsApp
              </Button>

              {/* <Button style={{ minWidth: 0 }} variant="outlined">
                Status{cardData?.data?.candidate_status && `: ${cardData?.data?.candidate_status}`}
              </Button> */}

            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Divider variant="middle" />
      <Stack sx={{
        display: "flex",
        flexDirection: "row",
        marginLeft: "1%"
      }}>
        <Card sx={{
          width: "200px",
          height: "400px",
          padding: "1%",
          paddingLeft: "2%",
          marginRight: "0",
          marginTop: "5%",
          position: "fixed",
          // marginLeft:"5%",
          boxShadow: "rgba(0, 0, 0, 0.25)"
        }}>
          <h3 style={{ textDecoration: 'underline' }}>Quick Access</h3>
          {['personaldetails', 'candidateprofile', 'professionaldetails', 'address', 'work', 'education','emails', 'associatedinterview', 'attachments', 'notes', 'tasklist'].map((sectionId) => (
        <a
          key={sectionId}
          className={`quickaccess ${activeSection === sectionId ? 'active' : ''}`}
          role="button"
          tabIndex={0}
          style={{ cursor: 'pointer', fontWeight: activeSection === sectionId ? 'bold' : 'normal' }}
          onClick={() => handleQuickAccessClick(sectionId)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleQuickAccessClick(sectionId);
            }
          }}
        >
          {sectionId
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase())
            .replace('Personaldetails', 'Personal Details')
            .replace('Candidateprofile', 'Candidate Profile')
            .replace('Professionaldetails', 'Professional Details')
            .replace('Work', 'Work Experience')
            .replace('Associatedinterview', 'Associated Interviews')
            .replace('Tasklist', 'Task List')}
        </a>
        ))}

        </Card>
        <Container sx={{
          width: "80%",
          marginLeft: "15%",
          // marginRight: "auto",
        }}>
          <Grid container sx={{ mt: 1, justifyContent: "center", marginLeft: "0" }} spacing={2}>
            <Container sx={{ width: "100%" }}>
              
              <Grid item md={12}>
                <h3 id='personaldetails' className='canhead'>Personal Details</h3>
              </Grid>
              <Grid container gap={2} sx={{ mt: 4, mb: 4 }}>
                <Card sx={{ width: '100%', padding: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            id="standard-required"
                            label="First Name"
                            variant="standard"
                            value={candidateData?.data.first_name || ''}
                            name="first_name"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            id="standard-required"
                            label="Middle Name"
                            variant="standard"
                            value={candidateData?.data.middle_name || ''}
                            name="middle_name"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            id="standard-required"
                            label="Last Name"
                            variant="standard"
                            value={candidateData?.data.last_name || ''}
                            name="last_name"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            id="standard-required"
                            label="Email"
                            variant="standard"
                            value={candidateData?.data.email || ''}
                            name="email"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            id="standard-required"
                            label="Alternative Email"
                            variant="standard"
                            value={candidateData?.data.alternate_email || ''}
                            name="alternate_email"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            type="number"
                            id="standard-required"
                            label="Mobile Number"
                            variant="standard"
                            value={candidateData?.data.mobile || ''}
                            name="mobile"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            type="number"
                            id="standard-required"
                            label="Alternative Mobile Number"
                            variant="standard"
                            value={candidateData?.data.alternate_mobile || ''}
                            name="alternate_mobile"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                            // disabled={true}
                            sx={{
                                width: '60%',
                            }}
                            disableFuture
                            label="Date of Birth"
                            inputFormat="YYYY-MM-DD"
                            value={candidateData?.data.date_of_birth}
                            renderInput={(params) => <TextField {...params} />}
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                            />
                        </LocalizationProvider>
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            type="number"
                            id="standard-required"
                            label="Age"
                            variant="standard"
                            value={candidateData?.data.age || ''}
                            name="age"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            id="standard-required"
                            label="Gender"
                            variant="standard"
                            value={candidateData?.data.gender || ''}
                            name="gender"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            id="standard-required"
                            variant="standard"
                            value={candidateData?.data.marital_status || ''}
                            name="marital_status"
                            label="Marital Status"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                    </Grid>
                </Card>
              </Grid>

              <Grid item md={12}>
                <h3 id='candidateprofile' className='canhead'>Candidate Profile</h3>
              </Grid>
              <Grid container sx={{ mt: 4 , mb: 4}}>
                <Card sx={{ width: '100%', padding: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item md={2}>
                    <h4>Headline</h4>
                  </Grid>
                  <Grid item md={9}>
                    <p className=''>
                      {candidateData?.data.headline}
                    </p>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
                  <Grid item md={2}>
                    <h4>Summary</h4>
                  </Grid>
                  <Grid item md={9}>
                    <p className=''>
                      {candidateData?.data.summary}
                    </p>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
                  <Grid item md={2}>
                    <h4>Total Experiance</h4>
                  </Grid>
                  <Grid item md={9}>
                    <p className='' style={{ width: "25%" }}>
                      {`${candidateData?.data.exp_years ?? 0} years ${candidateData?.data.exp_months ?? 0} months`}
                    </p>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
                  <Grid item md={2}>
                    <h4>Skills</h4>
                  </Grid>
                  <Grid item md={9}>
                    <p className=''>
                      {skillsString}
                    </p>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
                  <Grid item md={2}>
                    <h4>Subjects</h4>
                  </Grid>
                  <Grid item md={8}>
                    <p className=''>
                      {subjectsString}
                    </p>
                  </Grid>
                </Grid>
                </Card>
              </Grid>

              <Grid item md={12}>
                <h3 id='professionaldetails' className='canhead'>Professional Details</h3>
              </Grid>
              <Grid container gap={2} sx={{ mt: 4, mb: 4 }}>
                <Card sx={{ width: '100%', padding: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            id="standard-required"
                            label="Years of Experience"
                            variant="standard"
                            value={candidateData?.data.exp_years || ''}
                            name="exp_years"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            id="standard-required"
                            label="Highest Qualification held"
                            variant="standard"
                            value={candidateData?.data.highest_qualification || ''}
                            name="highest_qualification"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            id="standard-required"
                            label="Current Employer"
                            variant="standard"
                            value={candidateData?.data.cur_employer || ''}
                            name="cur_employer"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        // onChange={(e) => handleChangenewFormData(e.target.name, e.target.value)}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={10} mb={5} ml={0} mr={0}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                // disabled={true}
                                sx={{
                                width: '50%',
                                }}
                                disableFuture
                                label="From"
                                inputFormat="YYYY-MM-DD"
                                value={candidateData?.data.professional_start_date}
                                renderInput={(params) => <TextField {...params} />}
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
  
                                InputProps={{ style: { color: '#000' } }}
                                inputProps={{
                                readOnly: true,
                                }}
                            />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                // disabled={true}
                                sx={{
                                width: '50%',
                                }}
                                disableFuture
                                label="To"
                                inputFormat="YYYY-MM-DD"
                                value={candidateData?.data.professional_end_date}
                                renderInput={(params) => <TextField {...params} />}
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
  
                                InputProps={{ style: { color: '#000' } }}
                                inputProps={{
                                readOnly: true,
                                }}
                            />
                            </LocalizationProvider>
                        </Stack>
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            id="standard-required"
                            label="Current Job Title"
                            variant="standard"
                            value={candidateData?.data.current_job_title?.name || ''}
                            name="current_job_title"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        // onChange={(e) => handleChangenewFormData(e.target.name, e.target.value)}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            id="standard-required"
                            label="Professional Degree"
                            variant="standard"
                            value={candidateData?.data.professional_degree?.name || ''}
                            name="professional_degree"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            id="standard-required"
                            label="Curriculum/Board"
                            variant="standard"
                            value={candidateData?.data.curriculum_board || ''}
                            name="curriculum_board"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            id="standard-required"
                            label="Functional Area"
                            variant="standard"
                            value={candidateData?.data.fun_area || ''}
                            name="fun_area"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            id="standard-required"
                            label="Notice Period"
                            variant="standard"
                            value={candidateData?.data.notice_period || ''}
                            name="notice_period"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                    </Grid>
                </Card>
              </Grid>

              <Grid item md={12}>
                <h3 id='address' className='canhead'>Address</h3>
              </Grid>
              <Grid container gap={2} sx={{ mt: 4, mb: 4 }}>
                <Card sx={{ width: '100%', padding: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            marginRight: '0',
                            }}
                            id="standard-required"
                            label="Street"
                            variant="standard"
                            value={candidateData?.data.street || ''}
                            name="street"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            marginRight: '0',
                            }}
                            id="standard-required"
                            label="Country"
                            variant="standard"
                            value={candidateData?.data.country?.name || ''}
                            name="country"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            marginRight: '0',
                            }}
                            id="standard-required"
                            label="State"
                            variant="standard"
                            value={candidateData?.data.state?.name || ''}
                            name="state"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            marginRight: '0',
                            }}
                            id="standard-required"
                            label="City"
                            variant="standard"
                            value={candidateData?.data.city?.name || ''}
                            name="city"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        />
                        </Grid>
                        <Grid item md={6} sx={{ mt: 2, pr: 2 }}>
                        <TextField
                            // disabled={true}
                            sx={{
                            width: '100%',
                            }}
                            type="number"
                            id="standard-required"
                            label="Pin code"
                            variant="standard"
                            value={candidateData?.data.pincode || ''}
                            name="pincode"
                            InputLabelProps={{ style: { color: '#637381' }, shrink: true }}
                            InputProps={{ style: { color: '#000' } }}
                            inputProps={{
                            readOnly: true,
                            }}
                        // onChange={(e) => handleChangenewFormData(e.target.name, e.target.value)}
                        />
                        </Grid>
                    </Grid>
                </Card>
              </Grid>

              <Grid item md={12}>
                <h3 id='work' className='canhead'>Work Experience</h3>
              </Grid>
              <Grid item md={12} sx={{ mt: 4, mb: 4 }}>
                <Card>
                  <CardContent>
                    {candidateData?.data?.user_experiences.map((item, index) => (
                      <Accordion key={index} expanded={workExperienceExpanded === `panel${index}`} onChange={handleWorkExperienceChange(`panel${index}`)}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${index}-content`}
                          id={`panel${index}-header`}
                        >
                          <Typography sx={{ width: '33%', flexShrink: 0 }} variant="subtitle1">
                            {formatDateRange(item.field4, item.field5)}
                          </Typography>
                          <Typography sx={{ color: 'text.secondary' }} variant="body1">
                            {item.field2} at {item.field1}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            {item.field3}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            
              <Grid item md={12}>
                <h3 id='education' className='canhead'>Education Details</h3>
              </Grid>
              <Grid item md={12} sx={{ mt: 4, mb: 4 }}>
                <Card>
                  <CardContent>
                    {candidateData?.data?.user_educations.map((item, index) => (
                      <Accordion key={index} expanded={educationExpanded === `panel${index}`} onChange={handleEducationChange(`panel${index}`)}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${index}-content`}
                          id={`panel${index}-header`}
                        >
                          <Typography sx={{ width: '33%', flexShrink: 0 }} variant="subtitle1">
                            {formatDateRange(item.field4, item.field5)}
                          </Typography>
                          <Typography sx={{ color: 'text.secondary' }} variant="body1">
                            {item.field2} {item.field3} from {item.field1}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            {/* Your additional details go here */}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </CardContent>
                </Card>
              </Grid>

              <Grid item md={12}>
                <Card id='emails' sx={{ mt:2, ml: 0, mr: 4, p: 2, width: '100%' }}>
                  <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={10} sx={{ mt: 1, mb: 2 }}>
                      <Typography variant="h6">Emails</Typography>
                    </Grid>
                    <Grid item md={2} style={{ textAlign: 'center' }}>
                      <Button
                      onClick={sendEmailhandler}
                      variant="contained"
                      style={{ textTransform: 'capitalize' }}
                      >
                      Send Email
                      </Button>
                  </Grid>
                  </Grid>
                  <Divider />
                  <DataGrid
                      rows={
                        allEmailsData
                          ? [
                              ...(Array.isArray(allEmailsData.received_emails) ? allEmailsData.received_emails : []),
                              ...(Array.isArray(allEmailsData.sent_emails) ? allEmailsData.sent_emails : [])
                            ].map((email, index) => {
                              const isReceived = allEmailsData?.received_emails?.includes(email);
                              return {
                                id: index,
                                date: email.date,
                                from: email.from,
                                to: email.to,
                                subject: email.subject,
                                type: isReceived ? 'Received' : 'Sent',
                                body: email.body,
                              };
                            })
                          : []
                      }
                      columns={columns}
                      pageSize={5}
                      rowsPerPageOptions={[10]}
                      autoHeight
                    />
                </Card>
              </Grid>

              <Grid item md={12}>
                <Card id='associatedinterview' sx={{ mt:2, ml: 0, mr: 4, p: 2, width: '100%' }}>
                  <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={12} md={5} sx={{ mt: 1, mb: 2 }}>
                      <Typography variant="h6">Associated Interviews</Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                    <DataGrid
                      rows={interviewRows}
                      columns={interviewColumns}
                      pageSize={5}
                      rowsPerPageOptions={[10]}
                      autoHeight
                      // onRowClick={handleRowClick} 
                    />
                </Card>
              </Grid>

              <Grid item md={12}>
                <Card id='attachments' sx={{ mt:2, ml: 0, mr: 4, p: 2, width: '100%' }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={5} sx={{ mt: 1, mb: 2 }}>
                      <Typography variant="h6">Attachments</Typography>
                    </Grid>
                    <Grid item  xs={12} md={7}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexWrap: 'nowrap' }}>
                        <FormControl sx={{ m: 1, minWidth: 200 }}>
                          <InputLabel id="demo-simple-select-autowidth-label">Select Document Type</InputLabel>
                          <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={selectedDocumentType}
                            onChange={(e) => setSelectedDocumentType(e.target.value)}
                            label="Select Document Type"
                            // autoWidth
                            sx={{ width: 200 }} 
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="resume">Resume</MenuItem>
                            <MenuItem value="cover_letter">Cover Letter</MenuItem>
                            <MenuItem value="certificate">Certificate</MenuItem>
                          </Select>
                        </FormControl>
                        <Button
                          variant="contained"
                          onClick={handleButtonClick}
                          sx={{ ml: 2 }}
                        >
                          Choose File
                        </Button>
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleFileChange}
                          style={{ display: 'none' }} // Hide the input
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider />
                  <div style={{ height: 300, width: '100%', marginTop: '30px' }}>
                    <DataGrid
                      rows={documents}
                        columns={[
                          { field: 'type', headerName: 'Attachment Type', width: 200 },
                          { 
                            field: 'view', 
                            headerName: 'View', 
                            width: 150, 
                            renderCell: (params) => (
                              <IconButton
                              onClick={() => {
                                if (params.row.type === 'View Resume') {
                                  handleOpenPdfDisplay(); // Open the PDF display for "View Resume"
                                } else {
                                  handleOpenPdfViewer(params.row.url, params.row.name); // Handle other document types
                                }
                              }}
                              disabled={params.row.type === 'View Resume' && !candidateData?.data?.resume_data}
                            >
                              <Iconify icon="mdi:eye" width={24} height={24} />
                            </IconButton>
                            ),
                          },
                          { field: 'name', headerName: 'File Name', width: 800 },
                          {
                            field: 'url',
                            headerName: 'Action',
                            width: 200,
                            renderCell: (params) => (
                            <Box sx={{ display: 'flex', gap: '10px' }}>
                              {/* <Button
                                variant="contained"
                                onClick={() => handleDeleteDialogOpen(params.row.type.toLowerCase().replace(' ', '_'))}
                              >
                                Delete
                              </Button> */}
                              {params.row.type !== 'View Resume' && ( // Only render the button if the type is not 'View Resume'
                                <Button
                                  variant="contained"
                                  onClick={() => handleDeleteDialogOpen(params.row.type.toLowerCase().replace(' ', '_'))}
                                >
                                  Delete
                                </Button>
                              )}
                            </Box>
                            ),
                          },
                        ]}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                      />
                  </div>
                </Card>
                {(['pdf', 'docx', 'jpg', 'jpeg', 'png', 'gif'].includes(fileType)) ? (
                  <PDFViewer open={pdfViewerOpen} onClose={handleClosePdfViewer} fileUrl={pdfUrl} fileType={fileType} />
                ):(
                  <PdfDisplayBE
                  pdfDisplayOpen={pdfDisplayOpen}
                  resumeData={candidateData?.data?.resume_data}
                  resumeUser={candidateData?.data?.resume_user}
                  onClose={handleClosePdfDisplay}
                  />
                )} 
              </Grid>
            
              <Grid item md={12}>
                <Card id='notes' sx={{ mt:4, ml: 0, mr: 4, p: 2, width: '100%' }}>
                  <Notes candidateId={candidateId} />
                </Card>
                <Card id='tasklist' sx={{ mt:4, ml: 0, mr: 4, p: 2, width: '100%' }}>
                  <ToDoApp style={{ marginLeft: "0%" }} candidateId={candidateId} />
                </Card>
              </Grid>
            </Container>
          </Grid>
        </Container>
      </Stack>
      <AssesmentModal open={openAssesmentModal} data={candidateData?.data?.assessment_data} handleClose={() => setOpenAssestmentModal(false)} />
      <AssignJobModel
        open={modelOpen}
        handleClose={assignJobModelClosed}
        jobs={jobListData}
        value={selectedJob}
        handleChange={handleChangeSelectedJob}
        handleSubmit={handleUpdateSelectedJob}
        name="Job"
      />
      <SendEmailModel open={emailModelOpen} handleClose={() => assignJobModelClosed()} candidateData={candidateData} />
      {/* <EmailCommunicationModal
        open={emailModalOpen}
        onClose={handleCloseEmailModal}
      /> */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            p: 4,
            bgcolor: 'background.paper',
            width: '50%', // Set width to 90% of the viewport width
            maxWidth: 'none',
            maxHeight: '80vh', // Limit height for scrolling
            overflowY: 'auto', // Enable vertical scrolling
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          {selectedEmail && (
              <>
              <Typography variant="h6" gutterBottom>
                {selectedEmail.subject}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>From:</strong> {selectedEmail.from}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>To:</strong> {selectedEmail.to}
              </Typography>
              <Box
                sx={{ marginTop: 2 }}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formatEmailBody(selectedEmail.body)) }}
              />
            </>
          )}
        </Box>
      </Modal>

      {showTopButton && (
        <Button
          onClick={handleScrollToTop}
          variant="contained"
          color="primary"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 1000,
          }}
        >
          <ArrowUpwardIcon />
        </Button>
      )}

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        sx={{ '& .MuiDialog-paper': { minHeight: '200px' } }} // Adjust the height as needed
      >
        <DialogTitle>Delete Document</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this document?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

    </div>

  );
};

export default PerticularCandidate;