import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { GridToolbar } from '@mui/x-data-grid';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  ListItemIcon,
  Grid,
  DialogContent,
  TableContainer,
  useMediaQuery,
  Tabs,
  Tab,
} from '@mui/material';
// components
import { formatQuery, QueryBuilder } from 'react-querybuilder';
import { QueryBuilderAntD } from '@react-querybuilder/antd';
import { Education, Type, JobNature, SalaryType } from './JobsFilterOptions';
import 'react-querybuilder/dist/query-builder.css';
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import JobModel from '../../../components/Mains/JobModel';
import { showToast } from '../../../utils/toast';
import { useGetJobListQuery, useFilterJobMutation, useGetDraftJobListQuery, usePublishDraftJobMutation } from '../../../redux/services/jobs/JobListService';
import { useAddJobMutation, useDeleteJobMutation, useApplyJobFilterMutation } from '../../../redux/services/jobs/JobServices';
import "./job.css";
import OperatorMappings from '../OperatorMappings';
// mock

// const style = {
//   position: 'absolute',
//   top: '20%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

const dateJob = [
  { name: 'contains', label: '=' },
  { name: 'notContains', label: '!=' },
  { name: 'greaterThan', label: '>' },
  { name: 'greaterThanOrEqual', label: '>=' },
  { name: 'lessThan', label: '<' },
  { name: 'lessThanOrEqual', label: '<=' },
  { name: 'blank', label: 'is blank' },
  { name: 'notBlank', label: 'is not blank' },
]

const initialQuery = { 
  combinator: 'and',
  rules: [
    { field: 'title', operator: 'contains', value: '' },
  ] 
};

const fields = [
  { name: 'title', label: 'Title', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'department', label: 'Department', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'owner', label: 'Owner', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'exp_max', label: 'Max Experience', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'number', operators: OperatorMappings().number },
  { name: 'exp_min', label: 'Min Experience', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'number', operators: OperatorMappings().number },
  { name: 'speciality', label: 'Speciality', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'vacancies', label: 'Vacancies', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'number', operators: OperatorMappings().number },
  { name: 'salary_min', label: 'Min Salary', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'number', operators: OperatorMappings().number },
  { name: 'salary_max', label: 'Max Salary', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'number', operators: OperatorMappings().number },
  { name: 'created', label: 'Publishing Date', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'date', operators: dateJob },
  { name: 'assessment', label: 'Assessment Name', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  {
    name: 'salary_type',
    label: 'Salary Type',
    valueEditorType: 'multiselect',
    values: SalaryType,
    defaultValue: 'Cowbell',
    valueSources: ['value'],
    groupNumber: 1,
    comparator: '=',
    operators: OperatorMappings().object,
  },
  {
    name: 'type',
    label: 'Job Type',
    valueEditorType: 'multiselect',
    values: Type,
    defaultValue: 'Cowbell',
    valueSources: ['value'],
    groupNumber: 1,
    comparator: '=',
    operators: OperatorMappings().object,
  },
  {
    name: 'nature',
    label: 'Job Nature',
    valueEditorType: 'multiselect',
    values: JobNature,
    defaultValue: 'Cowbell',
    valueSources: ['value'],
    groupNumber: 1,
    comparator: '=',
    operators: OperatorMappings().object,
  },
  {
    name: 'educations',
    label: 'Education',
    valueEditorType: 'multiselect',
    values: Education,
    defaultValue: 'Cowbell',
    valueSources: ['value'],
    groupNumber: 1,
    comparator: '=',
    operators: OperatorMappings().object,
  },
];

export const validator = (r) => !!r.value;

const addGroupAction = (props) => null;

const Jobs = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [modelOpen, setModelOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const { data, refetch ,isLoading: isJobDataLoading} = useGetJobListQuery();
  const { data : draftJobData , refetch : draftJobRefetch ,isLoading: isDraftJobLoading} = useGetDraftJobListQuery();
  const [filterData, setFilterData] = useState(tabValue === 0 ? data : draftJobData?.data);

  useEffect(() => {
    if (tabValue === 0) {
      setFilterData(data || []);
    } else {
      setFilterData(draftJobData?.data || []);
    }
  }, [data, draftJobData, tabValue]);

  const handleClearFilter = () => {
    setQuery(initialQuery);
    setFilterData(data); 
    refetch();
  };

  const [detailsId, setDetailsId] = useState();
  const companyId = JSON.parse(localStorage.getItem("globalUser"))?.company?.id
  // const { data, refetch } = useGetJobQuery(companyId);
  const { editJobId } = useParams();

  const [currentIndex, setCurrentIndex] = useState(editJobId);
  const [deleteJobId, setDeleteJobID] = useState()
  const [addJobData, addJobDataInfo] = useAddJobMutation();
  const [filterJob, filterJobInfo] = useFilterJobMutation();
  const [deleteJob, deleteJobInfo] = useDeleteJobMutation();
  const [applyJobFilter, applyJobFilterInfo] = useApplyJobFilterMutation();
  const [publishDraftJob] = usePublishDraftJobMutation();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [jobApplied, setJobApplied] = useState(null);
  const [education, setEducation] = useState('');
  const [hiring, setHiring] = useState('');
  const [opens, setOpens] = useState(false);
  const handleOpens = () => setOpens(true);
  const handleCloses = () => setOpens(false);
  const [simpleFilterForm, setSimpleFilterForm] = useState(true)
  const [advanceFilterForm, setAdvanceFilterForm] = useState(false)
  const [query, setQuery] = useState(initialQuery);
  const [filtersData, setFiltersData] = useState({})
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const savedVisibility = localStorage.getItem("columnVisibilityJob");
    return savedVisibility ? JSON.parse(savedVisibility) : {
      view: true,
      id: false,
      title: true,
      created: true,
      department_name: true,
      owner: true,
      updated: true,
      exp_max: true,
      exp_min: true,
      speciality: true,
      educations: true,
      nature: true,
      type: true,
      action: true,
    };
  });

  const [draftColumnVisibility, setDraftColumnVisibility] = useState(() => {
    // Load column visibility from local storage or use default values
    const savedVisibility = localStorage.getItem("columnVisibilityDraftJob");
    return savedVisibility ? JSON.parse(savedVisibility) : {
      view: true,
      id: false,
      title: true,
      created: true,
      department_name: true,
      owner: true,
      exp_max: true,
      exp_min: true,
      speciality: true,
      educations: true,
      nature: true,
      type: true,
      action: true,
    };
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleFromDate = (newValue) => {
    if (newValue !== null) {
      const formattedDate = `${newValue.getFullYear()}-${(newValue.getMonth() + 1).toString().padStart(2, '0')}-${newValue.getDate().toString().padStart(2, '0')}`;
      setFromDate(formattedDate);
    }
  };

  const handleToDate = (newValue) => {
    if (newValue !== null) {
      const formattedDate = `${newValue.getFullYear()}-${(newValue.getMonth() + 1).toString().padStart(2, '0')}-${newValue.getDate().toString().padStart(2, '0')}`;
      setToDate(formattedDate);
    }
  };

  const handleJobApplied = (newValue) => {
    if (newValue !== null) {
      const formattedDate = `${newValue.getFullYear()}-${(newValue.getMonth() + 1).toString().padStart(2, '0')}-${newValue.getDate().toString().padStart(2, '0')}`;
      setJobApplied(formattedDate);
    }
  };

  const handleEducation = (event) => {
    setEducation(event.target.value);
  }

  const handleHiring = (event) => {
    setHiring(event.target.value);
  }

  const showAdvanceFilterForm = () => {
    setAdvanceFilterForm(true)
    setSimpleFilterForm(false)
  }

  const removePercentageInBrackets = (query) => {
    // Use regular expression to match and replace % within brackets
    const modifiedQuery = query.replace(/\(([^)]*)\)/g, (match, content) => {
      // Replace % within the content with an empty string
      const withoutPercentage = content.replace(/%/g, '');
      return `(${withoutPercentage})`;
    });

    return modifiedQuery;
  };

  const handleQueryChange = (newQuery) => {
    setQuery(newQuery);
  };

  const applyFilter = async () => {
    let formattedQuery = formatQuery(query, 'sql');
  
    // Replace 'and' and 'or' with their uppercase equivalents
    formattedQuery = formattedQuery.replace(/\band\b/g, 'AND');
    formattedQuery = formattedQuery.replace(/\bor\b/g, 'OR');
  
    // Replace specific operators with their required formats
    formattedQuery = formattedQuery.replace(/\bcontains\b/g, 'LIKE');
    formattedQuery = formattedQuery.replace(/\bnotContains\b/g, 'NOT LIKE');
    formattedQuery = formattedQuery.replace(/\bequals\b/g, '=');
    formattedQuery = formattedQuery.replace(/\bnotEqual\b/g, '<>');
    formattedQuery = formattedQuery.replace(/\bstartsWith\b/g, 'StartWith');
    formattedQuery = formattedQuery.replace(/\bendWith\b/g, 'EndWith');
    formattedQuery = formattedQuery.replace(/\bblank\b/g, 'IS NULL');
    formattedQuery = formattedQuery.replace(/\bnotBlank\b/g, 'IS NOT NULL');
    formattedQuery = formattedQuery.replace(/\bgreaterThan\b/g, '>');
    formattedQuery = formattedQuery.replace(/\bgreaterThanOrEqual\b/g, '>=');
    formattedQuery = formattedQuery.replace(/\blessThan\b/g, '<');
    formattedQuery = formattedQuery.replace(/\blessThanOrEqual\b/g, '<=');
    formattedQuery = formattedQuery.replace(/\btrue\b/g, 'TRUE');
    formattedQuery = formattedQuery.replace(/\bfalse\b/g, 'FALSE');
  
    // Add percentage signs for LIKE queries
    formattedQuery = formattedQuery.replace(/LIKE '([^']*)'/g, "LIKE '%$1%'");
    formattedQuery = formattedQuery.replace(/NOT LIKE '([^']*)'/g, "NOT LIKE '%$1%'");
    formattedQuery = formattedQuery.replace(/LIKE '%([^']*)'/g, "LIKE '%$1'"); // For endsWith
    formattedQuery = formattedQuery.replace(/LIKE '([^']*)%'/g, "LIKE '$1%'"); // For startsWith
  
    try {
      const response = await applyJobFilter({ query: formattedQuery });
      setFilterData(response?.data?.result || []);
    } catch (error) {
      console.error("Error applying filter:", error);
    }
  };


  const navigateCancel = () => {
    // navigate("/dashboard/app")
    navigate(-1);
  }

  const onJobViewModel = (jobId) => {
    setModelOpen(true);
    setDetailsId(jobId);
  };
  const handleClose = () => {
    setModelOpen(false);
    refetch();
  };
 
  // const sortData = useMemo(() => {
  //   const sortresult = sortedDataFn(filterData);
  //   return sortresult;
  // }, [filterData]);

  // Delete Handler
  const onDeletJobeHandler = async (deleteId) => {
    setCurrentIndex(deleteId);
    showToast('success', 'Job deleted successfully');
    setActive(true);
    setOpen(false);
    await deleteJob(deleteId);
  };

  const [open, setOpen] = React.useState(false);
  const [active, setActive] = React.useState(false);

  const handleeClosee = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setActive(false);
  };

  const handleClickOpen = (id) => {
    setDeleteJobID(id)
    setOpen(true);
  };

  const handleClosee = () => {
    setOpen(false);
  };

  const handlePublishDraftJob = async (jobId) => {
    try {
      await publishDraftJob(jobId);
      showToast("success", "Job published successfully!");
      refetch();
      // Optionally, refetch data or navigate to another page
    } catch (error) {
      showToast("error", "Failed to publish job!");
    }
  };

  useEffect(() => {
    if (deleteJobInfo.isSuccess) {
      showToast('success', deleteJobInfo?.data?.msg);
      deleteJobInfo.reset();
      refetch();
    }
    if (deleteJobInfo.isError) {
      showToast('error', deleteJobInfo.error.data.msg);
      deleteJobInfo.reset();
      refetch();
    }
  }, [addJobDataInfo, deleteJobInfo]);

  useEffect(() => {
    refetch();
    draftJobRefetch();
  }, []);

  const columns = [
    {
      name: 'view',
      label: 'View',
      options: {
        display: columnVisibility.view,
        filter: false,
        sort: false,
        setCellProps: () => ({ 'data-label': 'View' }),
        customBodyRenderLite: (dataIndex) => (
          <>
            <Button
              style={{ minWidth: 0, marginRight: '5px' }}
              // variant="contained"
              onClick={() => navigate(`/dashboard/candidate/jobDetails/${filterData?.[dataIndex]?.id}`)}
              color="info"
            >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="carbon:view-filled" width={24} height={24} sx={{ color: '#C2CFE0' }} />
              </ListItemIcon>
            </Button>
          </>
        ),
      },
    },
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
        display: columnVisibility.id,
        setCellProps: () => ({ 'data-label': 'Id' }),
      },
    },
    {
      name: 'title',
      label: 'Job title',
      options: {
        display: columnVisibility.title,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Job title' }),
        customBodyRender: (value, tableMeta, updateValue) => {
          const dataIndex = tableMeta.rowIndex;
          const jobId = tableMeta.currentTableData[dataIndex]?.data[1];
          return (
            <Button
              onClick={() => navigate(`/dashboard/candidate/jobDetails/${jobId}`)}
              sx={{
                color: "primary",
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {value}
            </Button>
          );
        },
      },
    },
    {
      name: 'created',
      label: 'Publishing Date',
      options: {
        display: columnVisibility.created,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Publishing Date' }),
        // customHeadRender: (columnMeta) => (
        //   <th className="state-header" style={{ color: '#334D6E', opacity: '0.5' }}>{columnMeta.label}</th>
        // ),
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value && typeof value === 'string') {
            const originalDate = new Date(value);
            const formattedDate = `${(originalDate.getMonth() + 1).toString().padStart(2, '0')}/${originalDate.getDate().toString().padStart(2, '0')}/${originalDate.getFullYear()}`;
            return (
              <div >
                {formattedDate}
              </div>
            );
          }
          return (
            <div >
              { }
            </div>
          );

        },
      },
    },
    {
      name: 'department_name',
      label: 'Department',
      options: {
        display: columnVisibility.department_name,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Department' }),
        customBodyRender: (value, metaData) => {
          const department = value || (filterData && filterData[metaData.rowIndex]?.department__name) || 'N/A';
          return department;
        },
      },
    },
    {
      name: 'owner',
      label: 'Owner',
      options: {
        display: columnVisibility.owner,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Owner' }),
        customBodyRender: (value, metaData) => {
          const owner = value?.first_name || (filterData && filterData[metaData.rowIndex]?.owner__first_name) || 'N/A';
          return owner;
        },
      },
    },
    {
      name: 'updated',
      label: 'Modified date and time',
      options: {
        display: columnVisibility.updated,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Modified date and time' }),
        customBodyRender: (value) => {
          if (value) {
            const date = new Date(value);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const formattedDate = `${day}/${month}/${year} ${date.toLocaleTimeString()}`;
            return <div>{formattedDate}</div>;
          }
          return <div>N/A</div>;
        },
      },
    },
    {
      name: 'exp_max',
      label: 'Max Experience',
      options: {
        display: columnVisibility.exp_max,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Max Experience' }),
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: 'exp_min',
      label: 'Min Experience',
      options: {
        display: columnVisibility.exp_min,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Min Experience' }),
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: 'speciality',
      label: 'Speciality',
      options: {
        display: columnVisibility.speciality,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Speciality' }),
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: 'educations',
      label: 'Education',
      options: {
        display: columnVisibility.educations,
        filter: true,
        sort: true,
        // display: false, 
        setCellProps: () => ({ 'data-label': 'Education' }),
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: 'nature',
      label: 'Job Nature',
      options: {
        display: columnVisibility.nature,
        filter: true,
        sort: true,
        // display: false, 
        setCellProps: () => ({ 'data-label': 'Job Nature' }),
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: 'type',
      label: 'Job Type',
      options: {
        filter: true,
        sort: true,
        display: columnVisibility.type,
        setCellProps: () => ({ 'data-label': 'Job Type' }),
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: 'action',
      label: 'Action',
      options: {
        display: columnVisibility.action,
        filter: false,
        sort: false,
        setCellProps: () => ({ 'data-label': 'Action' }),
        customBodyRenderLite: (dataIndex) => (
          <>
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleeClosee}
              sx={{ height: '10vh' }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                position: 'absolute',
              }}
            >
              <Alert onClose={handleeClosee} severity="success" sx={{ width: '100%' }}>
                Job Deleted!
              </Alert>
            </Snackbar>
            <div>
              <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClosee}
                aria-labelledby="responsive-dialog-title"
                sx={{ '& .MuiDialog-paper': { height: '200px' } }}
              >
                <DialogTitle variant="h4">{'Delete'}</DialogTitle>
                <DialogContent>
                  <DialogContentText>Are you sure you wan&#39;t to delete this job?</DialogContentText>
                  {/* <Button autoFocus onClick={handleClosee}>
                    Disagreeeeee
                  </Button>
                  <Button
                    onClick={() => onDeletJobeHandler(deleteJobId)}
                    loading={dataIndex === currentIndex ? useDeleteJobMutation.isLoading : false}
                    autoFocus
                  >
                    Agree
                  </Button> */}
                <DialogActions>
                  <Button autoFocus onClick={handleClosee}>
                    Disagree
                  </Button>
                  <Button
                    onClick={() => onDeletJobeHandler(deleteJobId)}
                    loading={dataIndex === currentIndex ? useDeleteJobMutation.isLoading : false}
                    autoFocus
                    >
                    Agree
                  </Button>
                </DialogActions>
                </DialogContent>
              </Dialog>
            </div>
            <Button
              style={{ minWidth: 0 }}
              // variant="contained"
              component={RouterLink}
              to={`/dashboard/jobs/edit-job/${filterData?.[dataIndex].id}`}
            // onClick={() => onEditModalHandler(dataIndex)}
            >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="ep:edit" width={24} height={24} sx={{ color: 'blue' }} />
              </ListItemIcon>
            </Button>
            <Button
              style={{ minWidth: 0, margin: '0px 5px' }}
              // variant="contained"
              color="error"
              // onClick={() => onDeletJobeHandler(data?.[dataIndex].id)}
              onClick={() => handleClickOpen(filterData?.[dataIndex].id)}
              loading={dataIndex === currentIndex ? useDeleteJobMutation.isLoading : false}
            >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="eva:trash-2-outline" width={24} height={24} sx={{ color: 'red' }} />
              </ListItemIcon>
            </Button>
          </>
        ),
      },
    },
  ];

  const draftJobColumns = [
    {
      name: 'view',
      label: 'View',
      options: {
        display: draftColumnVisibility.view,
        filter: false,
        sort: false,
        setCellProps: () => ({ 'data-label': 'View' }),
        customBodyRenderLite: (dataIndex) => (
          <>
            <Button
              style={{ minWidth: 0, marginRight: '5px' }}
              // variant="contained"
              onClick={() => navigate(`/dashboard/candidate/draftJobsDetails/${filterData?.[dataIndex]?.id}`)}
              color="info"
            >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="carbon:view-filled" width={24} height={24} sx={{ color: '#C2CFE0' }} />
              </ListItemIcon>
            </Button>
          </>
        ),
      },
    },
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
        display: draftColumnVisibility.id,
        setCellProps: () => ({ 'data-label': 'Id' }),
      },
    },
    {
      name: 'title',
      label: 'Job title',
      options: {
        display: draftColumnVisibility.title,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Job title' }),
        customBodyRender: (value, tableMeta, updateValue) => {
          const dataIndex = tableMeta.rowIndex;
          return (
            <Button
              onClick={() => navigate(`/dashboard/candidate/draftJobsDetails/${filterData?.[dataIndex]?.id}`)}
              sx={{
                color: "primary",
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {value}
            </Button>
          );
        },
      },
    },
    {
      name: 'created',
      label: 'Publishing Date',
      options: {
        display: draftColumnVisibility.created,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Publishing Date' }),
        // customHeadRender: (columnMeta) => (
        //   <th className="state-header" style={{ color: '#334D6E', opacity: '0.5' }}>{columnMeta.label}</th>
        // ),
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value && typeof value === 'string') {
            const originalDate = new Date(value);
            const formattedDate = `${(originalDate.getMonth() + 1).toString().padStart(2, '0')}/${originalDate.getDate().toString().padStart(2, '0')}/${originalDate.getFullYear()}`;
            return (
              <div >
                {formattedDate}
              </div>
            );
          }
          return (
            <div >
              { }
            </div>
          );

        },
      },
    },
    {
      name: 'department_name',
      label: 'Department',
      options: {
        display: draftColumnVisibility.department_name,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Department' }),
        customBodyRender: (value, metaData) => {
          const department = value || (filterData && filterData[metaData.rowIndex]?.department__name) || 'N/A';
          return department;
        },
      },
    },
    {
      name: 'owner',
      label: 'Owner',
      options: {
        display: draftColumnVisibility.owner,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Owner' }),
        customBodyRender: (value, metaData) => {
          const owner = value?.first_name || (filterData && filterData[metaData.rowIndex]?.owner__first_name) || 'N/A';
          return owner;
        },
      },
    },
    {
      name: 'exp_max',
      label: 'Max Experience',
      options: {
        display: draftColumnVisibility.exp_max,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Max Experience' }),
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: 'exp_min',
      label: 'Min Experience',
      options: {
        display: draftColumnVisibility.exp_min,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Min Experience' }),
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    // {
    //   name: 'speciality',
    //   label: 'Speciality',
    //   options: {
    //     display: draftColumnVisibility.speciality,
    //     filter: true,
    //     sort: true,
    //     setCellProps: () => ({ 'data-label': 'Speciality' }),
    //     customBodyRender: (value) => <div>{value}</div>,
    //   },
    // },
    {
      name: 'educations',
      label: 'Education',
      options: {
        display: draftColumnVisibility.educations,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Education' }),
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: 'nature',
      label: 'Job Nature',
      options: {
        display: draftColumnVisibility.nature,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Job Nature' }),
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: 'type',
      label: 'Job Type',
      options: {
        display: draftColumnVisibility.type,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Job Type' }),
        customBodyRender: (value) => <div>{value}</div>,
      },
    },
    {
      name: 'action',
      label: 'Action',
      options: {
        display: draftColumnVisibility.action,
        filter: false,
        sort: false,
        setCellProps: () => ({ 'data-label': 'Action' }),
        customBodyRenderLite: (dataIndex) => {
          const job = filterData?.[dataIndex];
          return (
            <>
              {job?.published === false && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    navigate(`/dashboard/jobs/create-job`, { state: { editJobType : 'draftJob', draftJobId: job?.id } })}
                  }
                  sx={{ textTransform: 'capitalize' }}
                >
                  Publish
                </Button>
              )}
              {/* <Button
                style={{ minWidth: 0, marginLeft: '5px' }}
                // component={RouterLink}
                onClick={() => {
                  navigate(`/dashboard/jobs/create-job`, { state: { editJobType : 'draftJob', draftJobId: job?.id } })}
                }
              >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="ep:edit" width={24} height={24} sx={{ color: 'blue' }} />
              </ListItemIcon>
            </Button> */}
            </>
          );
        },
      },
    },
  ];

  const labelStatus = (
    <Label variant="ghost" color={'success'}>
      {sentenceCase('active')}
    </Label>
  );
  // const data = [
  //   { name: 'Joe James', status: labelStatus },
  //   { name: 'John Walsh', status: labelStatus },
  //   { name: 'Bob Herm', status: labelStatus },
  //   { name: 'James Houston', status: labelStatus },
  // ];

  useEffect(() => {
    localStorage.setItem('columnVisibilityJob', JSON.stringify(columnVisibility));
  }, [columnVisibility]);

  const options = {
    filterType: 'dropdown',
    responsive: 'standard', // or 'vertical', 'simple'
    filter: true,
    download: true,
    print: true,
    tableBodyHeight: '400px', // Adjust as needed
    tableBodyMaxHeight: '600px', // Adjust as needed
    selectableRows: 'multiple',
    rowsPerPage: 10, // Adjust as needed
    rowsPerPageOptions: [10, 20, 30], // Adjust as needed
    customToolbarSelect: () => {}, // Customize toolbar if needed
    viewColumns: true,
    onColumnViewChange: (changedColumn, action) => {
      setColumnVisibility((prevVisibility) => ({
        ...prevVisibility,
        [changedColumn]: action === 'add',
      }));
    },
  };

  useEffect(() => {
    localStorage.setItem('columnVisibilityDraftJob', JSON.stringify(draftColumnVisibility));
  }, [draftColumnVisibility]);

  const draftOptions = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    download: true,
    print: true,
    tableBodyHeight: '400px',
    tableBodyMaxHeight: '600px',
    selectableRows: 'multiple',
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 30],
    viewColumns: true,
    onColumnViewChange: (changedColumn, action) => {
      setDraftColumnVisibility((prevVisibility) => ({
        ...prevVisibility,
        [changedColumn]: action === 'add',
      }));
    },
  };

  // const getInputValue = (value) => {};

  const [value, setValue] = React.useState(null);

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClosee = () => {
  //   setOpen(false);
  // };
  useEffect(() => {
    const element = document.getElementById("changer");
    const parentElement = element?.parentElement;
    parentElement.style.paddingTop = "90px"
  },[])

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // if (isJobDataLoading || !filterData) {
  //   return (
  //     <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
  //       <CircularProgress />
  //     </Container>
  //   )
  // }

  return (
    <>
      {/* <Page title="User" sx={{width:"90vw",backgroundColor:"blue"}}> */}
      <Container id="changer" sx={{
        width: isMobile ? "100vw" : "95vw", marginLeft: "0px", marginRight: "0px"
      }}>
        <Stack direction={isMobile ? "column" : "row"} alignItems="center" justifyContent="space-between" mb={5} style={{ paddingLeft: "0px" }} sx={{ marginBottom:'0px' }}>
          <Container
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: "0px",
            }}
          >
            <div className="backbutton tt-back" style={{ display: "flex" }} >
              <ArrowBackIosIcon onClick={navigateCancel} sx={{
                cursor: "pointer"
              }} />
            </div>
            {/* <h1 style={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}>
              Jobs
            </h1> */}
            <Typography 
              variant="h3" 
              color="textPrimary" 
              sx={{ 
                width: '300px',
                textDecorationLine: 'underline',
                backgroundColor: 'transparent !important'
              }}
            >
              Jobs
            </Typography>
          </Container>
          {/* <Typography variant="h4" gutterBottom backgroundColor="#f9fafb">
            Jobs
          </Typography> */}
          <Button
            style={{ width: isMobile ? "100%" : "9%", height: "46px" }}
            variant="contained"
            component={RouterLink}
            to="/dashboard/jobs/create-job"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Job
          </Button>
        </Stack>
        <Button onClick={toggleSidebar} startIcon={<Iconify icon={sidebarOpen ? 'eva:arrow-ios-back-fill' : 'eva:arrow-ios-forward-fill'} />}>
          {sidebarOpen ? 'Hide Filters' : 'Show Filters'}
        </Button>

        <Tabs value={tabValue} onChange={handleTabChange} aria-label="job tabs">
          <Tab label="Publish Jobs" />
          <Tab label="Draft Jobs" />
        </Tabs>
        
        <Grid container spacing={2} style={{ padding: 0 }} >
        {sidebarOpen && (
          <Grid item xs={12} md={3}>
            <Card sx={{ padding: 'inherit', height: '100%' }}>
              <Stack direction="column" spacing={1} sx={{ maxHeight: '600px', overflow: 'auto', marginRight: '16px' }}>
              <div style={{ overflow: 'hidden', padding: '10px' }}>
                <QueryBuilderAntD style={{ gap: '3px', width: '100%' }}>
                  <QueryBuilder
                    fields={fields}
                    query={query}
                    controlElements={{
                      addGroupAction
                    }}
                    onQueryChange={handleQueryChange}
                    autoSelectField={false}
                    autoSelectOperator={false}
                    resetOnFieldChange={false}
                  />
                </QueryBuilderAntD>
              </div>
                <Stack direction={isMobile ? "column" : "row"} spacing={2} sx={{ justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    onClick={applyFilter}
                    sx={{ width: isMobile ? '100%' : '200px' }}
                    startIcon={<Iconify icon="bi:filter-square-fill" />}
                  >
                    Apply Filter
                  </Button>
                  <Button
                    variant="contained"
                    onClick = {handleClearFilter}
                    sx={{ width: isMobile ? '100%' : '200px' }}
                    startIcon={<Iconify icon="mdi:filter-remove-outline" />}
                  >
                    Clear Filter
                  </Button>
                </Stack>
              </Stack>
              </Card>
          </Grid>
        )}
          <Grid item xs={12} md={sidebarOpen ? 9 : 12}>
            <Card flex={4}>
            <TableContainer>
                {tabValue === 0 ? (
                  <MUIDataTable
                    title={'Publish Job'}
                    data={filterData}
                    columns={columns}
                    options={{
                      ...options,
                      responsive: 'standard',
                      scrollY: '400px',
                      scrollX: true,
                    }}
                    components={{ Toolbar: GridToolbar }}
                  />
                ) : (
                  <MUIDataTable
                    title={'Draft Job List'}
                    data={filterData}
                    columns={draftJobColumns}
                    options={{
                      ...draftOptions,
                      responsive: 'standard',
                      scrollY: '400px',
                      scrollX: true,
                    }}
                    components={{ Toolbar: GridToolbar }}
                  />
                )}
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
        {/* </Stack> */}
      </Container>
      {/* <div>
        <Modal
          open={opens}
          onClose={handleCloses}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Stack>
              <Filters />
            </Stack>
          </Box>
        </Modal>
      </div> */}
      {modelOpen && detailsId && <JobModel open={modelOpen} handleClose={handleClose} />}
      {/* </Page> */}
    </>
  );
};

export default Jobs;
