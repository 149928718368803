import { isRejectedWithValue } from '@reduxjs/toolkit';
import { authAction } from "../auth/AuthReducer"

/**
 * Log a warning and show a toast!
 */
const removeAllCookies = () => {
    document.cookie.split(";").forEach((cookie) => {
      const name = cookie.split("=")[0].trim();
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
};

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
        if (action.payload.status === 401) {
            localStorage.clear()
            sessionStorage.clear()
            window.location.reload()
            removeAllCookies()
            api.dispatch(authAction(false));
        }
    }

    return next(action)
}