import React, { useState, useEffect, useMemo } from 'react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MUIDataTable from 'mui-datatables';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  ListItemIcon,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContent,
  Box,
  TableContainer,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { QueryBuilderAntD } from '@react-querybuilder/antd';
import { QueryBuilder, formatQuery } from 'react-querybuilder';
// components
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import { sortedDataFn } from '../../../utils/getSortedData';
import { showToast } from '../../../utils/toast';

// mock

import {
  useGetInterviewListAllQuery,
  useDeleteInterviewMutation,
  useApplyInterviewFilterMutation,
} from '../../../redux/services/interview/InterviewServices';
import OperatorMappings from '../OperatorMappings';

export const InterviewTypeOptions = [
  'IP',
  'PC',
  'VC',
].map(s => ({ name: s, label: s }))

const initialQuery = { 
  rules: [
    { field: 'title', operator: 'contains', value: '' },
  ] ,
  combinator: 'and', 
  };

const fields = [
  { name: 'title', label: 'Interview Name', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'date', label: 'Date', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'date', operators: OperatorMappings().date },
  { name: 'time_start', label: 'Time Start', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().time },
  { name: 'time_end', label: 'Time End', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().time },
  { name: 'job', label: 'Job Title', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'candidate', label: 'Candidate Name', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'company', label: 'Company Name', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'location', label: 'Location', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'email_temp', label: 'Email Template', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'email_sub', label: 'Email Subject', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  {
    name: 'type',
    label: 'Interview Type',
    valueEditorType: 'multiselect',
    values: InterviewTypeOptions,
    defaultValue: 'Cowbell',
    valueSources: ['value'],
    groupNumber: 1,
    comparator: '=',
    operators: OperatorMappings().object,
  },
]


const addGroupAction = (props) => {
  return null;
};

const Interviews = () => {
  const navigate = useNavigate();
  const { data, refetch } = useGetInterviewListAllQuery();
  const [dataList, setDataList] = useState([])
  useEffect(() => {
    if (data?.list) {
      setDataList(data.list);
    }
  }, [data]);
  // const [modelOpen, setModelOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [deleteJobId, setDeleteJobID] = useState()
  const [deleteInterview, deleteInterviewInfo] = useDeleteInterviewMutation();
  const [applyInterviewFilter] = useApplyInterviewFilterMutation();
  const [active, setActive] = React.useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [query, setQuery] = useState(initialQuery);
  const [interviewColumnVisibility, setInterviewColumnVisibility] = useState(() => {
    const savedVisibility = localStorage.getItem('columnVisibilityInterview');
    return savedVisibility ? JSON.parse(savedVisibility) : {
      view: true,
      id: false,
      title: true,
      date: true,
      time_start: true,
      time_end: true,
      job: true,
      candidate: true,
      updated: true,
      type: true,
      company: true,
      location: true,
      email_temp: true,
      email_sub: true,
      action: true,
    };
  });

  const handleClearFilter = () => {
    setQuery(initialQuery);
    setDataList(data?.list); 
    refetch(); 
  };

  const handleQueryChange = (newQuery) => {
    setQuery(newQuery);
  };

  const applyFilter = async () => {
    let formattedQuery = formatQuery(query, 'sql');
  
    // Replace 'and' and 'or' with their uppercase equivalents
    formattedQuery = formattedQuery.replace(/\band\b/g, 'AND');
    formattedQuery = formattedQuery.replace(/\bor\b/g, 'OR');
  
    // Replace specific operators with their required formats
    formattedQuery = formattedQuery.replace(/\bcontains\b/g, 'LIKE');
    formattedQuery = formattedQuery.replace(/\bnotContains\b/g, 'NOT LIKE');
    formattedQuery = formattedQuery.replace(/\bequals\b/g, '=');
    formattedQuery = formattedQuery.replace(/\bnotEqual\b/g, '<>');
    formattedQuery = formattedQuery.replace(/\bstartsWith\b/g, 'StartWith');
    formattedQuery = formattedQuery.replace(/\bendWith\b/g, 'EndWith');
    formattedQuery = formattedQuery.replace(/\bblank\b/g, 'IS NULL');
    formattedQuery = formattedQuery.replace(/\bnotBlank\b/g, 'IS NOT NULL');
    formattedQuery = formattedQuery.replace(/\bgreaterThan\b/g, '>');
    formattedQuery = formattedQuery.replace(/\bgreaterThanOrEqual\b/g, '>=');
    formattedQuery = formattedQuery.replace(/\blessThan\b/g, '<');
    formattedQuery = formattedQuery.replace(/\blessThanOrEqual\b/g, '<=');
    formattedQuery = formattedQuery.replace(/\btrue\b/g, 'TRUE');
    formattedQuery = formattedQuery.replace(/\bfalse\b/g, 'FALSE');
  
    // Add percentage signs for LIKE queries
    formattedQuery = formattedQuery.replace(/LIKE '([^']*)'/g, "LIKE '%$1%'");
    formattedQuery = formattedQuery.replace(/NOT LIKE '([^']*)'/g, "NOT LIKE '%$1%'");
    formattedQuery = formattedQuery.replace(/LIKE '%([^']*)'/g, "LIKE '%$1'"); // For endsWith
    formattedQuery = formattedQuery.replace(/LIKE '([^']*)%'/g, "LIKE '$1%'"); // For startsWith
  
    try {
      const response = await applyInterviewFilter({ query: formattedQuery });
      setDataList(response?.data?.result || []);
    } catch (error) {
      console.error("Error applying filter:", error);
    }
  };

  const handleeClosee = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setActive(false);
  };

  const sortData = useMemo(() => {
    const sortresult = sortedDataFn(data?.list);
    return sortresult;
  }, [data]);
  const navigateCancel = () => {
    // navigate('/dashboard/app');
    navigate(-1);
  };
  
  const onDeletAssesmenteHandler = async (dataIndex) => {
    setActive(true);
    setOpen(false);
    setCurrentIndex(dataIndex);
    await deleteInterview(dataIndex);
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (deleteInterviewInfo.isSuccess) {
      showToast('success', deleteInterviewInfo.data.msg);
      deleteInterviewInfo.reset();
      refetch();
    }
    if (deleteInterviewInfo.isError) {
      showToast('error', deleteInterviewInfo.error.data.msg);
      deleteInterviewInfo.reset();
      refetch();
    }
  }, [deleteInterviewInfo, refetch]);

  // const onJobViewModel = () => {
  //   setModelOpen(true);
  // };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (id) => {
    setDeleteJobID(id)
    setOpen(true);
  };

  const handleClosee = () => {
    setOpen(false);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const columns = [
    {
      name: 'view',
      label: 'View',
      options: {
        display: interviewColumnVisibility.view,
        filter: true,
        sort: false,
        setCellProps: () => ({ 'data-label': 'View' }),
        customBodyRenderLite: (dataIndex) => (
          <>
            <Button
              style={{ minWidth: 0, marginRight: '5px' }}
              onClick={() => {
                // navigate(`/dashboard/candidate/perticularCandidate/${data?.list[dataIndex]?.id}`);
                navigate(`/dashboard/interviews/perticularInterview/${dataList[dataIndex]?.id}`);
              }}
              color="info"
            >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="carbon:view-filled" width={24} height={24} sx={{ color: '#C2CFE0' }} />
              </ListItemIcon>
            </Button>
          </>
        ),
      },
    },
    {
      name: 'id',
      label: 'Interview Id',
      options: {
        filter: true,
        sort: true,
        display: interviewColumnVisibility.id,
        setCellProps: () => ({ 'data-label': 'Interview Id' }),
      },
    },
    {
      name: 'title',
      label: 'Interview Name',
      options: {
        display: interviewColumnVisibility.title,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Interview Name' }),
        customBodyRender: (value, tableMeta) => {
          const dataIndex = tableMeta.rowIndex;  
          const interviewId = tableMeta.currentTableData[dataIndex]?.data[1];; 
          return (
            <Button
              onClick={() => navigate(`/dashboard/interviews/perticularInterview/${interviewId}`)}
              sx={{
                color: 'primary',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {value}
            </Button>
          );
        },
      },
    },
    {
      name: 'date',
      label: 'Date',
      options: {
        display: interviewColumnVisibility.date,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Date' }),
      },
    },
    {
      name: 'time_start',
      label: 'Time Start',
      options: {
        display: interviewColumnVisibility.time_start,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Time Start' }),
      },
    },
    {
      name: 'time_end',
      label: 'Time End',
      options: {
        display: interviewColumnVisibility.time_end,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Time End' }),
      },
    },
    {
      name: 'job',
      label: 'Job Title',
      options: {
        filter: true,
        sort: true,
        display: interviewColumnVisibility.job,
        setCellProps: () => ({ 'data-label': 'Job Title' }),
        customBodyRender: (value, metaData) => {
          let job = value?.id || '';
          if (job === '' && dataList[metaData.rowIndex]?.job__id) {
            job = dataList[metaData.rowIndex].job__id;
          }
          let name = value?.title || '';
          if (name === '' && dataList[metaData.rowIndex]?.job__title) {
            name = dataList[metaData.rowIndex].job__title;
          }

          return (
            <Button
              onClick={() => navigate(`/dashboard/candidate/jobDetails/${job}`)}
              sx={{
                color: 'primary',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {name}
            </Button>
          );
        },
      },
    },
    {
      name: 'candidate',
      label: 'Candidate Name',
      options: {
        filter: true,
        sort: true,
        display: interviewColumnVisibility.candidate,
        setCellProps: () => ({ 'data-label': 'Candidate Name' }),
        customBodyRender: (value, metaData) => {
          let candidate = value?.id || '';
          if (candidate === '' && dataList[metaData.rowIndex]?.candidate__id) {
            candidate = dataList[metaData.rowIndex].candidate__id;
          }
          let name = value?.first_name || '';
          if (name === '' && dataList[metaData.rowIndex]?.candidate__first_name) {
            name = dataList[metaData.rowIndex].candidate__first_name;
          }
          return (
            <Button
              onClick={() => navigate(`/dashboard/candidate/perticularCandidate/${candidate}`)}
              sx={{
                color: 'primary',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              {name}
            </Button>
          );
        },
      },
    },
    {
      name: 'updated',
      label: 'Modified date and time',
      options: {
        display: interviewColumnVisibility.updated,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Modified date and time' }),
        customBodyRender: (value) => {
          if (value) {
            const date = new Date(value);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const formattedDate = `${day}/${month}/${year} ${date.toLocaleTimeString()}`;
            return <div>{formattedDate}</div>;
          }
          return <div>N/A</div>;
        },
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        display: interviewColumnVisibility.type,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Type' }),
      },
    },
    {
      name: 'company',
      label: 'Company',
      options: {
        display: interviewColumnVisibility.company,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Company' }),
        customBodyRender: (value, metaData) => {
          const company = value && value.name ? value.name : dataList[metaData.rowIndex]?.company__name || 'N/A';
          return company;
        },
      },
    },
    {
      name: 'location',
      label: 'Location',
      options: {
        display: interviewColumnVisibility.location,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Location' }),
        customBodyRender: (value, metaData) => {
          const location = value && value.name ? value.name : dataList[metaData.rowIndex]?.location__name || 'N/A';
          return location;
        },
      },
    },
    {
      name: 'email_temp',
      label: 'Email Template',
      options: {
        display: interviewColumnVisibility.email_temp,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Email Template' }),
        customBodyRender: (value, metaData) => {
          const email = value && value.name ? value.name : dataList[metaData.rowIndex]?.email_temp__name || 'N/A';
          return email;
        },
      },
    },
    {
      name: 'email_sub',
      label: 'Email Subject',
      options: {
        display: interviewColumnVisibility.email_sub,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Email Subject' }),
      },
    },
    {
      name: 'action',
      label: 'Action',
      options: {
        display: interviewColumnVisibility.action,
        filter: true,
        sort: false,
        setCellProps: () => ({ 'data-label': 'Action' }),
        customBodyRenderLite: (dataIndex) => {
          const interview = dataIndex;
          return (
          <>
           
            <div>
              <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClosee}
                aria-labelledby="responsive-dialog-title"
                sx={{ '& .MuiDialog-paper': { height: '200px' } }}
              >
                <DialogTitle variant="h4">{'Delete'}</DialogTitle>
                <DialogContent>
                  <DialogContentText>Are you sure you wan&#39;t to delete this interview?</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleClosee}>
                    Disagree
                  </Button>
                  <Button
                    onClick={() => onDeletAssesmenteHandler(deleteJobId)}
                    loading={dataIndex === currentIndex ? useDeleteInterviewMutation.isLoading : false}
                    autoFocus
                  >
                    Agree
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <Button style={{ minWidth: 0 }} component={RouterLink} to={`/dashboard/interviews/edit-interview/${dataList[dataIndex]?.id}`}>
            {/* <Button style={{ minWidth: 0 }} component={RouterLink} to={`/dashboard/interviews/edit-interview/`}> */}
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="ep:edit" width={24} height={24} color={'blue'} />
              </ListItemIcon>
            </Button>

            <Button
              style={{ minWidth: 0, margin: '0px 5px' }}
              // variant="contained"
              color="error"
              onClick={(e) => {e.preventDefault(); handleClickOpen(dataList[dataIndex]?.id)}}
              loading={dataIndex === currentIndex ? useDeleteInterviewMutation.isLoading : false}
            >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="eva:trash-2-outline" width={24} height={24} color={'red'} />
              </ListItemIcon>
            </Button>
          </>
          )
        }
      },
    },
  ];

  const labelStatus = (
    <Label variant="ghost" color={'success'}>
      {sentenceCase('active')}
    </Label>
  );
  const editAndDeleteButton = (
    <>
      <Button component={RouterLink} to="/dashboard/interviews/edit-interview">
        <ListItemIcon style={{ justifyContent: 'center' }}>
          <Iconify icon="eva:edit-fill" width={24} height={24} />
        </ListItemIcon>
      </Button>
      <Button>
        <ListItemIcon style={{ justifyContent: 'center' }}>
          <Iconify icon="eva:trash-2-outline" width={24} height={24} />
        </ListItemIcon>
      </Button>
    </>
  );
  // const data = [
  //   { name: 'Joe James', status: labelStatus, action: editAndDeleteButton },
  //   { name: 'John Walsh', status: labelStatus, action: editAndDeleteButton },
  //   { name: 'Bob Herm', status: labelStatus, action: editAndDeleteButton },
  //   { name: 'James Houston', status: labelStatus, action: editAndDeleteButton },
  // ];

  useEffect(() => {
    localStorage.setItem('columnVisibilityInterview', JSON.stringify(interviewColumnVisibility));
  }, [interviewColumnVisibility]);

  const options = {
    filterType: 'dropdown',
    responsive: 'standard', // or 'vertical', 'simple'
    filter: true,
    download: true,
    print: true,
    tableBodyHeight: '400px', // Adjust as needed
    tableBodyMaxHeight: '600px', // Adjust as needed
    selectableRows: 'multiple', // Disable row selection if not needed
    rowsPerPage: 10, // Adjust as needed
    rowsPerPageOptions: [10, 20, 30], // Adjust as needed
    customToolbarSelect: () => {}, // Customize toolbar if needed
    viewColumns: true,
    onColumnViewChange: (changedColumn, action) => {
      setInterviewColumnVisibility((prevVisibility) => ({
        ...prevVisibility,
        [changedColumn]: action === 'add',
      }));
    },
  };

  const getInputValue = (value) => {};

  const [value, setValue] = React.useState(null);

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  useEffect(() => {
    const element = document.getElementById("changer");
    const parentElement = element?.parentElement;
    parentElement.style.paddingTop = "90px"
  },[])

  return (
    <Page id="changer" title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0}>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div className="backbutton tt-back" style={{ display: 'flex' }}>
              <ArrowBackIosIcon
                onClick={navigateCancel}
                sx={{
                  cursor: 'pointer',
                }}
              />
            </div>
            {/* <h1 style={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}>Interviews</h1> */}
            <Typography 
              variant="h3" 
              color="textPrimary" 
              sx={{ 
                width: '300px',
                textDecorationLine: 'underline',
                backgroundColor: 'transparent !important'
              }}
            >
              Interviews
            </Typography>
          </Container>
          <Button
            style={{ width: '14%', height: '46px' }}
            variant="contained"
            component={RouterLink}
            to="/dashboard/NewInterview"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Create Interview
          </Button>
        </Stack>
        <Button onClick={toggleSidebar} startIcon={<Iconify icon={sidebarOpen ? 'eva:arrow-ios-back-fill' : 'eva:arrow-ios-forward-fill'} />}>
          {sidebarOpen ? 'Hide Filters' : 'Show Filters'}
        </Button>
        <Grid container spacing={2} style={{ padding: 0 }}>
        {sidebarOpen && (
          <Grid item xs={12} md={3}>
            <Card sx={{ padding: 'inherit', height: '100%' }}>
              <Stack direction="column" spacing={2} sx={{ maxHeight:'600px', overflow:'auto',  marginRight: '16px' }}>
                <QueryBuilderAntD>
                  <QueryBuilder
                    fields={fields}
                    query={query}
                    controlElements={{
                      addGroupAction
                    }}
                    onQueryChange={handleQueryChange}
                    autoSelectField={false}
                    autoSelectOperator={false}
                    resetOnFieldChange={false} />
                </QueryBuilderAntD>
                <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    sx={{ width: '200px'}}
                    onClick={applyFilter}
                    startIcon={<Iconify icon="bi:filter-square-fill" />}
                  >
                    Apply Filter
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleClearFilter}
                    sx={{ width: '200px'}}
                    startIcon={<Iconify icon="mdi:filter-remove-outline" />}
                  >
                    Clear Filter
                  </Button>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        )}
          <Grid item xs={12} md={sidebarOpen ? 9 : 12}>
            <Card flex={4}>
              <TableContainer>
                <MUIDataTable title={'Interview List'} data={dataList} columns={columns} 
                options={{
                  ...options,
                  responsive: 'standard',
                  scrollY: '400px',
                  scrollX: true,
                }}
                />
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Interviews;
