import React, { useState, forwardRef } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Typography, TextField, Select } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Iconify from '../Iconify';



const AssignCandidateJobModel = (props) => {
    // const { open, handleClose, candidateListData, handleChange, value, handleSubmit, name } = props;
    const { open, handleClose, candidates, handleChange, value, handleSubmit, name } = props;

    // console.log(candidates)
    return (
        <>
            <Dialog open={open} onClose={handleClose} >
                <DialogTitle>Assign a candidate to a job</DialogTitle>
                <DialogContent style={{ width: "600px", padding: "0 2rem", paddingTop: "2rem", paddingBottom: "6rem" }}>
                    <TextField 
                        id="outlined-basic" 
                        label="Type a Job title" 
                        variant="outlined" 
                        fullWidth 
                        placeholder='Select a Job title...'
                        select
                        SelectProps={{
                            native: true
                        }}
                        value={value}
                        onChange={handleChange}
                    >
                        <option value={-1}>Select a {name}</option>
                        {(Array.isArray(candidates?.list) ? candidates.list : []).map(e => (
                            <option value={e.id} key={e.id}>
                                {e.first_name} {e.last_name}
                            </option>
                        ))} 
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {handleSubmit(); handleClose()}} variant="outlined">Add</Button>
                    <Button onClick={handleClose} variant="outlined">Close</Button>
                    {/* <Button onClick={() => {handleClose()}} variant="outlined">Add</Button> */}
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AssignCandidateJobModel