import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, Radio, FormControlLabel, Checkbox, Stack, useMediaQuery } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { Container } from '@mui/system';
import { useTheme } from '@mui/material/styles';

const AssesmentModal = (props) => {
    const { open, handleClose, data } = props;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const calculateTotalMarks = (question) => {
        let totalMarks = 0;
        const arr1 = question?.answers;
        const arr2 = question?.candidateAnswer
        arr2?.forEach((element, index) => {
            if (index !== 0) {
                totalMarks += arr1[parseInt(element - 1, 10)]
            }
        });
        return totalMarks
    }

    return (
       
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>Assesment Response</DialogTitle>
                <DialogContent
                    sx={{
                        maxHeight: '70vh',
                        overflowY: "auto",
                        padding: isSmallScreen ? "0.5rem" : "1rem",
                        paddingTop: isSmallScreen ? "1rem" : "2rem",
                        paddingBottom: isSmallScreen ? "3rem" : "6rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: isSmallScreen ? "1rem" : "2rem",
                    }}
                >
                     <Stack sx={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "100%",
                    }}>
                    {data?.[0]?.form?.length > 0 ? (
                        data[0].form.map((e, i) => (
                            <Container key={i} sx={{ width: "100%", padding: "1rem", borderBottom: "1px solid #ccc" }}>
                                <Container sx={{ color: `${e?.candidateAnswer ? "green" : "red"}`, marginBottom: "0.5rem" }}>
                                    <strong>{i + 1}) Q. {e.question}</strong>
                                </Container>
                                <Container sx={{ marginBottom: "0.5rem" }}>
                                    Marks Obtained: <strong>{e.type === "C" ? calculateTotalMarks(e) : e?.answers[e?.candidateAnswer - 1] || e?.candidateAnswer}</strong> 
                                    <span style={{ color: (() => {
                                        const marksObtained = e.type === "C" ? calculateTotalMarks(e) : e?.answers[e?.candidateAnswer - 1];
                                        return marksObtained >= e?.marks ? "green" : "red";
                                    })() }}>
                                        {(() => {
                                            const marksObtained = e.type === "C" ? calculateTotalMarks(e) : e?.answers[e?.candidateAnswer - 1];
                                            return marksObtained >= e?.marks ? " - Qualified" : " - Unqualified";
                                        })()}
                                    </span>
                                </Container>
                                <Container
                                    sx={{
                                        display: "flex",
                                        gap: "1rem",
                                        flexWrap: "wrap",
                                        justifyContent: "center",
                                    }}
                                >
                                    {e?.options?.map((option, index) => (
                                        <FormControlLabel
                                            control={e.type === "C" ? <Checkbox disabled /> : <Radio disabled />}
                                            label={option}
                                            checked={e.type === "C" ? e?.candidateAnswer?.map(e => +e - 1).includes(index) : index + 1 === parseInt(e?.candidateAnswer, 10)}
                                            key={index}
                                        />
                                    ))}
                                </Container>
                            </Container>
                        ))
                    ) : (
                        <Container sx={{ textAlign: "center", color: "red", marginTop: "10px" }}>
                            Assessment data not available
                        </Container>
                    )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">Close</Button>
                    {/* <Button onClick={() => {handleSubmit(); handleClose()}} variant="outlined">Add</Button> */}
                </DialogActions>
            </Dialog>
    )
}

export default AssesmentModal
