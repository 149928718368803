import * as Yup from 'yup';
import React, { forwardRef, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import { Stack, Button, TextField, Container, CircularProgress, ListItem, Grid, FormControl, InputLabel, Select, MenuItem, ListItemIcon, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText, Divider, FormControlLabel, Checkbox, Slide, IconButton, Collapse, Box, RadioGroup, Radio, InputAdornment, Tooltip, Typography, FormLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from '@mui/x-data-grid';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AutoSizer } from 'react-virtualized';
import { useGetUsersApiQuery } from '../../redux/services/settings/UserService';
import {
    useGetTaskOptionsListQuery,
    useAddTasksMutation,
    useGetTaskListQuery,
    useUpdateTasksMutation,
    useDeleteTasksMutation,
    useCompleteTasksMutation,
    useGetEventOptionsListQuery,
    useAddEventsMutation,
    useGetEventListQuery,
    useUpdateEventsMutation,
    useDeleteEventsMutation,
    useGetCallOptionsListQuery,
    useAddCallsMutation,
    useGetCallListQuery,
    useUpdateCallsMutation,
    useDeleteCallsMutation
} from '../../redux/services/notes/NotesServices';
import { showToast } from '../../utils/toast';
import ToDoList from './ToDoList';
import {useGetCandidateListQuery} from '../../redux/services/candidate/CandidateServices';
import { useGetInterviewerDetailsQuery } from '../../redux/services/settings/interviewContact';
import { useGetJobListQuery } from '../../redux/services/jobs/JobListService';

const useStyles = makeStyles({
    oddRow: {
      backgroundColor: '#f9f9f9',
    },
    evenRow: {
      backgroundColor: '#ffffff',
    },
  });

function AddForm({ candidateId }) {
    const classes = useStyles();
    const { data: candidateTaskOptionsData, refetch: candidateTaskOptionsDataInfo } = useGetTaskOptionsListQuery();
    const { data: candidateEventOptionsData, refetch: candidateEventOptionsDataInfo } = useGetEventOptionsListQuery();
    const { data: candidateCallOptionsData, refetch: candidateCallOptionsDataInfo } = useGetCallOptionsListQuery();
    const [addTasks, addTasksInfo] = useAddTasksMutation();
    const [addEvents, addEventsInfo] = useAddEventsMutation();
    const [addCalls, addCallsInfo] = useAddCallsMutation();
    const { data: taskListData, refetch } = useGetTaskListQuery(candidateId);
    const { data: eventListData, refetch : eventListDataInfo } = useGetEventListQuery(candidateId);
    const { data: callListData, refetch : callListDataInfo } = useGetCallListQuery(candidateId);
    const [updateTasks, updateTasksInfo] = useUpdateTasksMutation();
    const [updateEvents, updateEventsInfo] = useUpdateEventsMutation();
    const [updateCalls, updateCallsInfo] = useUpdateCallsMutation();
    const [deleteTasks, deleteTasksinfo] = useDeleteTasksMutation();
    const [deleteEvents, deleteEventsinfo] = useDeleteEventsMutation();
    const [deleteCalls, deleteCallsinfo] = useDeleteCallsMutation();
    const [completeTask] = useCompleteTasksMutation();
    const { data: candidateListData, refetch: candidateListDataInfo } = useGetCandidateListQuery();
    const { data: getownerData, refetch: refetchOwner } = useGetUsersApiQuery();
    const { data: contactsData, refetch: contactsDataInfo } = useGetInterviewerDetailsQuery()
    const { data: jobData, refetch: jobDataRefetch } = useGetJobListQuery();

    // const combinedData = [
    //     ...(taskListData || []).map(task => ({ ...task, type: 'TASKS' })),
    //     ...(eventListData || []).map(event => ({ ...event, type: 'EVENTS' })),
    //     // ...(callListData || []).map(call => ({ ...call, type: 'CALLS' })),
    // ];

    const combinedData = [
        ...(taskListData || []).map(task => ({
            ...task,
            type: 'TASKS',
            displaySubject: task.subject // Use subject if available, otherwise event_name
        })),
        ...(eventListData || []).map(event => ({
            ...event,
            type: 'EVENTS',
            displaySubject: event.event_name // Use subject if available, otherwise event_name
        })),
        ...(callListData || []).map(call => ({
            ...call,
            type: 'CALLS',
            displaySubject: call.subject
        })),
    ];
    
    const [input, setInput] = useState("");
    const [selectedCandidate, setSelectedCandidate] = useState("");
    const [openTaskDialog, setOpenTaskDialog] = useState(false);
    const [openEventDialog, setOpenEventDialog] = useState(false);
    const [openCallDialog, setOpenCallDialog] = useState(false);
    const [callMoreFieldsOpen, setCallMoreFieldsOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editingTaskId, setEditingTaskId] = useState(null);
    const [editingTaskText, setEditingTaskText] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState({ id: null, todoType: null });
    const [reminderOpen, setReminderOpen] = useState(false);
    const [repeatOpen, setRepeatOpen] = useState(false);
    const [moreFieldsOpen, setMoreFieldsOpen] = useState(false);
    const [dueDate, setDueDate] = useState('');
    const [showMoreDetails, setShowMoreDetails] = useState(false);
    const [subject, setSubject] = useState('');
    const [priority, setPriority] = useState('');
    const [owner, setOwner] = useState('');
    const [reminder, setReminder] = useState('');
    const [reminderTime, setReminderTime] = useState('');
    const [repeat, setRepeat] = useState('');
    const [alert, setAlert] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [repeatType, setRepeatType] = useState('');
    const [contactName, setContactName] = useState('');
    const [relatedTo, setRelatedTo] = useState('');
    const [status, setStatus] = useState('');
    const [currency, setCurrency] = useState('');
    const [emailNotification, setEmailNotification] = useState(false);
    const [exchangeRate, setExchangeRate] = useState('');
    const [description, setDescription] = useState('');

    const [editingEventId, setEditingEventId] = useState(null);
    const [eventName, setEventName] = useState('');
    const [meetingVenue, setMeetingVenue] = useState('');
    const [location, setLocation] = useState('');
    const [allDay, setAllDay] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [host, setHost] = useState('');
    // const [relatedTo, setRelatedTo] = useState('');
    const [candidate, setCandidate] = useState('');
    const [participants, setParticipants] = useState([]);
    // const [currency, setCurrency] = useState('');
    // const [description, setDescription] = useState('');
    const [repeatEvent, setRepeatEvent] = useState('');
    // const [reminder, setReminder] = useState('');

    const [timer, setTimer] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [selectedCallType, setSelectedCallType] = useState('CURRENTCALL');

    
    const [editingCallId, setEditingCallId] = useState(null);
    const [callSubject, setCallSubject] = useState('');
    const [callType, setCallType] = useState('');
    const [callPurpose, setCallPurpose] = useState('');
    const [postingTitle, setPostingTitle] = useState(null);
    const [contactType, setContactType] = useState('contact');
    const [callResult, setCallResult] = useState('');
    const [callStartTime, setCallStartTime] = useState('');
    const [callDuration, setCallDuration] = useState(null);
    const [billable, setbillable] = useState(false);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const [selectedCandidateId, setSelectedCandidateId] = useState(null);
    const [selectedJobId, setSelectedJobId] = useState(null);

    const handleCallTypeChange = (event) => {
        setSelectedCallType(event.target.value);
    };

    useEffect(() => {
        let interval;
        if (isRunning) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer + 1);
            }, 1000);
        } else if (!isRunning && timer !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isRunning, timer]);

    const handleStartStop = () => {
        setIsRunning(!isRunning);
    };

    const handleReset = () => {
        setTimer(0);
        setIsRunning(false);
    };

    useEffect(() => {
        refetch();
        candidateTaskOptionsDataInfo();
        candidateEventOptionsDataInfo();
        candidateCallOptionsDataInfo();
        candidateListDataInfo();
        refetchOwner();
        contactsDataInfo();
        jobDataRefetch();
    }, []);

    useEffect(() => {
        eventListDataInfo();
        callListDataInfo()
    },[candidateId])

    const handleClickOpen = () => {
        setIsEditMode(false);
        setInput('');
        setSelectedCandidate('');
        setOpenTaskDialog(true);
    };

    const handleClose = () => {
        setOpenTaskDialog(false);
        setEditingTaskId(null);
        setEditingTaskText('');
        setSelectedCandidate('');
        closeTaskEditHandler();
    };
    
    const formatTimeAsHHMMSS = (time) => {
        if (typeof time !== 'string') {
            // console.warn('Expected a string input for time, received:', time);
            return '00:00:00'; // Default to zero duration if input is invalid
        }
        const [hours, minutes, seconds] = time.split(':').map(Number);
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds || 0).padStart(2, '0')}`;
    };

    const formatTimeCallDuration = (time) => {
        if (typeof time !== 'string') {
            // console.warn('Expected a string input for time, received:', time);
            return '00:00:00'; // Default to zero duration if input is invalid
        }
        
        const parts = time.split(':').map(Number);
        const hours = parts[0] || 0;
        const minutes = parts[1] || 0;
        const seconds = parts[2] || 0;
    
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const onTaskFormSubmit = async (event) => {
        event.preventDefault();

        if (!subject.trim()) {
            showToast('error', 'Subject is required');
            return; // Prevent form submission
        }
        
        const taskData = {
            candidate: candidateId || selectedCandidate,
            subject,
            // duedate: dueDate ? new Date(dueDate).toISOString().split('T')[0] : '', // Format date
            priority: priority?.toUpperCase() || '', // Use optional chaining
            ...(owner ? { owner } : {}),
            // when_date: reminder ? new Date(reminder).toISOString().split('T')[0] : '', // Format date
            when_time: reminderTime || '00:00:00',
            task_repeat: repeat?.toUpperCase() || '', // Use optional chaining
            task_alert: alert?.toUpperCase() || '', // Use optional chaining
            // start_date: startDate ? new Date(startDate).toISOString().split('T')[0] : '', // Format date
            // end_date: endDate ? new Date(endDate).toISOString().split('T')[0] : '', // Format date
            repeat_type: repeatType?.toUpperCase() || '', // Use optional chaining
            contact_name: contactName,
            related_to: relatedTo,
            status: status?.toUpperCase() || '', // Use optional chaining
            currency: currency?.toUpperCase() || '', // Use optional chaining
            email_notification: emailNotification,
            exchange_rate: exchangeRate,
            description,
        };

        if (dueDate) taskData.duedate = new Date(dueDate).toISOString().split('T')[0];
        if (startDate) taskData.start_date = new Date(startDate).toISOString().split('T')[0];
        if (endDate) taskData.end_date = new Date(endDate).toISOString().split('T')[0];
        if (reminder) taskData.when_date = new Date(reminder).toISOString().split('T')[0];

        const result = await addTasks(taskData);
        if (result.error) {
            showToast('error', result.error.data.msg || 'Failed to add task');
        } else {
            showToast('success', 'Task added successfully');
            refetch();
            closeTaskEditHandler();
            handleClose();
        }
    };

    const onEventFormSubmit = async (event) => {
        event.preventDefault();

        if (!eventName.trim()) {
            showToast('error', 'Event name is required');
            return; // Prevent form submission
        }

        const eventData = {
            event_name: eventName,
            meeting_venue: meetingVenue.toUpperCase(),
            location,
            all_day: allDay,
            // from_date: fromDate,
            // to_date: toDate,
            // host,
            ...(host ? { host } : {}),
            related_to: relatedTo?.toUpperCase() || '',
            // candidate,
            candidate : candidate || candidateId,
            participants,
            currency : currency?.toUpperCase() || '',
            description,
            repeat : repeatEvent?.toUpperCase() || '',
            reminders : reminder?.toUpperCase() || '',
        };

        if (fromDate) eventData.from_date = new Date(fromDate).toISOString().split('T')[0];
        if (toDate) eventData.to_date = new Date(toDate).toISOString().split('T')[0];

        const result = await addEvents(eventData);
        if (result.error) {
            showToast('error', result.error.data.msg || 'Failed to add event');
        } else {
            showToast('success', 'Event added successfully');
            eventListDataInfo(); 
            closeEventEditHandler();
            handleCloseEventDialog();
        }
    };

    const onCallFormSubmit = async (event) => {
        event.preventDefault();

        if (!callSubject.trim()) {
            showToast('error', 'Call subject is required');
            return; // Prevent form submission
        }

        const callData = {
            subject: callSubject,
            call_type: callType || '',
            call_purpose: callPurpose || '',
            contact_name_contact: contactType === 'contact' ? selectedContactId : null,
            contact_name_candidate : contactType === 'candidate' ? selectedCandidateId : null,
            candidate: candidateId || selectedCandidate,
            related_to: relatedTo || '',
            posting_title: selectedJobId || null,
            call_details: selectedCallType,
            call_timer:  formatTimeAsHHMMSS(timer) || null, 
            call_result: callResult || null,
            call_start_time: callStartTime || null,
            call_duration: formatTimeCallDuration(callDuration) || null,
            description : description || null,
            billable : billable || false,
            owner : owner || null,
            reminders : reminder || null,
        };
        const result = await addCalls(callData);
        if (result.error) {
            showToast('error', result.error.data.msg || 'Failed to add call');
        } else {
            showToast('success', 'Call added successfully');
            callListDataInfo();
            handleCloseCallDialog();
        }
    };

    const handleDeleteDialogOpen = (id, todoType) => {
        setItemToDelete({ id, todoType });
        setDeleteDialogOpen(true);
    };
      
    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
        setItemToDelete({ id: null, todoType: null });
    };
      
    const handleDeleteConfirm = async () => {
        const { id, todoType } = itemToDelete;
        if (id !== null && todoType !== null) {
            let result;
            if (todoType === 'TASKS') {
                result = await deleteTasks(id);
            } else if (todoType === 'EVENTS') {
                result = await deleteEvents(id);
            } else if (todoType === 'CALLS') {
                result = await deleteCalls(id);
            }
        
            if (result.error) {
                showToast('error', result.error.data.msg || 'Failed to delete');
            } else {
                showToast('success', 'Deleted successfully');
            }
            refetch();
            eventListDataInfo();
            callListDataInfo();
            handleDeleteDialogClose();
        }
    };

    const onEditHandler = (item) => {
        
        if (item.type === 'TASKS') {
            setIsEditMode(true);
            setEditingTaskId(item.id);
            setSubject(item.subject); 
            setDueDate(item.duedate); 
            setPriority(item.priority.toLowerCase());
            setOwner(item.owner); 
            setReminder(item.when_date); 
            setReminderTime(item.when_time); 
            setRepeat(item.task_repeat.toLowerCase()); 
            setAlert(item.task_alert.toLowerCase()); 
            setStartDate(item.start_date); 
            setEndDate(item.end_date); 
            setRepeatType(item.repeat_type.toLowerCase()); 
            setContactName(item.contact_name); 
            setRelatedTo(item.related_to); 
            setStatus(item.status.toLowerCase()); 
            setCurrency(item.currency.toLowerCase()); 
            setEmailNotification(item.email_notification); 
            setExchangeRate(item.exchange_rate); 
            setDescription(item.description); 
            setOpenTaskDialog(true);

        } else if (item.type === 'EVENTS') {
            const formatDateForInput = (dateString) => {
                const date = new Date(dateString);
                return date.toISOString().slice(0, 16); // 'YYYY-MM-DDTHH:MM'
            };
            setIsEditMode(true);
            setEditingEventId(item.id)
            setEventName(item.event_name);
            setMeetingVenue(item.meeting_venue.toLowerCase());
            setLocation(item.location);
            setAllDay(item.all_day);
            setFromDate(formatDateForInput(item.from_date));
            setToDate(formatDateForInput(item.to_date));
            setHost(item.host);
            setRelatedTo(item.related_to.toLowerCase());
            setCandidate(item.candidate);
            setParticipants(item.participants);
            setCurrency(item.currency.toLowerCase());
            setDescription(item.description);
            const repeatValue = Object.entries(candidateEventOptionsData?.repeat_fields || {}).find(
                ([key, value]) => key.toUpperCase() === item.repeat.toUpperCase()
            );    
            setRepeatEvent(repeatValue ? repeatValue[1] : "None"); 
            const reminderValue = Object.entries(candidateEventOptionsData?.reminder_fields || {}).find(
                ([key, value]) => key.toUpperCase() === item.reminders.toUpperCase()
            );    
            setReminder(reminderValue ? reminderValue[1] : "None");
            setOpenEventDialog(true);
        } else if (item.type === 'CALLS') {
            setIsEditMode(true);
            setEditingCallId(item.id);
            setCallSubject(item.subject);
            setCallType(item.call_type);
            setCallPurpose(item.call_purpose);
            setSelectedContactId(item.contact_name_contact);
            setSelectedCandidateId(item.contact_name_candidate);
            setRelatedTo(item.related_to);
            setPostingTitle(item.posting_title);
            setSelectedCallType(item.call_details);
            setTimer(item.call_timer);
            setCallResult(item.call_result);
            setCallStartTime(item.call_start_time);
            setCallDuration(item.call_duration);
            setDescription(item.description);
            setbillable(item.billable);
            setOwner(item.owner);
            setReminder(item.reminders);
            setOpenCallDialog(true);
        }
    };
    
    const closeTaskEditHandler = () => {
        setIsEditMode();
        setEditingTaskId();
        setSubject(); 
        setDueDate(); 
        setPriority();
        setOwner(); 
        setReminder(); // Set the reminder date
        setReminderTime(); // Set the reminder time
        setRepeat(); // Set the repeat
        setAlert(); // Set the alert
        setStartDate(); // Set the start date
        setEndDate(); // Set the end date
        setRepeatType(); // Set the repeat type
        setContactName(); // Set the contact name
        setRelatedTo(); // Set the related to
        setStatus(); // Set the status
        setCurrency(); // Set the currency
        setEmailNotification(); // Set the email notification
        setExchangeRate(); // Set the exchange rate
        setDescription(); 
    };

    const closeEventEditHandler = () => {
        setIsEditMode();
        setEditingEventId();
        setEventName('');
        setMeetingVenue('');
        setLocation('');
        setAllDay(false);
        setFromDate('');
        setToDate('');
        setHost('');
        setRelatedTo('');
        setCandidate('');
        setParticipants([]);
        setCurrency('');
        setDescription('');
        setRepeatEvent(''); // Assuming you have a separate state for event repeat
        setReminder('');
    };

    const closeCallEditHandler = () => {
        setIsEditMode(false);
        setEditingCallId(null);
        setCallSubject('');
        setCallType('');
        setCallPurpose('');
        setSelectedContactId(null);
        setSelectedCandidateId(null);
        setRelatedTo('');
        setPostingTitle(null);
        setSelectedCallType('CURRENTCALL');
        setTimer(0);
        setCallResult('');
        setCallStartTime('');
        setCallDuration('');
        setDescription('');
        setbillable(false);
        setOwner('');
        setReminder('');
    };

    const saveEditHandler = async () => {

        let result;
        let taskData = null;
        let eventData = null;
        let callData = null;

        if (editingTaskId) {
            taskData = {
                candidate: candidateId || selectedCandidate,
                subject, 
                duedate: dueDate,
                priority: priority.toUpperCase(),
                owner,
                when_date: reminder,
                when_time: reminderTime, 
                task_repeat: repeat.toUpperCase(), 
                task_alert: alert.toUpperCase(), 
                start_date: startDate, 
                end_date: endDate, 
                repeat_type: repeatType.toUpperCase(), 
                contact_name: contactName,
                related_to: relatedTo, 
                status: status.toUpperCase(), 
                currency: currency.toUpperCase(),
                email_notification: emailNotification, 
                exchange_rate: exchangeRate, 
                description: editingTaskText,
                todo_type: 'TASKS'
            };
        } else if (editingEventId) {
            eventData = {
                event_name : eventName,
                meeting_venue : meetingVenue.toUpperCase(),
                location,
                all_day : allDay,
                from_date : fromDate,
                to_date : toDate,
                host,
                related_to : relatedTo.toUpperCase(),
                candidate,
                participants,
                currency : currency.toUpperCase(),
                description,
                repeat,
                reminders : reminder.toUpperCase(),
                todo_type: 'EVENTS'
            };
        } else if (editingCallId) {
            callData = {
                subject: callSubject,
                call_type: callType.toUpperCase(),
                call_purpose: callPurpose.toUpperCase(),
                contact_name_contact: contactType === 'contact' ? selectedContactId : null,
                contact_name_candidate: contactType === 'candidate' ? selectedCandidateId : null,
                candidate: candidateId || selectedCandidate,
                related_to: relatedTo || null,
                posting_title: selectedJobId || null,
                call_details: selectedCallType,
                call_timer: formatTimeAsHHMMSS(timer),
                call_result: callResult || null,
                call_start_time: callStartTime || null,
                call_duration: formatTimeCallDuration(callDuration),
                description: description || null,
                billable: billable || false,
                owner: owner || null,
                reminders: reminder ? reminder.toUpperCase() : null,
                todo_type: 'CALLS'
            };
        };
        
        if (taskData && taskData.todo_type === 'TASKS') {
            result = await updateTasks({ id: editingTaskId, data: taskData });
        } else if (eventData && eventData.todo_type === 'EVENTS') {
            result = await updateEvents({ id: editingEventId, data: eventData });
        } else if (callData && callData.todo_type === 'CALLS') {
            result = await updateCalls({ id: editingCallId, data: callData });
        } else {
            console.error('No valid data to update');
            return;
        }

        if (result && result.error) {
            showToast('error', result.error.data.msg || 'Failed to update');
        } else if (result) {
            showToast('success', 'Updated successfully');
            setEditingTaskId(null);
            setEditingTaskText('');
            setSelectedCandidate('');
            setEditingEventId(null);
            handleCloseEventDialog()
            setEditingCallId(null);
            handleCloseCallDialog();
        } else {
            console.error('No result returned from update function');
        }
        refetch();
        eventListDataInfo(); 
        callListDataInfo();
    };

    const handleMarkComplete = async (id, todoType) => {
        try {
            let result;
            if (todoType === 'TASKS') {
                result = await completeTask(id).unwrap();
            } else if (todoType === 'EVENTS') {
                // result = await completeEvent(id).unwrap();
            } else if (todoType === 'CALLS') {
                // result = await completeCall(id).unwrap();
            }

            showToast('success', 'Task marked as completed');
            refetch(); // Refresh the task list
        } catch (error) {
            showToast('error', error.data?.message || 'Failed to mark task as completed');
        }
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return new Date(dateString).toLocaleString(undefined, options);
      };

      const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')} : ${String(secs).padStart(2, '0')}`;
    };

    const columns = [
        {
            field: 'complete',
            headerName: '',
            width: 100,
            renderCell: (params) => ( 
                <> 
                    <Tooltip title="Mark as Completed">
                        <IconButton
                            onClick={() => handleMarkComplete(params.row.id, params.row.todo_type)}
                            sx={{
                                backgroundColor: 'green', // Set the background color
                                color: 'white', // Set the icon color
                                '&:hover': {
                                    backgroundColor: 'darkgreen', // Set the hover color
                                },
                                borderRadius: '50%', // Make it circular
                                padding: '0.5px', // Adjust padding for size
                            }}
                        >
                            <CheckIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
        // { field: 'id', headerName: 'Id', width: 50 },
        { field: 'displaySubject', headerName: 'Subject', width: 550 },
        { field: 'todo_type', headerName: 'To-Do Type', width: 150 },
        { field: 'status', headerName: 'Status', width: 150 },
        { field: 'duedate', headerName: 'Duedate', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <>  
                    <Button variant="contained" onClick={() => onEditHandler(params.row)} sx={{ mr: 1 }}>
                        Edit
                    </Button>
                    <Button variant="contained" onClick={() => handleDeleteDialogOpen(params.row.id, params.row.todo_type)}>
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    const rows = taskListData?.map((task) => ({
        id: task.id,
        subject: task.subject,
        status: task.status,
        duedate: task.duedate,
        priority: task.priority, // Add priority
        owner: task.owner, // Add owner
        when_date: task.when_date, // Add reminder date
        when_time: task.when_time, // Add reminder time
        task_repeat: task.task_repeat, // Add repeat
        task_alert: task.task_alert, // Add alert
        start_date: task.start_date, // Add start date
        end_date: task.end_date, // Add end date
        repeat_type: task.repeat_type, // Add repeat type
        contact_name: task.contact_name, // Add contact name
        related_to: task.related_to, // Add related to
        currency: task.currency, // Add currency
        email_notification: task.email_notification, // Add email notification
        exchange_rate: task.exchange_rate, // Add exchange rate
        description: task.description, // Add description
        todo_type: task.todo_type,
    })) || [];

    const handleReminderOpen = () => {
        setReminderOpen(true);
    };

    const handleReminderClose = () => {
        setReminderOpen(false);
    };

    const handleRepeatOpen = () => {
        setRepeatOpen(true);
    };

    const handleRepeatClose = () => {
        setRepeatOpen(false);
    };

    const handleMoreFieldsOpen = () => {
        setMoreFieldsOpen(true);
    };

    const handleMoreFieldsClose = () => {
        setMoreFieldsOpen(false);
    };

    const handleOpenEventDialog = () => {
        setOpenEventDialog(true);
    };

    const handleCloseEventDialog = () => {
        setOpenEventDialog(false);
        setEditingEventId(null);
        closeEventEditHandler();
    };
    
    const handleOpenCallDialog = () => {
        setOpenCallDialog(true);
    };

    const handleCloseCallDialog = () => {
        setOpenCallDialog(false);
        setEditingCallId(null);
        closeCallEditHandler();
    };

    const handleCallMoreFieldsOpen = () => {
        setCallMoreFieldsOpen(true);
    };

    const handleCallMoreFieldsClose = () => {
        setCallMoreFieldsOpen(false);
    };

    const toggleMoreDetails = () => {
        setShowMoreDetails(!showMoreDetails);
    };

    const Transition = forwardRef((props, ref) => (
        <Slide direction="right" ref={ref} {...props} />
    ));

    return (
        <>
            <div>
                <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid  item md={8} sx={{ mt: 1, mb: 2 }}>
                        <h3 className=''>To-Dos</h3>
                    </Grid>
                    <Grid item md={4} style={{ textAlign: 'center' }}>
                        <Button
                            onClick={handleClickOpen}
                            variant="contained"
                            style={{ textTransform: 'capitalize', marginRight: '8px' }}
                        >
                            New Task
                        </Button>
                        <Button
                            onClick={handleOpenEventDialog}
                            variant="contained"
                            style={{ textTransform: 'capitalize', marginRight: '8px' }}
                        >
                            New Event
                        </Button>
                        <Button
                            onClick={handleOpenCallDialog}
                            variant="contained"
                            style={{ textTransform: 'capitalize' }}
                        >
                            New Call
                        </Button>
                    </Grid>
                </Grid>
                <Divider />
            </div>
            <div style={{ height: 300, width: '100%', marginTop: '30px' }}>
                <AutoSizer>
                    {({ height, width }) => (
                    <DataGrid
                        rows={combinedData}
                        columns={columns}
                        pageSize={3}
                        rowsPerPageOptions={[3]}
                        disableSelectionOnClick
                        autoHeight
                        style={{ height, width }}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? classes.evenRow : classes.oddRow
                        }
                    />
                    )}
                </AutoSizer>
            </div>
            
            <Dialog open={openTaskDialog} onClose={handleClose} maxWidth="sm" fullWidth sx={{ '& .MuiDialog-paper': { minHeight: '650px', minWidth: '600px' } }}>
                <DialogTitle>{isEditMode ? 'Edit Task' : 'Add Task'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="subject"
                        label="Subject"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />  
                    <TextField
                        margin="dense"
                        id="due-date"
                        label="Due Date"
                        type="date"
                        fullWidth
                        variant="outlined"
                        value={dueDate}
                        onChange={(e) => setDueDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true, 
                        }}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="priority-select-label">Priority</InputLabel>
                        <Select
                            labelId="priority-select-label"
                            value={priority}
                            onChange={(e) => setPriority(e.target.value)}
                            label="Priority"
                        >
                            {candidateTaskOptionsData?.priority_fields && Object.entries(candidateTaskOptionsData.priority_fields).map(([key, value]) => (
                                <MenuItem key={key} value={value}>{key}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="owner-select-label">Owner</InputLabel>
                        <Select
                            labelId="owner-select-label"
                            value={owner}
                            onChange={(e) => setOwner(e.target.value)}
                            label="Owner"
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 48 * 4 + 8, 
                                        width: 250,
                                    },
                                },
                            }}
                        >
                            {getownerData?.list?.map((item) => (
                                <MenuItem key={item.account_id} value={item.account_id}>
                                    {item?.first_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        id="reminder"
                        label="Reminder"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onClick={handleReminderOpen}
                        value={reminder}
                        onChange={(e) => setReminder(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="repeat"
                        label="Repeat"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onClick={handleRepeatOpen}
                        value={repeat}
                        onChange={(e) => setRepeat(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="morefields"
                        label="More Fields"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onClick={handleMoreFieldsOpen}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={isEditMode ? saveEditHandler : onTaskFormSubmit}>
                        {isEditMode ? 'Save' : 'Add'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={reminderOpen} onClose={handleReminderClose} maxWidth="sm" fullWidth sx={{ '& .MuiDialog-paper': { minHeight: '650px', minWidth: '600px' } }}>
                <DialogTitle>Set Reminder</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="When"
                        type="date"
                        fullWidth
                        variant="outlined"
                        name="date"
                        value={reminder}
                        onChange={(e) => setReminder(e.target.value)}
                        InputLabelProps={{
                            shrink: true, 
                        }}
                    />
                    <TextField
                        margin="dense"
                        label="Time"
                        type="time"
                        fullWidth
                        variant="outlined"
                        name="time"
                        value={reminderTime}
                        onChange={(e) => setReminderTime(e.target.value)}
                        InputLabelProps={{
                            shrink: true, 
                        }}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="repeat-select-label">Repeat</InputLabel>
                        <Select
                            label="Repeat"
                            labelId="repeat-select-label"
                            name="repeat"
                            value={repeat}
                            onChange={(e) => setRepeat(e.target.value)}
                        >
                            {candidateTaskOptionsData?.task_repeat && Object.entries(candidateTaskOptionsData.task_repeat).map(([key, value]) => (
                                <MenuItem key={key} value={value}>{key}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="alert-select-label">Alert</InputLabel>
                        <Select
                            label="Alert"
                            labelId="alert-select-label"
                            name="alert"
                            value={alert}
                            onChange={(e) => setAlert(e.target.value)}
                        >
                            {candidateTaskOptionsData?.task_alert && Object.entries(candidateTaskOptionsData.task_alert).map(([key, value]) => (
                                <MenuItem key={key} value={value}>{key}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleReminderClose}>Cancel</Button>
                    <Button onClick={() => { handleReminderClose(); }}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={repeatOpen} onClose={handleRepeatClose} maxWidth="sm" fullWidth sx={{ '& .MuiDialog-paper': { minHeight: '650px', minWidth: '600px' } }}>
                <DialogTitle>Set Repeat</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Start Date"
                        type="date"
                        fullWidth
                        variant="outlined"
                        name="startDate"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true, // Ensures the label is always visible
                        }}
                    />
                    <TextField
                        margin="dense"
                        label="End Date"
                        type="date"
                        fullWidth
                        variant="outlined"
                        name="endDate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true, // Ensures the label is always visible
                        }}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="repeat-type-select-label">Repeat Type</InputLabel>
                        <Select
                            label="Repeat Type"
                            labelId="repeat-type-select-label"
                            name="repeatType"
                            value={repeatType}
                            onChange={(e) => setRepeatType(e.target.value)}
                        >
                            {candidateTaskOptionsData?.task_repeat && Object.entries(candidateTaskOptionsData.task_repeat).map(([key, value]) => (
                                <MenuItem key={key} value={value}>{key}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRepeatClose}>Cancel</Button>
                    <Button onClick={() => { /* Handle save repeat logic */ handleRepeatClose(); }}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={moreFieldsOpen} onClose={handleMoreFieldsClose} maxWidth="sm" fullWidth sx={{ '& .MuiDialog-paper': { minHeight: '650px', minWidth: '600px' } }}>
                <DialogTitle>More Fields</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Contact Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="contactName"
                        value={contactName}
                        onChange={(e) => setContactName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Related To"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="relatedTo"
                        value={relatedTo}
                        onChange={(e) => setRelatedTo(e.target.value)}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="status-select-label">Status</InputLabel>
                        <Select
                            label="Status"
                            labelId="status-select-label"
                            name="status"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            {candidateTaskOptionsData?.status_fields && Object.entries(candidateTaskOptionsData.status_fields).map(([key, value]) => (
                                <MenuItem key={key} value={value}>{key}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={emailNotification}
                                onChange={(e) => setEmailNotification(e.target.checked)}
                                name="sendNotification"
                            />
                        }
                        label="Send Notification Email"
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="currency-select-label">Currency</InputLabel>
                        <Select
                            label="Currency"
                            labelId="currency-select-label"
                            name="currency"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                        >
                            {candidateTaskOptionsData?.currency && Object.entries(candidateTaskOptionsData.currency).map(([key, value]) => (
                                <MenuItem key={key} value={value}>{key}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Exchange Rate"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="exchangeRate"
                        value={exchangeRate}
                        onChange={(e) => setExchangeRate(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Description"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleMoreFieldsClose}>Cancel</Button>
                    <Button onClick={() => { /* Handle save more fields logic */ handleMoreFieldsClose(); }}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openEventDialog} onClose={handleCloseEventDialog} maxWidth="sm" fullWidth sx={{ '& .MuiDialog-paper': { minHeight: '550px', minWidth: '600px' } }}>
                <DialogTitle>Event Information</DialogTitle>
                <DialogContent dividers style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Event Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={eventName}
                        onChange={(e) => setEventName(e.target.value)}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Meeting Venue</InputLabel>
                        <Select
                            label="Meeting Venue"
                            value={meetingVenue}
                            onChange={(e) => setMeetingVenue(e.target.value)}
                        >
                            {Object.entries(candidateEventOptionsData?.meeting_venue || {}).map(([key, value]) => (
                                <MenuItem key={key} value={value}>{key}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Location"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={allDay} onChange={(e) => setAllDay(e.target.checked)}/>}
                        label="All day"
                    />
                    <TextField
                        margin="dense"
                        label="From"
                        type="datetime-local"
                        fullWidth
                        variant="outlined"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        margin="dense"
                        label="To"
                        type="datetime-local"
                        fullWidth
                        variant="outlined"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Host</InputLabel>
                        <Select
                            label="Host"
                            value={host}
                            onChange={(e) => setHost(e.target.value)}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 48 * 4 + 8, 
                                        width: 250,
                                    },
                                },
                            }}
                        >
                            {getownerData?.list?.map((item) => (
                                <MenuItem key={item.account_id} value={item.account_id}>
                                    {item?.first_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Related To</InputLabel>
                        <Select
                            label="Related To"
                            value={relatedTo}
                            onChange={(e) => setRelatedTo(e.target.value)}
                        >
                            {Object.entries(candidateEventOptionsData?.related_to || {}).map(([key, value]) => (
                                <MenuItem key={key} value={value}>{key}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Candidate</InputLabel>
                        <Select
                            label="Candidate"
                            value={candidate}
                            onChange={(e) => setCandidate(e.target.value)}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 48 * 4 + 8, 
                                        width: 250,
                                    },
                                },
                            }}
                            endAdornment={
                                <IconButton>
                                    <SearchIcon />
                                </IconButton>
                            }
                        >
                            {candidateListData?.list?.map((candidate) => (
                                <MenuItem key={candidate.id} value={candidate.id}>
                                    {candidate.first_name} {candidate.last_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Participants</InputLabel>
                        <Select
                            label="Participants"
                            multiple
                            value={participants}
                            onChange={(e) => setParticipants(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
                            renderValue={(selected) => selected.map(id => {
                                const contact = contactsData?.data?.find(contact => contact.id === id);
                                return contact ? contact.name : id;
                            }).join(', ')}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 48 * 4 + 8, 
                                        width: 250,
                                    },
                                },
                            }}
                            endAdornment={
                                <Button color="primary" style={{ textTransform: 'capitalize' }}>
                                    + Add
                                </Button>
                            }
                        >
                            {contactsData?.data?.map((contact) => (
                                <MenuItem key={contact.id} value={contact.id}>
                                    {contact.name} ({contact.email})
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Currency</InputLabel>
                        <Select
                            label="Currency"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                        >
                            {Object.entries(candidateEventOptionsData?.currency || {}).map(([key, value]) => (
                                <MenuItem key={key} value={value}>{key}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {/* More Details Section */}
                    <Collapse in={showMoreDetails}>
                        <TextField
                            margin="dense"
                            label="Description"
                            type="text"
                            fullWidth
                            variant="outlined"
                            multiline
                            rows={3}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Repeat</InputLabel>
                            <Select
                                label="Repeat"
                                value={repeatEvent}
                                onChange={(e) => setRepeatEvent(e.target.value)}
                            >
                                {Object.entries(candidateEventOptionsData?.repeat_fields || {}).map(([key, value]) => (
                                    <MenuItem key={key} value={value}>{key}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Reminder</InputLabel>
                            <Select
                                label="Reminder"
                                value={reminder}
                                onChange={(e) => setReminder(e.target.value)}
                            >
                                {Object.entries(candidateEventOptionsData?.reminder_fields || {}).map(([key, value]) => (
                                    <MenuItem key={key} value={value}>{key}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Collapse>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ flexGrow: 1 }}>
                        <Button onClick={toggleMoreDetails} color="primary" style={{ textTransform: 'capitalize' }}>
                            {showMoreDetails ? 'Hide Details' : 'Add More Details'}
                        </Button>
                    </Box>
                    <Button onClick={ handleCloseEventDialog }>Cancel</Button>
                    <Button onClick={ isEditMode ? saveEditHandler : onEventFormSubmit } variant="contained" color="primary">
                        {isEditMode ? 'Save' : 'Add'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openCallDialog} onClose={handleCloseCallDialog} maxWidth="sm" fullWidth sx={{ '& .MuiDialog-paper': { minHeight: '550px', minWidth: '600px' } }}>
                <DialogTitle>Create Call</DialogTitle>
                <DialogContent dividers style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Subject"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={callSubject}
                        onChange={(e) => setCallSubject(e.target.value)}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Call Type</InputLabel>
                        <Select 
                            labelId="priority-select-label"
                            value={callType} 
                            onChange={(e) => setCallType(e.target.value)}
                            label="Call Type"
                        >
                            {candidateCallOptionsData?.call_type && Object.entries(candidateCallOptionsData.call_type).map(([key, value]) => (
                                <MenuItem key={key} value={key}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Call Purpose</InputLabel>
                        <Select
                            value={callPurpose}
                            onChange={(e) => setCallPurpose(e.target.value)}
                            labelId="priority-select-label"
                            label="Call Purpose"
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 48 * 4 + 8, 
                                        width: 250,
                                    },
                                },
                            }}
                        >
                            {candidateCallOptionsData?.call_purpose && Object.entries(candidateCallOptionsData.call_purpose).map(([key, value]) => (
                                <MenuItem key={key} value={key}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box display="flex" alignItems="center">
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Contact Name</InputLabel>
                            <Select
                                value={contactType}
                                onChange={(e) => setContactType(e.target.value)}
                                labelId="priority-select-label"
                                label="Contact Name"
                            >
                                <MenuItem value="contact">Contact</MenuItem>
                                <MenuItem value="candidate">Candidate</MenuItem>
                                {/* Add more options as needed */}
                            </Select>
                        </FormControl>
                        <TextField
                            margin="dense"
                            type="text"
                            variant="outlined"
                            select
                            value={contactType === 'contact' ? selectedContactId : selectedCandidateId}
                            onChange={(e) => {
                                if (contactType === 'contact') {
                                    setSelectedContactId(e.target.value);
                                } else {
                                    setSelectedCandidateId(e.target.value);
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <IconButton>
                                        <SearchIcon />
                                    </IconButton>
                                ),
                            }}
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        style: {
                                            maxHeight: 48 * 4 + 8,
                                            width: 250,
                                        },
                                    },
                                },
                            }}
                        >
                            {contactType === 'contact' && contactsData?.data?.map((contact) => (
                                <MenuItem key={contact.id} value={contact.id}>
                                    {contact.name} ({contact.email})
                                </MenuItem>
                            ))}
                            {contactType === 'candidate' && candidateListData?.list?.map((candidate) => (
                                <MenuItem key={candidate.id} value={candidate.id}>
                                    {candidate.first_name} {candidate.last_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    <TextField
                        margin="dense"
                        label="Related To"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={relatedTo}
                        onChange={(e) => setRelatedTo(e.target.value)}

                    />
                    <Box display="flex" alignItems="center">
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Posting Title</InputLabel>
                            <Select
                                labelId="priority-select-label"
                                label="Posting Title"
                                onChange={(e) => setPostingTitle(e.target.value)}
                                value={postingTitle}
                            >
                                <MenuItem value="JobOpening">Job Opening</MenuItem>
                                {/* Add more options as needed */}
                            </Select>
                        </FormControl>
                        <TextField
                            margin="dense"
                            type="text"
                            variant="outlined"
                            select
                            value={postingTitle === 'JobOpening' ? selectedJobId : ''}
                            onChange={(e) => setSelectedJobId(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <IconButton>
                                        <SearchIcon />
                                    </IconButton>
                                ),
                            }}
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        style: {
                                            maxHeight: 48 * 5 + 8, 
                                            width: 250, 
                                        },
                                    },
                                },
                            }}
                        >
                            {postingTitle === 'JobOpening' && jobData?.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    <Box mt={2} p={2} border={1} borderColor="grey.300" borderRadius={1}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Call Details</FormLabel>
                            <RadioGroup row value={selectedCallType} onChange={handleCallTypeChange}>
                                {candidateCallOptionsData?.call_status && 
                                    Object.entries(candidateCallOptionsData.call_status).map(([key, value]) => (
                                        <FormControlLabel key={key} value={key} control={<Radio />} label={value} />
                                    ))
                                }
                            </RadioGroup>
                        </FormControl>
                        {selectedCallType === 'CURRENTCALL' && (
                            <Box display="flex" alignItems="center" mt={1}>
                                <TextField
                                    margin="dense"
                                    label="Call Timer"
                                    type="text"
                                    variant="outlined"
                                    value={formatTimeAsHHMMSS(timer)}
                                    style={{ width: '80px' }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <Button color="primary" onClick={handleStartStop} style={{ marginLeft: '8px' }}>
                                    {isRunning ? 'Stop' : 'Start'}
                                </Button>
                                <Button color="secondary" onClick={handleReset} style={{ marginLeft: '8px' }}>
                                    Reset
                                </Button>
                            </Box>
                        )}
                    </Box>
                    {selectedCallType === 'CURRENTCALL' && (
                        <Box mt={1}>
                            <TextField
                                margin="dense"
                                label="Call Result"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={callResult}
                                onChange={(e) => setCallResult(e.target.value)}
                            />
                        </Box>
                        )}
                    {selectedCallType === 'COMPLETEDCALL' && (
                        <Box mt={1}>
                            <TextField
                                margin="dense"
                                label="Call Start Time"
                                type="datetime-local"
                                fullWidth
                                variant="outlined"
                                value={callStartTime}
                                onChange={(e) => setCallStartTime(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                                    <TimePicker
                                        views={['minutes', 'seconds']}
                                        inputFormat="mm:ss"
                                        value={callDuration || new Date(0, 0, 0, 0, 0, 0)}
                                        onChange={(newValue) => setCallDuration(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                variant="outlined"
                                                label="Call Duration"
                                            />
                                        )}
                                    />
                                    <Typography variant="body2" sx={{ ml: 1 }}>
                                        minutes
                                    </Typography>
                                    <Typography variant="body2" sx={{ ml: 1 }}>
                                        seconds
                                    </Typography>
                                </Box>
                            </LocalizationProvider>
                            <TextField
                                margin="dense"
                                label="Call Result"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={callResult}
                                onChange={(e) => setCallResult(e.target.value)}
                            />
                        </Box>
                    )}

                    {selectedCallType === 'SCHEDULECALL' && (
                        <Box mt={1}>
                            <TextField
                                margin="dense"
                                label="Call Start Time"
                                type="datetime-local"
                                fullWidth
                                variant="outlined"
                                value={callStartTime}
                                onChange={(e) => setCallStartTime(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                margin="dense"
                                label="Call Result"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={callResult}
                                onChange={(e) => setCallResult(e.target.value)}
                            />
                            <FormControl fullWidth margin="dense">
                                <InputLabel id="owner-select-label">Owner</InputLabel>
                                <Select
                                    labelId="owner-select-label"
                                    value={owner}
                                    onChange={(e) => setOwner(e.target.value)}
                                    label="Owner"
                                >
                                    {getownerData?.list?.map((item) => (
                                        <MenuItem key={item.account_id} value={item.account_id}>
                                            {item?.first_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth margin="dense">
                                <InputLabel id="reminder-select-label">Reminder</InputLabel>
                                <Select
                                    labelId="reminder-select-label"
                                    value={reminder}
                                    onChange={(e) => setReminder(e.target.value)}
                                    label="Reminder"
                                >
                                {candidateCallOptionsData?.reminder_fields && Object.entries(candidateCallOptionsData.reminder_fields).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>{value}</MenuItem>
                                ))}
                                    
                                </Select>
                            </FormControl>
                            {/* <Button onClick={handleMoreFieldsOpen} variant="outlined" fullWidth>
                                More Fields
                                </Button> */}
                        </Box>
                    )}
                    <TextField
                        margin="dense"
                        id="morefields"
                        label="More Fields"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onClick={handleCallMoreFieldsOpen}
                    />
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCallDialog}>Cancel</Button>
                    <Button onClick={isEditMode ? saveEditHandler : onCallFormSubmit} variant="contained" color="primary">
                        {isEditMode ? 'Save' : 'Add'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={callMoreFieldsOpen} onClose={handleCallMoreFieldsClose} maxWidth="sm" fullWidth sx={{ '& .MuiDialog-paper': { minHeight: '550px', minWidth: '600px' } }}>
                <DialogTitle>More Fields</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Description"
                        type="text"
                        fullWidth
                        variant="outlined"
                        name="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={billable}
                                onChange={(e) => setbillable(e.target.checked)}
                                name="billable"
                            />
                        }
                        label="Billable"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCallMoreFieldsClose}>Cancel</Button>
                    <Button onClick={() => { /* Handle save more fields logic */ handleCallMoreFieldsClose(); }}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteDialogClose}
                sx={{ '& .MuiDialog-paper': { minHeight: '200px' } }} // Adjust the height as needed
                >
                <DialogTitle>Delete Item</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    Are you sure you want to delete this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose} color="primary">
                    Disagree
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
                    Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AddForm;