import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { 
  Card, 
  Box, 
  Stack, 
  TextField, 
  Container, 
  CircularProgress, 
  Grid, 
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useGetCareerSiteCompanyDataQuery, useUpdateCareerSiteCompanyDataMutation } from "../../../redux/services/settings/CareerSiteService";
import { showToast } from "../../../utils/toast";
import BackgroundImageURL from "../../../assets/images/BackgroundImageURL.jpeg"


const CareerSiteDynamicUpdate = () => {
    const { companyId } = useParams();    
    const { data, refetch } = useGetCareerSiteCompanyDataQuery(companyId);
    const [updateCareerSiteCompanyData] = useUpdateCareerSiteCompanyDataMutation();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        background_image: '',
        title1: '',
        title2: '',
        title3: ''
    });


    useEffect(() => {
        if (data && data.companies_details) {
            setFormData({
                background_image: data.companies_details.background_image || '',
                title1: data.companies_details.title1 || '',
                title2: data.companies_details.title2 || '',
                title3: data.companies_details.title3 || ''
            });
        }
    }, [data]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Assuming you want to store the file object itself
            setFormData({
                ...formData,
                background_image: file
            });
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async () => {
        try {
            const formDataToSend = new FormData();
            
            // Append the file only if it exists
            if (formData.background_image instanceof File) {
                formDataToSend.append('background_image', formData.background_image);
            }
            
            formDataToSend.append('title1', formData.title1);
            formDataToSend.append('title2', formData.title2);
            formDataToSend.append('title3', formData.title3);
    
            await updateCareerSiteCompanyData({ companyId, updatedcareersitedata: formDataToSend });
            refetch();
            showToast("success", "Company data updated successfully!")
            setFormData({
                background_image: '',
                title1: '',
                title2: '',
                title3: ''
            });
            navigate('/dashboard/career-site'); 
        } catch (error) {
            console.error("Failed to update company data", error);
            showToast("error", "Failed to update company data")
        }
    };

    if (!data || !data.companies_details) {
        return (
            <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
              <CircularProgress />
            </Container>
        );
    }

    const navigateCancel = () => {
        navigate(-1);
    }

    return (
        <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                    }}
                >
                    <div className="backbutton tt-back" style={{display: "flex" }} >
                        <ArrowBackIosIcon onClick={navigateCancel} sx={{
                            cursor: "pointer"
                        }} />
                    </div>
                    <Typography 
                    variant="h3" 
                    color="textPrimary" 
                    sx={{ 
                        width: '300px',
                        textDecorationLine: 'underline',
                        backgroundColor: 'transparent !important'
                    }}
                    >
                    Update Banner
                    </Typography>
                </Container>
        </Stack>
            <DialogContent>
                <Card sx={{ minWidth: 275, p: 4 }} variant="outlined">
                    <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={3} >
                        <Grid item xs={12} md={6}>
                            <TextField
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="background_image"
                                label="Background Image URL"
                                value={formData.background_image || ''}
                                onChange={handleChange}
                            />
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ marginTop: '10px' }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="title1"
                                label="Title 1"
                                value={formData.title1 || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="title2"
                                label="Title 2"
                                value={formData.title2 || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="title3"
                                label="Title 3"
                                value={formData.title3 || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <LoadingButton variant="contained" onClick={handleSubmit}> Update </LoadingButton>
                        </Grid>
                    </Grid>
                    </Box>
                </Card>
            </DialogContent>

            <Box sx={{ minHeight: "50vh", mt: 13, p: 0 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundImage: `url(${formData.background_image instanceof File ? URL.createObjectURL(formData.background_image) : BackgroundImageURL})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        textAlign: 'center',
                        py: 5,
                        position: 'relative',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent overlay
                            zIndex: 1,
                        },
                        '& > *': {
                            position: 'relative',
                            zIndex: 2,
                            maxWidth: '700px',
                            mx: 'auto',
                            color: '#fff', // Ensure text is white
                        }
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 2, color: "#fff", backgroundColor: "transparent !important" }}>
                        {formData?.title1 || "About iNtechspaces"}
                    </Typography>
                    <Typography variant="h2" sx={{ fontWeight: "bold", mb: 2, color: "#fff", backgroundColor: "transparent !important" }}>
                        {formData?.title2 || "We design and manufacture technology that takes the limits off living."}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 4, color: "#fff", backgroundColor: "transparent !important" }}>
                        {formData?.title3 ||
                            "We're a company of people who care about each other, about our customers and their patients, and about our communities. Together, with around 19,000 employees globally, we fulfill our shared purpose of Life Unlimited."}
                    </Typography>
                </Box>
            </Box>



        </Container>
    );
};

export default CareerSiteDynamicUpdate;