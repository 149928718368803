import React, { useState, useEffect, useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { Card, Stack, Button, Container, Typography, ListItemIcon, Grid, DialogContent, TableContainer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { formatQuery, QueryBuilder, defaultOperators } from 'react-querybuilder';
import { QueryBuilderAntD } from '@react-querybuilder/antd';
// components
import MainModuleFilter from '../../../components/main/MainModuleFilter';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import CandidatesModel from '../../../components/Mains/CandidatesModel';
import { sortedDataFn } from '../../../utils/getSortedData';
import { showToast } from '../../../utils/toast';
import {
  useGetCandidateListQuery,
  useDeleteCandidateMutation,
  useAddApplyJobMutation,
  useApplyCandidateFilterMutation
} from '../../../redux/services/candidate/CandidateServices';
import { useGetJobCandidatesQuery, useGetJobCandidateQuery } from '../../../redux/services/jobs/JobListService';
import { HighestQualification, ProfessionalDegreeOptions, Boards, FunctionalArea, NoticePeriod, State, Country, City } from './CandidateFilterOptions';
import 'react-querybuilder/dist/query-builder.css';
import OperatorMappings from '../OperatorMappings';


// mock

const initialQuery = { combinator: 'and',
 rules: [
  { field: 'first_name', operator: 'contains', value: '' },
 ] };

const fields = [
  { name: 'first_name', label: 'First Name', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'last_name', label: 'Last Name', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'middle_name', label: 'Middle Name', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'job__title', label: 'Associated Job', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'pipeline_stage_status', label: 'Status', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  // { name: 'source', label: 'Sourced from', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'mobile', label: 'Phone', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'gender', label: 'Gender', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'email', label: 'Email', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'marital_status', label: 'Marital Status', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'subjects', label: 'Subjects', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  // { name: 'date_of_birth', label: 'Date Of Birth', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'date', operators: OperatorMappings().date },
  // { name: 'age', label: 'Age', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'number', operators: OperatorMappings().number },
  { name: 'exp_years', label: 'Years Of Experience', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'number', operators: OperatorMappings().number },
  { name: 'highest_qualification', label: 'Highest Qualification', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  // {
  //   name: 'highest_qualification',
  //   label: 'Highest Qualification',
  //   valueEditorType: 'multiselect',
  //   values: HighestQualification,
  //   defaultValue: 'Cowbell',
  //   valueSources: ['value'],
  //   groupNumber: 1,
  //   comparator: '=',
  //   operators: OperatorMappings().object,
  // },
  { name: 'cur_employer', label: 'Current Employer', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  { name: 'professional_start_date', label: 'Professional Start Date', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'date', operators: OperatorMappings().date },
  { name: 'professional_end_date', label: 'Professional End Date', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'date', operators: OperatorMappings().date },
  { name: 'current_job_title', label: 'Current Job Title', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  {
    name: 'professional_degree',
    label: 'Professional Degree',
    valueEditorType: 'multiselect',
    values: ProfessionalDegreeOptions,
    defaultValue: 'Cowbell',
    valueSources: ['value'],
    groupNumber: 1,
    comparator: '=',
    operators: OperatorMappings().object,
  },
  {
    name: 'curriculum_board',
    label: 'Curriculum Board',
    valueEditorType: 'multiselect',
    values: Boards,
    defaultValue: 'Cowbell',
    valueSources: ['value'],
    groupNumber: 1,
    comparator: '=',
    operators: OperatorMappings().object,
  },
  {
    name: 'fun_area',
    label: 'Functional Area',
    valueEditorType: 'multiselect',
    values: FunctionalArea,
    defaultValue: 'Cowbell',
    valueSources: ['value'],
    groupNumber: 1,
    comparator: '=',
    operators: OperatorMappings().object,
  },
  {
    name: 'notice_period',
    label: 'Notice Period',
    valueEditorType: 'multiselect',
    values: NoticePeriod,
    defaultValue: 'Cowbell',
    valueSources: ['value'],
    groupNumber: 1,
    comparator: '=',
    operators: OperatorMappings().object,
  },
  { name: 'street', label: 'Street', valueSources: ['value'], groupNumber: 1, comparator: '=', inputType: 'text', operators: OperatorMappings().text },
  {
    name: 'state',
    label: 'State',
    valueSources: ['value'],
    groupNumber: 1,
    comparator: '=',
    inputType: 'text', 
    operators: OperatorMappings().text, 
  },
  {
    name: 'country',
    label: 'Country',
    valueSources: ['value'],
    groupNumber: 1,
    comparator: '=',
    inputType: 'text', 
    operators: OperatorMappings().text, 
  },
  {
    name: 'city',
    label: 'City',
    valueSources: ['value'],
    groupNumber: 1,
    comparator: '=',
    inputType: 'text', 
    operators: OperatorMappings().text, 
  },
];

const addGroupAction = (props) => {
  return null;
};

const Candidates = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { ids: id, status } = location.state || {};
  const [modelOpen, setModelOpen] = useState(false);
  const [deleteJobId, setDeleteJobID] = useState()
  const [candidateId, setCandidateId] = useState();
  const [salectedJobId, setSalectedJobId] = useState('');
  const { data = [], refetch } = useGetCandidateListQuery();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [deleteJob, deleteJobInfo] = useDeleteCandidateMutation();
  const [dataList, setDataList] = useState(data?.list)
  const [addApplyCandidate, addApplyCandidateInfo] = useAddApplyJobMutation();
  const [query, setQuery] = useState(initialQuery);
  const [filterData, setFilterData] = useState({})
  const [applyCandidateFilter, applyCandidateFilterInfo] = useApplyCandidateFilterMutation();
  const [simpleFilterForm, setSimpleFilterForm] = useState(true)
  const [advanceFilterForm, setAdvanceFilterForm] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const savedVisibility = localStorage.getItem('columnVisibilityCandidate');
    return savedVisibility ? JSON.parse(savedVisibility) : {
      view: true,
      id: false,
      first_name: true,
      last_name: true,
      middle_name: true,
      job_title: true,
      updated: true,
      pipeline_stage: true,
      source: true,
      mobile: true,
      country: true,
      gender: true,
      email: true,
      marital_status: true,
      current_job_title: true,
      exp_years: true,
      highest_qualification: true,
      curriculum_board: true,
      fun_area: true,
      professional_degree: true,
      professional_start_date: true,
      professional_end_date: true,
      notice_period: true,
      state: true,
      city: true,
      subjects: true,
      action: true,
    };
  });

  const candidatesQuery = useGetJobCandidatesQuery(id ? +id : null, { skip: !id });
  const candidateQuery = useGetJobCandidateQuery({ id: id ? +id : null, stage: status }, { skip: !id || !status });
  const { data: candidateData, refetch: refetchCandidates } = status ? candidateQuery : candidatesQuery;  

  useEffect(() => {
    if (candidateData) {
      setDataList(candidateData);
    }
  }, [candidateData]);

  const handleClearFilter = () => {
    setQuery(initialQuery);
    setDataList(data?.list); 
    refetch(); 
  };

  // const removePercentageInBrackets = (query) => {
  //   // Use regular expression to match and replace % within brackets
  //   const modifiedQuery = query.replace(/\(([^)]*)\)/g, (match, content) => {
  //     // Replace % within the content with an empty string
  //     const withoutPercentage = content.replace(/%/g, '');
  //     return `(${withoutPercentage})`;
  //   });

  //   return modifiedQuery;
  // };

  // const handleQueryChange = async (newQuery) => {
  //   const myQuery = formatQuery(newQuery, 'sql')
  //   const modifiedQuery = removePercentageInBrackets(myQuery);
  //   const modifiedQueryWithoutBrackets = modifiedQuery.replace(/[()]/g, "");
  //   setFilterData(modifiedQueryWithoutBrackets)
  //   setQuery(newQuery);
  // };

  // const applyFilter = async () => {
  //   const formattedData = { query: filterData };
  //   const response = await applyCandidateFilter(formattedData);
  //   setDataList(response?.data?.result)
  // }

  const handleQueryChange = (newQuery) => {
    setQuery(newQuery);
  };

  // const applyFilter = async () => {
  //   const formattedQuery = formatQuery(query, 'sql');
  //   const response = await applyCandidateFilter({ query: formattedQuery });
  //   setDataList(response?.data?.result || []);
  // };

  const applyFilter = async () => {
    let formattedQuery = formatQuery(query, 'sql');
  
    // Replace 'and' and 'or' with their uppercase equivalents
    formattedQuery = formattedQuery.replace(/\band\b/g, 'AND');
    formattedQuery = formattedQuery.replace(/\bor\b/g, 'OR');
  
    // Replace specific operators with their required formats
    formattedQuery = formattedQuery.replace(/\bcontains\b/g, 'LIKE');
    formattedQuery = formattedQuery.replace(/\bnotContains\b/g, 'NOT LIKE');
    formattedQuery = formattedQuery.replace(/\bequals\b/g, '=');
    formattedQuery = formattedQuery.replace(/\bnotEqual\b/g, '<>');
    formattedQuery = formattedQuery.replace(/\bstartsWith\b/g, 'StartWith');
    formattedQuery = formattedQuery.replace(/\bendWith\b/g, 'EndWith');
    formattedQuery = formattedQuery.replace(/\bblank\b/g, 'IS NULL');
    formattedQuery = formattedQuery.replace(/\bnotBlank\b/g, 'IS NOT NULL');
    formattedQuery = formattedQuery.replace(/\bgreaterThan\b/g, '>');
    formattedQuery = formattedQuery.replace(/\bgreaterThanOrEqual\b/g, '>=');
    formattedQuery = formattedQuery.replace(/\blessThan\b/g, '<');
    formattedQuery = formattedQuery.replace(/\blessThanOrEqual\b/g, '<=');
    formattedQuery = formattedQuery.replace(/\btrue\b/g, 'TRUE');
    formattedQuery = formattedQuery.replace(/\bfalse\b/g, 'FALSE');
  
    // Add percentage signs for LIKE queries
    formattedQuery = formattedQuery.replace(/LIKE '([^']*)'/g, "LIKE '%$1%'");
    formattedQuery = formattedQuery.replace(/NOT LIKE '([^']*)'/g, "NOT LIKE '%$1%'");
    formattedQuery = formattedQuery.replace(/LIKE '%([^']*)'/g, "LIKE '%$1'"); // For endsWith
    formattedQuery = formattedQuery.replace(/LIKE '([^']*)%'/g, "LIKE '$1%'"); // For startsWith
  
    try {
      const response = await applyCandidateFilter({ query: formattedQuery });
      setDataList(response?.data?.result || []);
    } catch (error) {
      console.error("Error applying filter:", error);
    }
  };
  

  const navigateCancel = () => {
    // navigate('/dashboard/app');
    navigate(-1);
  };

  const sortData = useMemo(() => {
    const sortresult = sortedDataFn(data?.list);
    return sortresult;
  }, [data]);

  const onJobIDhandleChange = (event) => {
    event.preventDefault();
    setSalectedJobId(event.target.value);
  };
  const onDeleteHandler = async (deleteId) => {
    setActive(true);
    setOpen(false);
    setModelOpen(false);
    setCurrentIndex(deleteId);
    // const dataArr = sortData;
    // const currentDataObj = dataArr[dataIndex];
    await deleteJob(deleteId);
    await refetch();
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = React.useState(false);
  const [active, setActive] = React.useState(false);

  const handleClickOpen = (id) => {
    setDeleteJobID(id)
    setOpen(true);
  };

  const handleClosee = () => {
    setOpen(false);
  };
  const handleeClosee = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setActive(false);
  };

  // useEffect(() => {
  //   refetch();
  // }, [refetch]);

  useEffect(() => {
    const fetchData = async () => {
      await refetch();
    };
  
    fetchData();
  }, [refetch]);

  useEffect(() => {
    if (data?.list) {
      setDataList(data.list);
    }
  }, [data]);

  useEffect(() => {
    if (deleteJobInfo.isSuccess) {
      showToast('success', deleteJobInfo.data.msg);
      deleteJobInfo.reset();
      refetch();
    }
    if (deleteJobInfo.isError) {
      showToast('error', deleteJobInfo.error.data.msg);
      deleteJobInfo.reset();
      refetch();
    }
  }, [deleteJobInfo,refetch]);

  useEffect(() => {
    if (id && status) {
      refetchCandidates();
    }
  }, [refetchCandidates, id, status]);

  const onCandidateModelView = (candidateId) => {
    setCandidateId(candidateId);
    setModelOpen(true);
  };
  const handleClose = () => {
    setModelOpen(false);
  };

  const showAdvanceFilterForm = () => {
    setAdvanceFilterForm(true)
    setSimpleFilterForm(false)
  }

  useEffect(() => {
    // Save column visibility to local storage whenever it changes
    localStorage.setItem('columnVisibilityCandidate', JSON.stringify(columnVisibility));
  }, [columnVisibility]);
  

  const columns = [
    {
      name: 'view',
      label: 'View',
      options: {
        display: columnVisibility.view,
        filter: false,
        sort: false,
        setCellProps: () => ({ 'data-label': 'View' }),
        customBodyRenderLite: (dataIndex) => (
          <>
            <Button
              style={{ minWidth: 0, marginRight: '5px' }}
              // variant="contained"
              // onClick={() => onCandidateModelView(data.list[dataIndex].id)}
              onClick={() => {
                navigate(`/dashboard/candidate/perticularCandidate/${dataList[dataIndex]?.id}`);
              }}
              color="info"
            >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="carbon:view-filled" width={24} height={24} sx={{ color: '#C2CFE0' }} />
              </ListItemIcon>
            </Button>
          </>
        ),
      },
    },
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: true,
        sort: false,
        display: columnVisibility.id,
        setCellProps: () => ({ 'data-label': 'ID' }),
      },
    },
    {
      name: 'first_name',
      label: 'First Name',
      options: {
        display: columnVisibility.first_name,
        filter: true,
        sort: false,
        setCellProps: () => ({ 'data-label': 'First Name' }),
        customBodyRenderLite: (dataIndex) => {
          const candidate = dataList[dataIndex];
          return (
            <Button
              sx={{
                color: 'primary',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={() => {
                if (candidate?.id) {
                  navigate(`/dashboard/candidate/perticularCandidate/${candidate.id}`);
                }
              }}
              color="primary"
            >
              {candidate?.first_name}
            </Button>
          );
        },
      },
    },
    {
      name: 'last_name',
      label: 'Last Name',
      options: {
        display: columnVisibility.last_name,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Last Name' }),
      },
    },
    {
      name: 'middle_name',
      label: 'Middle Name',
      options: {
        display: columnVisibility.middle_name,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Middle Name' }),
      },
    },
    {
      name: 'job_title',
      label: 'Associated Job',
      options: {
        display: columnVisibility.job_title,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Associated Job' }),
        customBodyRender: (value, metaData) => {
          let name = value || '';
          if (name === '' && dataList[metaData.rowIndex].job__title) {
            name = dataList[metaData.rowIndex].job__title;
          }
          if (Array.isArray(name)) {
            name = name.join(', ');
          }
          return name;
        },
      },
    },
    {
      name: 'updated',
      label: 'Modified date and time',
      options: {
        display: columnVisibility.updated,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Modified date and time' }),
        customBodyRender: (value) => {
          if (value) {
            const date = new Date(value);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const formattedDate = `${day}/${month}/${year} ${date.toLocaleTimeString()}`;
            return <div>{formattedDate}</div>;
          }
          return <div>N/A</div>;
        },
      },
    },
    {
      name: 'pipeline_stage',
      label: 'Status',
      options: {
        display: columnVisibility.pipeline_stage,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Status' }),

      },
    },
    {
      name: 'source',
      label: 'Sourced from',
      options: {
        display: columnVisibility.source,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Sourced from' }),

        customBodyRender: (value) => (
          <span>{value || 'Add By User'}</span>
        ),
      },
    },
    {
      name: 'mobile',
      label: 'Phone',
      options: {
        display: columnVisibility.mobile,
        filter: true,
        sort: true,
        setCellProps: () => ({ 'data-label': 'Phone' }),

      },
    },
    {
      name: 'country',
      label: 'Country',
      options: {
        display: columnVisibility.country,
        filter: true,
        setCellProps: () => ({ 'data-label': 'Country' }),
        customBodyRender: (value, metaData) => {
          let name = value?.name || '';
          if(name === "") {
            name = dataList[metaData.rowIndex].country__name || "";
          }
          return name;
        },
      },
    },
    {
      name: 'gender',
      label: 'Gender',
      options: {
        display: columnVisibility.gender,
        filter: true,
        setCellProps: () => ({ 'data-label': 'Gender' }),

      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        display: columnVisibility.email,
        filter: true,
        setCellProps: () => ({ 'data-label': 'Email' }),
      },
    },
    {
      name: 'marital_status',
      label: 'Marital Status',
      options: {
        display: columnVisibility.marital_status,
        filter: true,
        setCellProps: () => ({ 'data-label': 'Marital Status' }),
      },
    },
    {
      name: 'current_job_title',
      label: 'Current Job title',
      options: {
        display: columnVisibility.current_job_title,
        filter: true,
        setCellProps: () => ({ 'data-label': 'Current Job title' }),
        customBodyRender: (value, metaData) => {
          // Check if the value is an object with a name property
          if (typeof value === 'object' && value !== null && value.name) {
            return value.name; // Return the name from the object
          } 
          // Check if the value is a string
          if (typeof value === 'string') {
            return value; // Return the string directly
          } 
          // Fallback to the data list if value is not valid
          return dataList[metaData.rowIndex]?.current_job_title?.name || '';
        },
      },
    },
    {
      name: 'exp_years',
      label: 'Years of Experience',
      options: {
        display: columnVisibility.exp_years,
        filter: true,
        setCellProps: () => ({ 'data-label': 'Years of Experience' }),
      },
    },
    {
      name: 'highest_qualification',
      label: 'Highest Qualification',
      options: {
        display: columnVisibility.highest_qualification,
        filter: true,
        setCellProps: () => ({ 'data-label': 'Highest Qualification' }),
      },
    },
    {
      name: 'curriculum_board',
      label: 'Curriculum Board',
      options: {
        display: columnVisibility.curriculum_board,
        filter: true,
        setCellProps: () => ({ 'data-label': 'Curriculum Board' }),
        customBodyRender: (value, metaData) => {
          let name = value || '';
          if(name === "") {
            name = dataList[metaData.rowIndex].curriculum_board || "";
          }
          return name;
        }
      },
    },
    {
      name: 'fun_area',
      label: 'Functional area',
      options: {
        display: columnVisibility.fun_area,
        filter: true,
        setCellProps: () => ({ 'data-label': 'Functional area' }),
      },
    },
    {
      name: 'professional_degree',
      label: 'Professional Degree',
      options: {
        display: columnVisibility.professional_degree,
        filter: true,
        setCellProps: () => ({ 'data-label': 'Professional Degree' }),
        customBodyRender: (value, metaData) => {

          if (typeof value === 'object' && value !== null){
            return value.name || '';
          }
          return value || dataList[metaData.rowIndex]?.professional_degree__name || '';
        }
      },
    },
    {
      name: 'professional_start_date',
      label: 'Professional Start_date',
      options: {
        display: columnVisibility.professional_start_date,
        filter: true,
        setCellProps: () => ({ 'data-label': 'Professional Start_date' }),
      },
    },
    {
      name: 'professional_end_date',
      label: 'Professional End_date',
      options: {
        display: columnVisibility.professional_end_date,
        filter: true,
        setCellProps: () => ({ 'data-label': 'Professional End_date' }),
      },
    },
    {
      name: 'notice_period',
      label: 'Notice Period',
      options: {
        display: columnVisibility.notice_period,
        filter: true,
        setCellProps: () => ({ 'data-label': 'Notice Period' }),
        customBodyRender: (value, metaData) => {
          let name = value || '';
          if(name === "") {
            name = dataList[metaData.rowIndex].notice_period || "";
          }
          return name;
        }
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        filter: true,
        display: columnVisibility.state,
        setCellProps: () => ({ 'data-label': 'State' }),
        customBodyRender: (value, metaData) => {
          let name = value?.name || '';
          if(name === "") {
            name = dataList[metaData.rowIndex].state__name || "";
          }
          return name;
        }
      },
    },
    {
      name: 'city',
      label: 'City',
      options: {
        filter: true,
        display: columnVisibility.city,
        setCellProps: () => ({ 'data-label': 'City' }),
        customBodyRender: (value, metaData) => {
          let name = value?.name || '';
          if(name === "") {
            name = dataList[metaData.rowIndex].city__name || "";
          }
          return name;
        }
      },
    },
    {
      name: 'subjects',
      label: 'Subjects',
      options: {
        filter: true,
        display: columnVisibility.subjects,
        setCellProps: () => ({ 'data-label': 'Subjects' }),
        customBodyRender: (value) => {
          if (Array.isArray(value)) {
            return value.map(subject => subject.name).join(', ');
          }
          return '';
        },
      },
    },
    {
      name: 'action',
      label: 'Action',
      options: {
        display: columnVisibility.action,
        filter: false,
        sort: false,
        setCellProps: () => ({ 'data-label': 'Action' }),
        customBodyRenderLite: (dataIndex) => (
          <>
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleeClosee}
              sx={{ height: '10vh' }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                position: 'absolute',
              }}
            >
              <Alert onClose={handleeClosee} severity="success" sx={{ width: '100%' }}>
                Candidate Deleted!
              </Alert>
            </Snackbar>
            <div>
              <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClosee}
                aria-labelledby="responsive-dialog-title"
                sx={{ '& .MuiDialog-paper': { height: '200px' } }}
              >
                <DialogTitle variant="h4">{'Delete'}</DialogTitle>
                <DialogContent>
                  <DialogContentText>Are you sure you wan&#39;t to delete this candidate?</DialogContentText>
                  {/* <Button autoFocus onClick={handleClosee}>
                    Disagree
                  </Button>
                  <Button
                    onClick={() => onDeleteHandler(deleteJobId)}
                    loading={dataIndex === currentIndex ? useDeleteCandidateMutation.isLoading : false}
                    autoFocus
                  >
                    Agree
                  </Button> */}
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleClosee}>
                    Disagree
                  </Button>
                  <Button
                    onClick={() => onDeleteHandler(deleteJobId)}
                    loading={dataIndex === currentIndex ? useDeleteCandidateMutation.isLoading : false}
                    autoFocus
                  >
                    Agree
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <Button
              style={{ minWidth: 0 }}
              component={RouterLink}
              to={`/dashboard/candidates/edit-candidate/${dataList[dataIndex]?.id}`}
            // variant="contained"
            // onClick={() => onEditModalHandler(dataIndex)}
            >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="ep:edit" width={24} height={24} sx={{ color: 'blue' }} />
              </ListItemIcon>
            </Button>
            <Button
              style={{ minWidth: 0, margin: '0px 5px' }}
              // variant="contained"
              color="error"
              onClick={() => handleClickOpen(dataList[dataIndex]?.id)}
              loading={dataIndex === currentIndex ? useDeleteCandidateMutation.isLoading : false}
            >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="eva:trash-2-outline" width={24} height={24} sx={{ color: 'red' }} />
              </ListItemIcon>
            </Button>
          </>
        ),
      },
    },
  ];

  const labelStatus = (
    <Label variant="ghost" color={'success'}>
      {sentenceCase('active')}
    </Label>
  );
  const editAndDeleteButton = (
    <>
      <Button component={RouterLink} to="/dashboard/candidates/edit-candidate">
        <ListItemIcon style={{ justifyContent: 'center' }}>
          <Iconify icon="eva:edit-fill" width={24} height={24} />
        </ListItemIcon>
      </Button>
      <Button>
        <ListItemIcon style={{ justifyContent: 'center' }}>
          <Iconify icon="eva:trash-2-outline" width={24} height={24} />
        </ListItemIcon>
      </Button>
    </>
  );

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    download: true,
    print: true,
    tableBodyHeight: '400px',
    tableBodyMaxHeight: '600px',
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 30],
    // customToolbarSelect: () => {},
    viewColumns: true,
    onColumnViewChange: (changedColumn, action) => {
      setColumnVisibility((prevVisibility) => ({
        ...prevVisibility,
        [changedColumn]: action === 'add',
      }));
    },
  };  

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  }

  useEffect(() => {
    if (candidateData) {
      setDataList(candidateData);
    }
  }, [candidateData]);

  useEffect(() => {
    const element = document.getElementById("changer");
    const parentElement = element?.parentElement;
    parentElement.style.paddingTop = "90px"
  },[])

  return (
    <Page id="changer" title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0}>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div className="backbutton tt-back" style={{ display: 'flex' }}>
              <ArrowBackIosIcon
                onClick={navigateCancel}
                sx={{
                  cursor: 'pointer',
                }}
              />
            </div>
            {/* <h1 style={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}>Candidates</h1> */}
            <Typography 
              variant="h3" 
              color="textPrimary" 
              sx={{ 
                width: '300px',
                textDecorationLine: 'underline',
                backgroundColor: 'transparent !important'
              }}
            >
              Candidates
            </Typography>
          </Container>
          <Button
            style={{ width: '13%' }}
            variant="contained"
            component={RouterLink}
            to="/dashboard/candidates/newcreate"
            startIcon={
              <Iconify
                icon="eva:plus-fill"
                sx={{
                  height: '35px',
                }}
              />
            }
          >
            New candidate
          </Button>
        </Stack>
        <Button onClick={toggleSidebar} startIcon={<Iconify icon={sidebarOpen ? 'eva:arrow-ios-back-fill' : 'eva:arrow-ios-forward-fill'} />}>
          {sidebarOpen ? 'Hide Filters' : 'Show Filters'}
        </Button>
        <Grid container spacing={2} style={{ padding: 0 }}>
        {sidebarOpen && (
          <Grid item xs={12} md={3}>
            <Card sx={{ padding: 'inherit', height: '100%' }}>
            {/* {advanceFilterForm && ( */}
              <Stack direction="column" spacing={2} sx={{maxHeight:'600px', overflow:'auto',  marginRight: '16px' }}>
                <QueryBuilderAntD>
                  <QueryBuilder
                    fields={fields}
                    query={query}
                    controlElements={{
                      addGroupAction
                    }}
                    onQueryChange={handleQueryChange}
                    autoSelectField={false}
                    autoSelectOperator={false}
                    resetOnFieldChange={false} />
                </QueryBuilderAntD>
                <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    sx={{ width: '200px'}}
                    onClick={applyFilter}
                    startIcon={<Iconify icon="bi:filter-square-fill" />}
                  >
                    Apply Filter
                  </Button>
                  <Button
                    variant="contained"
                    onClick = {handleClearFilter}
                    sx={{ width: '200px'}}
                    startIcon={<Iconify icon="mdi:filter-remove-outline" />}
                  >
                    Clear Filter
                  </Button>
                </Stack>
              </Stack>
            </Card>
            {/* )}  */}
            {/* <Card>
              <MainModuleFilter applyAdvanceFilter={showAdvanceFilterForm} simpleFilterForm={simpleFilterForm} />
            </Card> */}
          </Grid>
        )}
          <Grid item xs={12} md={sidebarOpen ? 9 : 12}>
            <Card>
              <TableContainer>
                <MUIDataTable
                  title={'Candidate List'}
                  data={dataList}
                  columns={columns}
                  options={{
                    ...options,
                    responsive: 'standard', // Ensure responsive design
                    scrollY: '400px', // Set a fixed height for the table if needed
                    scrollX: true, // Allow horizontal scrolling if necessary
                  }}
                />
              </TableContainer>
              <Typography variant="body2" align="center" sx={{ padding: '16px' }}>
                Total Candidates: {dataList?.length || 0}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <CandidatesModel open={modelOpen} handleClose={handleClose} candidateId={candidateId} />
    </Page>
  );
};

export default Candidates;
